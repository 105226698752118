#sidebar {
	width: 250px;
	padding: $small-padding;
	padding-top: 0;
	margin-top: -10px;
	float: right;
	text-align: left;
	font-size: 12px;
	line-height: 16px;

	p {
		font-size: 12px;
		line-height: 16px;
	}

	.inline.error, .inline.notice {
		margin-left: 0;
		margin-right: 0;
		padding: 5px;
	}

	textarea, input[type="text"] {
		border-color: $medium-blue;
	}

	.clean tr td {
		border-color: $medium-blue;
		padding: 10px 5px;
	}

	.content {
		padding: 0 10px 10px 10px;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			margin: 0;
			padding: 5px;
			line-height: 16px;
			border-bottom: 1px solid #f5f9ff;

			&:last-child {
				border-bottom: none;
			}

			&.plain {
				padding: 0;
				line-height: auto;
				border-bottom: 0;
			}

			&.block {
				padding: 5px;

				&:hover, &:active {
					background: #f5f9ff;
				}

				a {
					padding: 5px;

					&.inline {
						display: inline-block
					}

					&.secondary {
						color: #aabdcd;
					}

					&:hover, &:active {
						background: #f5f9ff;
					}
				}
			}
		}
	}

	#buttons, .buttons {
		text-align: center;
		margin-bottom: 10px;
		margin-top: 10px;

		a {
			border-width: 2px;
			width: 220px;
			font-weight: normal;
			font-size: 14px;
			text-align: left;
			margin-top: 2px;
			margin-bottom: 2px;
		}

		.edit {
			border-color: darkgoldenrod;
			background: darkgoldenrod;

			&:hover, &:active {
				background: goldenrod;
				border-color: goldenrod;
			}
		}
	}

	.item {
		margin-top: 10px;
		margin-bottom: 20px;

		h3 {
			font-size: 14px;
			font-weight: normal;
			background: #f5f9ff;
			padding: 8px;
			/*border-radius: 20px;
			padding-left: 20px;*/
			font-weight: 600;
			margin-left: -18px;
			border-bottom-right-radius: 20px;
			border-top-right-radius: 20px;
			padding-left: 28px;

			.view-all {
				background: #d4ebff;
				padding: 3px 7px;
				border-radius: 5px;
				margin-top: -3px;
				font-weight: normal;
				font-size: 12px;
				margin-right: 5px;
				float: right;
			}
		}

		ul, ol {
			margin: 5px 0;
		}
	}

	.message {
		padding-left: 44px;
		margin-top: 10px;
		padding: $small-padding;
		margin-bottom: 10px;
	}

	.warning, div.error {
		padding-left: 44px;
		margin-top: 10px;
		padding: $small-padding;
		padding-left: 44px;
		margin-bottom: 10px;
		background: #ffffd0;
		border: 1px solid #f4f400;
		color: #8a8a02;
	}

	div.error {
		background: #ffd5d5;
		padding-left: 36px;
		border: 2px solid #f69f9f;
		border-radius: 5px;
		color: #dd2323;
		text-shadow: none;
	}

	.notice {
		background: #d0ffd1;
		padding-left: 36px;
		border: 2px solid #99d089;
		border-radius: 5px;
		color: #0d4d05;
		text-shadow: none;
	}
}

.with-modal {
	&.small {
		width: 400px;
	}
	&.medium {
		width: 450px;
	}
	&.large {
		width: 500px;
	}
	&.extra-large {
		width: 600px;
	}

	&.delete {
		.header {
			background: #ffd5d5;
			border-color: #f69f9f;
		}
	}

	.header, .text-header {
		background: #f5f9ff; /* Old browsers */
		/* IE9 SVG, needs conditional override of 'filter' to 'none' */
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YyZjJmMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlZGVkZWQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: linear-gradient(to bottom, #e0f1ff 0%,$medium-blue 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0f1ff', endColorstr='$medium-blue',GradientType=0 );
		border-bottom: 1px solid #aedaff;
		margin: -10px;
		padding: $small-padding;
		min-height: 20px;
		margin-bottom: 10px;

		h2 {
			font-size: 18px;
			font-weight: 500;
			letter-spacing: -1px;
		}

		h3 {
			font-size: 16px;
			font-weight: normal;
			letter-spacing: -1px;
			margin-bottom: 0;
			margin-top: 0;
			padding-bottom: 0;
		}
	}

	.text-header {
		strong {
			font-size: 16px;
		}
	}

	.info {
		background: #fffedb;
		border-bottom: 1px solid #fffdad;
	}

	.links {
		margin: -10px;
		margin-top: 10px;
		background: #efefef;
		padding: $small-padding;
		text-align: center;

		a {
			display: inline-block;
			padding: 8px;
			margin: 0;
			font-size: 16px;
			border-radius: 5px;
			text-align: center;
			width: auto;
			min-width: 100px;
		}
	}
}

#sidebar {
	dl {
		margin: 0;
	}

	dt {
		float: left;
		clear: left;
		width: 80px;
		text-align: right;
		font-weight: 600;
	}

	dt:after {
		content: ":";
	}

	dd {
		margin: 0 0 0 90px;
		padding: 0 0 0.5em 0;
	}

	dl.multi-line {
		dd {
			display: block;
			padding-left: 20px;
			margin: auto;
			margin-bottom: 10px;
			font-weight: 600;
			/*border-bottom: 1px solid $medium-blue;*/

			&:last-child {
				border-bottom: 0;
			}
		}

		dt {
			float: none;
			text-align: left;
			width: auto;
			font-weight: normal;
			margin-bottom: 5px;

			&:after {
				content: none;
			}
		}
	}
}

.info-panel {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;

	&.right {
		border-radius: 0;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	.description {
		color: #444;
		margin: 0;
		padding: 0;
		font-size: 14px;
	}

	.task-list {
		margin: 0;
		width: 100%;
	}

	.sub-filter {
		width: 210px;
	}

	h3 {
		font-weight: 500;
		font-size: 14px;
		text-align: left;

		&.block {
			color: #444;
			font-size: 15px;

			i {
				color: $blue-etc;
				font-size: 1.4em;
			}
		}

		i {
			color: $green;
		}

		a {
			color: $blue-etc;
			text-decoration: underline;
		}

		.external-link {
			text-align: center;
			display: inline-block;
			float: right;
			padding: 1px 6px;
			margin-top: -1px;
			border-radius: 5px;
			font-size: 12px;

			&:hover, &:active {
				background: #ddd;
			}

			i {
				margin: 0;
				color: #aaa;
			}
		}
	}

	h4 {
		margin-bottom: 10px;
		color: $blue-etc;
		font-weight: 400;
		font-size: 15px;
		margin: 0;

		.fa-caret-left {
			margin-right: 20px;
			cursor: pointer;
		}

		.fa-caret-right {
			margin-left: 20px;
			cursor: pointer;
		}

		&.block {
			text-align: left;
			font-weight: 500;
			color: $blue-etc;
			background: #efefef;;
			padding: 5px 10px;
			margin: 10px -10px 10px -10px;
			border-radius: 0;
		}
	}

	h5 {
		color: $green;
		font-size: 13px;
		/*border-bottom: 1px solid #f2f2f2;*/
		padding: 5px 0;
		margin: 0;
		font-weight: 600;

		&.block {
			text-align: left;
			font-weight: 500;
			color: $blue-etc;
			background: #efefef;;
			padding: 5px 10px;
			margin: 10px -10px 10px -10px;
		}

		.icon {
			text-align: center;
			width: 30px;
			color: #eaeaea;
			display: inline-block;
		}
	}

	&.list, &.filter {
		h3, h4, h5 {
			color: $green;

			&.block {
				color: $blue-etc;
			}
		}
	}

	.profile-photo {
		float: none;
		margin: 0;
		border: 0;
	}

	hr {
		margin-left: -10px;
		margin-right: -10px;
	}
}

.left-side-panel.empty, .left-sidebar {
	background: none;
	border: 0;
	font-size: 13px;
	height: 100%;
	width: 260px;
	border-radius: 0;
	margin-left: -20px;
	margin-top: -20px;
	float: left;
	margin-right: -20px;
	padding: 20px;
	margin-left: -300px;
	/*borderLeft: 3px solid #ddf3d7;
	padding-left: 17px;*/

	&.narrow {
		width: 200px;
	}
}

#main.with-narrow-sidebar {
	padding-left: 240px;
}

.left-side-panel.paddingless {
	padding: 0;
	width: 280px;
}

.left-side-panel.white {
	background: none;
}

.list-with-panel, .record-with-panel {
	margin-left: 272px;

	&.bordered {
		border: 1px solid #efefef;
		padding: 10px;
		border-radius: 6px;
	}

	&.marginless {
		padding-top: 20px;
		margin-left: 280px;
	}
}

.info-panel {
	dl.labels {
		dt {
			width: 40%;
			font-size: 13px;
			color: #999;
		}

		dd {
			font-weight: 500;
		}
	}
}

#top-bar {
	background: #598a4c;
	background: -webkit-linear-gradient(to bottom,  #6ba35b 0%, #67a157 100%);
	background: #2b466e;
	height: 56px;

	#logo {
		line-height: 50px;
		margin-left: 10%;
		font-size: 24px;
	}

	ul {
		float: right;
		margin-right: 10%;
	}

	ul li a {
		&:hover, &:active, &.active {
			background: #192a4d;

			color: white;
			i {
				color: white;
			}
		}

		&:hover, &:active {
			background: #192a4d;

			color: white;
			i {
				color: aliceblue;
			}
		}
	}
}


#bar {
	position: relative;
	text-align: left;
	padding: 14px 3%;

	ul.section-links {
		margin: 0;
		padding: 0;

		li {
			list-style-type: none;
			margin: 0;
			padding: 0;
			display: inline-block;

			a {
				margin: 0;
				padding: 6px 12px 8px 12px;
				border-radius: 6px;
				line-height: auto;
				color: #ddd;
				font-size: 1.2em;

				&:hover, &:active {
					color: #ccc;
				}

				&.active {
					color: #76b262;
				}

				span {
					font-size: 14px;
					margin-left: 8px;
					font-weight: 500;
				}
			}
		}
	}
}

#bar-wrapper {
	background: #598a4c;
	border-bottom: 1px solid #4c753f;
	background: -webkit-linear-gradient(to bottom,  #6ba35b 0%, #518d40 100%);
	background: -webkit-linear-gradient(to bottom,  #6ba35b 0%, #67a157 100%);
	padding: 1px;
	position: relative;

	#bar {
		ul.section-links li a {
			color: #9aa9c6;
			border: none;

			&.active {
				/*background: #4c753f;
				background: #6ba35b;*/
				background: #17284a;
				color: white;
			}

			&:hover, &:active {
				color: white;
			}
		}
	}

	.logo {
		text-align: center;
		color: white;
		font-size: 18px;

		strong {
			font-size: 22px;
			font-weight: 600;
		}

		div {
			color: #c5e3bb;
			font-weight: 500;
			margin-top: 5px;
		}
	}
}

.side-menu, .top-menu {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;

	ul {
		display: table;
		table-layout: fixed;
		list-style-type: none;
		margin: 0;
		width: 100%;
		padding: 0;

		li {
			box-sizing: border-box;
			background: aliceblue;
			padding: 20px 10px;
			display: table-cell;
			font-weight: 500;
			font-size: 16px;
			color: #b8cdee;
			border-bottom: 1px solid #b8cdee;
			border-right: 1px solid #b8cdee;
			cursor: pointer;

			&:first-of-type {
				border-top-left-radius: 6px;
			}

			&:last-of-type {
				border-right: 0;
				border-top-right-radius: 6px;
			}

			i {
				width: 30px;
				text-align: center;
				color: #ccc;
				color: #b8cdee;
				margin-right: 10px;
			}

			&:hover, &:active {
				color: $blue-etc;
				background: #e8f2fa;

				i {
					color: $blue-etc;
				}
			}

			&.active {
				color: $blue-etc;
				border-bottom-color: white;
				background: white;
				color: $blue-etc;

				i {
					color: $blue-etc;
					/*color: #f5f9ff;*/
				}
			}
		}
	}
}

.with-left-menu {
	display: table;
	width: calc(100% + 40px);
	margin: -20px;
	border-radius: 6px;
}

.dashboard-icon {
	display: none;
	font-size: 50px;
	color: #ccc;
	text-align: center;
	margin: -20px auto 20px auto;
}

.panel-filter-links {
	font-weight: 500;
	font-size: 13px;
	text-align: center;
	width: 50px;

	&.top {
		position: relative;
		background: #192b4d;
		z-index: 10;
	}

	&.bottom {
		position: absolute;
		bottom: 0;
	}

	.sub {
		display: none;
	}

	i {
		color: #99b1d3;
		font-size: 2.3em;
		position: relative;
		text-align: center;
	}

	i.options {
		/*background: #99b1d3;*/
		width: auto;
		padding: 4px 5px 4px 6px;
		float: right;
		border-radius: 50px;
		color: #99b1d3;
		margin-right: 0;
		font-size: 1em;
	}

	.top {
		background: #192c4d;
	}

	.bottom {
		align-self: baseline;
		width: auto;
	}

	a {
		color: #99b1d3;
		border-radius: 6px;
		padding: 10px 8px;
		border: 1px solid transparent;
		cursor: pointer;
		margin: 8px -5px;
		display: block;
		border: 1px solid transparent;

		.title {
			display: none;
		}

		&:hover, &:active {
			.title, i {
				color: white;
			}
		}

		&.active {
			border-radius: 0;
			background: #f2f5f9;
			background: #2b466e;
			color: #333;
			border-radius: 6px;

			.number {
				background: #eaeaea;
				color: #666;
				display: none;
			}

			&.overview {
				.title, i {
					color: #6600ff;
				}
			}

			&.overdue {
				.title, i {
					color: firebrick;
				}
			}

			&.latest {
				.title, i {
					color: forestgreen;
				}
			}

			&.tagged {
				.title, i {
					color: $blue-etc;
				}
			}

			&.with-star {
				.title, .amount {
					color: goldenrod;
				}

				i {
					color: #fffcd7;
					-webkit-text-stroke: 1px #e0cd22;
				}
			}
		}

		.number {
			text-align: right;
			width: auto;
			color: #99b1d3;
			background: none;
			padding: 4px 8px;
			border-radius: 20px;
			margin-right: 0;
			margin-top: 0;
			font-size: 12px;
		}
	}

	.sub {
		padding-left: 10px;
		/*border-left: 2px solid #466397;*/
		/*background: #344c77;*/

		a {
			font-size: 12px;
			border: 0;
			color: white;
			border-radius: 0;
			font-weight: 500;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;

			&:hover, &:active {
				background: #ebf3ff;
			}

			&.active {
				background: #466397;

				.title {
					color: white;
				}
			}
		}
	}
}

#page {
	padding: 0;
	margin: 0;
	background: white;
	width: 100%;
	height: 100%;
	position: absolute;
}

#sidebar-footer {
	position: absolute;
	bottom: 0;
	width: 270px;
	background: white;
	padding: 10px;
	display: none;
}

#main-sidebar {
	flex-shrink: 0;
	width: 220px;
	box-sizing: border-box;
	background: #476398;
	background: #2b466e;
	height: 100%;
	padding: 10px;
	padding-right: 0;
	margin-right: -10px;
	overflow: hidden;

	.content {
		overflow-x: hidden;
		overflow-y: auto;
	}

	h2 {
		background: #0e1c35;
		background: #192c4d;
		margin: -10px;
		padding: 8px 20px;
		margin-bottom: 5px;
		font-weight: 600;
		font-size: 15px;
		color: #e0ecff;
		text-align: center;

		i {
			color: #e0ecff;
			font-size: 4em;
			color: #425e87;
			font-weight: 200;
			margin-bottom: 5px;
			margin-right: 0;
			display: block;
		}
	}

	hr {
		color: #476396;
		height: 1px;
		border: 0;
		background: #476396;
		margin: 10px 0%;
	}

	.center-console {
		li {
			line-height: auto;
			width: 62px;

			a {
				font-size: 13px;
				padding: 3px 8px;
				text-align: center;

				i {
					text-align: center;
					font-size: 1.5em;
					margin-bottom: 10px;
					margin: 10px auto;
					display: block;
				}
			}
		}
	}

	.module-search {
		background: #192c4d;
		margin: -10px;
		padding: 10px;
		margin-bottom: 10px;
		display: block;
		display: none;

		input {
			padding: 3px 8px;
			border-radius: 40px;
			font-size: 14px;
			border: 2px solid #2c476e;
			font-size: 13px;
			color: #192c4d;
			box-sizing: border-box;
			width: 100%;
			background: #476396;
			color: white;
			padding: 6px 10px;
			font-weight: 500;
			background: #99b0d2;
			color: #192c4d;
		}
	}

	/*.sub-panel {
		background: #284063;
		margin: 0 -5px;
		border-radius: 4px;
		padding: 5px;
	}*/

	h3.section-title {
		color: #e0ecff;
		font-size: 14px;
		/*font-weight: 400;
		text-transform: uppercase;*/
		font-weight: 700;
		margin: 0 0 5px 5px;
		line-height: 26px;

		i {
			float: right;
			font-size: 16px;
			line-height: 26px;
			color: #abc8ef;
			margin-right: 0px;
			cursor: pointer;
		}

		i.fa-minus-circle {
			display: none;
		}

		&:hover, &:active {
			i.fa-minus-circle {
				display: inline-block;
			}
		}
	}

	.sub, .main-links {
		a {
			font-size: 12px;
			border: 0;
			color: white;
			border-radius: 0;
			font-weight: 500;
			display: block;
			font-size: 13px;
			font-weight: 500;
			padding: 6px 10px;
			margin: 3px 0;
			color: #e0ecff;
			/*border: 1px solid transparent;*/
			border-right: 0;
			border-radius: 5px;

			i {
				margin-right: 10px;
				font-size: 1.3em;
				width: 20px;
				text-align: center;
			}

			.record-name {
				overflow: hidden;
				display: block;
				text-overflow: ellipsis;
			}

			&:hover, &:active {
				background: #ebf3ff;
			}

			&.active {
				background: #466397;

				.title {
					color: white;
				}
			}
		}

		&.sub {
			width: calc(100% - 10px);
		}
	}

	.main-links {
		margin-bottom: 10px;

		a {
			font-size: 14px;
			margin-right: -15px;

			input[type="text"] {
				border-radius: 50px;
				padding: 4px 12px;
				width: 145px;
				font-size: 14px;
				margin-left: -5px;
				font-weight: 500;
				border: 1px solid #efefef;
				border: 1px solid #476396;
				color: #2c476e;
				background: #99b0d2;
			}

			&.active {
				background: black;
				/*border-top-right-radius: 0;
				border-bottom-right-radius: 0;*/
				background: linear-gradient(to right, #fff, #f2f5f9);

				i {
					color: #99b0d2;
				}

				.title {
					color: #2c476e;
					font-weight: 600;
				}
			}
		}
	}
}

#menu {
	height: 100%;
	background: #192b4d;
	padding: 10px;
	flex-shrink: 0;
	color: #99b1d3;
	box-sizing: border-box;
	width: 70px;
	flex-wrap: wrap;

	.alert-number, .warning-number {
		font-family: 'Whitney SSm A', 'Whitney SSm B', 'helvetica neue', 'helvetica', 'sans-serif';
		background: firebrick;
		color: white;
		position: absolute;
		min-width: 20px;
		box-sizing: border-box;
		line-height: 20px;
		letter-spacing: -0.5px;
		border-radius: 14px;
		font-weight: 700;
		font-size: 11px;
		top: -5px;
		right: -10px;
		padding: 0 3px;
	}

	.warning-number {
		background: white;
		color: #99b1d3;
	}

	.identifier {
		font-weight: 500;
		margin-bottom: 0;
		color: #99b1d3;
		margin: 0 auto;
		text-align: center;
		background: #0e1d36;
		margin: -10px -10px 10px -10px;
		padding: 10px;
		height: 60px;
		padding-bottom: 0;

		.call-sign {
			/*background: #192a4d;
			margin: -20px -20px 10px -20px;
			padding: 10px 20px;*/
		}

		.current-user-avatar img {
			border-radius: 50px;
			margin-bottom: 10px;
			width: 50px;
			height: 50px;
		}

		.initials {
			border-radius: 50px;
			background: #192a4d;
			border: 1px solid #435f8a;
			font-weight: 600;
			color: white;
			display: inline-block;
			font-size: 22px;
			margin-bottom: 10px;
			width: 50px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			box-sizing: border-box;
			text-transform: uppercase;
		}
	}

	.button-icons {
		display: none;
		text-align: center;
		float: none;
		padding: 2px 0;
		background: #192a4d;
		margin: 10px 0 0 0;
		border: none;
		border-radius: 30px;

		a {
			&:hover, &:active, &.active {
				i {
					color: #466397;
				}
			}

			i {
				color: #2b466e;
			}
		}

		.fa-sign-out {
			padding-left: 2px;
		}
	}

	#search-box, #top-box {
		margin: -20px -20px 20px -20px;
		padding: 10px;
		background: #192a4d;
		text-align: center;
/*
		input[type="text"] {
			padding: 6px 8px;
			border: 1px solid #2b466e;
			background: #2b466e;
			font-size: 14px;
			color: white;
			width: auto;
			padding-left: 28px;

			&::-webkit-input-placeholder {
				color: #99b1d3;
			}
		}
*/

		input[type="text"] {
			width: auto;
		}
	}

	a {
		color: #99b1d3;
	}
}


#skeleton {
	overflow: auto;
	position: fixed;
	width: 100%;
	display: flex;
	height: 100%;
	align-items: center;
	background: linear-gradient(to bottom right, #ebf3ff, white);
	/*width: 97%;
	margin: 1.5%;
	height: 97%;*/

	.main-content-panel {
		overflow: auto;
		padding: 10px;
		box-sizing: border-box;
		max-width: 100%;
		flex: 1;
		justify-content: center;
		display: flex;
		height: 100%;

		> div:not(.main):not(#main) {
			width: 100%;
		}
	}

}

.with-modal .main.mini {
	margin-left: calc(50% - (450px / 2));
}

#main, .main {
	box-sizing: border-box;
	will-change: transform;
	border: 1px solid #d0d5db;
	border-radius: 6px;
	box-shadow: 0 0 10px #d0d5db;
	text-align: left;
	background: white;
	width: 100%;
	align-self: flex-start;

	&.enveloping {
		background: #fafafa;

		#content, .main-content {
			margin: 0 10px 10px 10px;
			border-radius: 6px;
			background: white;
			z-index: 10000;
			width: calc(100% - 20px);
			border: 1px solid #efefef;
		}
	}

	&.headingless {
		.main-content, #content, .content {
			border-radius: 4px;
		}
	}

	&.top-overflow {
		margin-top: 50px;

		.main-content, #content, .content {
			overflow: visible;
		}
	}

	&.small, &.medium {
		align-self: flex-start;
	}

	&.mini {
		width: 450px;
		margin-left: auto;
		margin-right: auto;
		align-self: center;
	}

	&.small {
		width: 600px;
		margin: 50px auto 0 auto;
	}

	&.medium {
		margin-left: auto;
		margin-right: auto;
		margin-top: 40px;
		width: 750px;
	}

/*	&.small, &.medium {
		.main-content {
			margin: -10px;
		}
	}
*/
}

#bar {
	padding: 15px;
}

#bar-wrapper {
	background: linear-gradient(to bottom,  #2b466e 0%, #415178 100%);
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	box-shadow: 5px 3px 10px #17284a;
	padding: 0;
	border: 1px solid #17284a;
	display: none;

	a {
		color: #99b1d3;
	}

	.call-sign {
		position: absolute;
		background: #17284a;
		top: 0;
		left: 0;
		width: 20%;

		.initials {
			background: #2b466e;
			border-color: #17284a;
			box-shadow: 0 0 4px #2b466e;
		}
	}
}

#skeleton #menu #top-box {
	padding: 5px;
	font-size: 1em;
	color: #3e4d7e;
}

.cell.right-sidebar {
	background: #fafafa;
	border-left: 2px solid #efefef;
	padding: 20px;

	div.work-list .row .cell {
		font-size: 13px;
		font-weight: 400;
	}

	h3.block {
		background: #f2f2f2;
		padding: 7px 10px;
		margin: 0 -15px 5px -15px;
		font-size: 15px;
		font-weight: 600;

		i {
			font-size: 1.4em;
			color: $blue-etc;
		}
	}

	&.blank {
		border-left: 0;
		background: transparent;
	}

	.section {
		margin: 15px -5px;
	}

	&.unpadded {
		padding: 0;
	}

	.heading {
		font-size: 15px;
		color: #888;
		font-weight: 500;
		margin-bottom: 10px;
		background: #efefef;
		padding: 8px;
		padding-left: 20px;
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
		margin-left: -20px;
	}
}

.floating-header {
	box-sizing: border-box;
	position: relative;
	margin: 10px;
	margin-left: calc(10% + 10px);
	width: calc(90% - 20px);
}

.inline-header {
	position: relative;
	border-bottom: 2px dotted #efefef;
}

.info-panel {
	h1 {
		line-height: 26px;
		font-weight: 500;
		font-size: 20px;
		margin: 10px 0;
	}

	.button-icons {
		float: none;
		text-align: center;
	}

	.section-links {
		list-style-type: none;
		margin: 0px -12px 20px -10px;
		padding: 0;
		border-top: 1px solid #f2f2f2;

		li a {
			display: block;
			font-size: 15px;
			font-weight: 500;
			padding: 10px 15px;
			color: #aaa;
			border-bottom: 1px solid #f2f2f2;

			i {
				display: inline-block;
				width: 30px;
				text-align: center;
				color: #ccc;
				margin-right: 10px;
				font-size: 1.3em;
			}

			&:hover, &:active {
				background: #f6f6f6;
			}

			.count {
				background: #ddd;
				background: none;
				color: #ccc;
				padding: 2px 10px;
				font-size: 13px;
			}

			&.active {
				background: white;
				background: linear-gradient(to left, #fff, #fafafa);
				color: $blue-etc;
				font-weight: 600;

				&:after {
					font-family: 'Font Awesome 5 Pro';
					content: "\f054";
					color: #ddd;
					margin-left: 10px;
				}

				i {
					color: $blue-etc;
				}
			}
		}
	}
}

.top-links {
	list-style-type: none;
	margin: 10px 10px 20px 10px;
	padding: 0;
	text-align: center;

	li {
		display: inline-block;
		margin: 0 5px;

		a {
			background: #fafafa;
			border-radius: 6px;
			padding: 6px 12px;
			color: #aaa;
			font-size: 14px;
			font-weight: 500;

			&:hover, &:active {
				background: #f6f6f6;
			}

			i {
				margin-right: 10px;
				color: #ccc;
				font-size: 1.3em;
			}


			&.active {
				background: aliceblue;
				/*background: white;
				/*background: linear-gradient(to bottom, rgba(232, 244, 255, 0.43), white);
				border-color: #f2f2f2;
				border-bottom: 1px solid white;*/
				color: $blue-etc;

				i {
					color: $blue-etc;
					color: #cee2ff;
				}
			}

			.count {
				display: none;
			}
		}
	}
}

#main-sidebar {
	background: none;

	.main-links {

	}

	.main-links a {
		color: #86a8d6;
		border: 1px solid transparent;
		border-right: 0;

		&.active {
			background: white;
			border: 1px solid #e0ecff;
			border-right: 0;
			box-shadow: 1px 1px 5px #e0ecff;
		}
	}

	.main-action-button {
		color: white;
		padding: 6px 10px;
		border-radius: 20px;
		font-weight: 600;
		background: #8cbf7e;
		width: 160px;
		box-sizing: border-box;
		margin-left: calc(50% - 80px);
		display: block;
		text-align: center;
		font-size: 14px;
		margin-bottom: 10px;

		&:hover, &:active, &.active {
			background: #7eb170;
		}
	}

	h3.section-title {
		width: calc(100% - 10px);
		color: #85a8d6;
	}

	.sub a {
		color: #999;

		&.active {
			background: #86a8d6;
			font-weight: 600;

			i {
				margin-right: 10px;
				color: white;
			}
		}
	}

	h2 {
		background: none;
		color: #42618a;
		padding-top: 30px;

		i {
			color: $blue-etc;
		}
	}
}

.structure { // .contact,
	display: table;
	width: calc(100% + 40px);
	margin: -20px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;

	#header {
		margin: -10px -10px 10px -10px;
	}

	&.left-panel {
		.info-panel {
			border-right: 1px solid #f2f2f2;
			border-left: 0;
			border-radius: 0;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}
	}

	.info-panel, .main-panel {
		display: table-cell;
		padding: 10px;
	}

	&.thread .info-panel {
		background: #f9f9f9;
	}

	.info-panel {
		vertical-align: top;
		width: 220px;
		border-left: 1px solid #e0ecff;
		background: #f9fcff;
		border-left: 1px solid #f2f2f2;
		background: #fafafa;

		.header-actions {
			display: block;
			text-align: center;
			margin-bottom: 10px;
		}

		&.right {
			border-left: 2px solid #efefef;
			border-right: 0;
			text-align: left;
		}

		.hero {
			color: $blue-etc;
			font-size: 5em;
		}

		h1 {
			font-size: 24px;
			line-height: 26px;
			font-weight: 500;
		}

		h2 {
			margin-top: 0;
		}

		.button-icons {
			margin-top: 0;
			float: none;
			display: inline-block;
		}
	}

	.main-panel {
		width: calc(100% - 200px);

		.new-header {
			margin: -10px -10px 10px -10px;
		}
	}
}