
/**
 * @providesModule DraftEditor
 * @permanent
 */

/**
 * We inherit the height of the container by default
 */
.DraftEditor-root,
.DraftEditor-editorContainer,
.public-DraftEditor-content {
	height: inherit;
	text-align: initial;
}

.public-DraftEditor-content {
	-webkit-user-modify: read-write-plaintext-only;
	user-modify: read-write-plaintext-only;
	height: 400px;
	background: white;
}

.DraftEditor-root {
	position: relative;
}

/**
 * Zero-opacity background used to allow focus in IE. Otherwise, clicks
 * fall through to the placeholder.
 */
.DraftEditor-editorContainer {
	background-color: rgba(255, 255, 255, 0);
	border-left: 0.1px solid transparent;
	position: relative;
	z-index: 1;
}

.public-DraftEditor-block {
	position: relative;
}

.DraftEditor-alignLeft .public-DraftStyleDefault-block {
	text-align: left;
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
	left: 0;
	text-align: left;
}

.DraftEditor-alignCenter .public-DraftStyleDefault-block {
	text-align: center;
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
	margin: 0 auto;
	text-align: center;
	width: 100%;
}

.DraftEditor-alignRight .public-DraftStyleDefault-block {
	text-align: right;
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
	right: 0;
	text-align: right;
}

.editor-controls {
	background: #f6f6f6;
	margin-bottom: 5px;
	border-radius: 10px;

	.group {
		display: inline-block;
		border-right: 1px solid #efefef;

		&:last-of-type {
			border-right: 0;
		}
	}

	.attach {
		float: right;
		margin-right: 10px;
		color: #aaa;
		line-height: 14px;
		padding: 0 10px 0 3px;
		border-radius: 6px;
		cursor: pointer;
		margin-top: 5px;
		border: 1px solid transparent;

		i {
			color: #999;
			width: auto;
			margin: 0;
			margin-right: 5px;
			padding: 5px;
		}

		&:hover, &:active {
			background: white;
			color: $green;
			/*border-color: #efefef;*/

			i {
				color: $green;
			}
		}
	}

	i {
		cursor: pointer;
		font-size: 1em;
		padding: 6px 0;
		text-align: center;
		width: 30px;
		margin: 5px;
		border-radius: 6px;
		border: 1px solid transparent;

		&:hover, &:active {
			background: white;
		}

		&.active {
			background: white;
			color: $green;
		}
	}
}