@media only screen and (max-width: 480px) {

#skeleton {
	background: white;

	&.anonymous {
		background: linear-gradient(to bottom right, #2d4573, #0e1d36);

		#main, .main {
			&.mini, &.medium, &.small {
				box-shadow: 0 0 30px #0e1d36;
			}
		}
	}

	.main-content-panel {
		padding: 0;
		margin: 0;
		display: flex;
	}

	&:not(.anonymous) {
		.main-content-panel {
			padding-bottom: 52px;

			> div {
				background: white;
			}
		}
	}
}

#main, .main {
	width: 100%;
	margin: 0;

	&:not(.small):not(.mini) {
		box-shadow: 0px 0px 0;
		margin: 0;
		width: 100%;
		border-radius: 0;
		border: 0;
	}

	&.mini,  &.small {
		&.standalone, &:not(.standalone) {
			width: calc(100% - 40px);
			margin: 20px;
			max-height: 100%;
			border-radius: 6px;
		}
	}
}

.with-modal .layer {
	div#main, div.main {
		border-radius: 6px !important;
		overflow: scroll;
		box-shadow: 0 0 80px #111 !important;
	}
}

.main-form.padded {
	padding: 10px;
}

div.table .cell.right-sidebar {
	display: none;
}

.mobile-only {
	display: inherit;
}

span.mobile-only {
	display: inline;
}

.desktop-only, .hide-on-mobile {
	display: none !important;
}

.milestone-widgets .milestone-widget {
	width: auto;
}

#content .center-console {
	margin: -20px -20px 20px -20px;
}

.center-console {
	margin: 0;
	width: 100%;
	background: #efefef;
	padding: 5px 0;
	border: 0;
	display: block;

	li a {
		border-radius: 0px;
		border: 1px solid #e3e3e3;
		border-right: 0;
		padding: 4px 12px;
		background: #efefef;
	}

	li:first-of-type a {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	li:last-of-type a {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		border-right: 1px solid #e3e3e3;
	}

	li a.active {
		background: #fafafa;
	}
/*
	li:first-child a {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	li:last-child a {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
*/
}

.main.headingless .main-content {
	border-radius: 0;
}

.loading-status {
	li {
		width: 100%;
		height: 80px;
		display: block;
	}

	.type-loaded {
		width: 30%;
		display: block;
		text-align: center;
		height: 50px;
	}

	.number-loaded {
		display: block;
		width: 50%;
		margin-top: -60px;
		margin-left: 50%;
		float: right;
	}
}

.infinite-paginator {
	margin-bottom: 20px;

	span {
		display: none;
	}

	a {
		margin: 0;
		min-width: auto;
	}
}

#content {
	margin-bottom: 50px;
}

.table.snapshot-files {
	margin: -20px;
}

.left-sidebar {
	display: none;
}

.record-with-panel {
	margin-left: 0;
}

.layer {
	> div {
		display: flex;
		width: 100%;
	}

	#main, .main {
		border-radius: 6px;
		margin: 0 auto !important;
	}

	h2 {
		color: #444;
	}
}

.share-sheet {
	width: auto;
}

.mobile-nav-bar {
	position: fixed;
	width: 100%;
	z-index: 100;
	display: flex;
	align-items: center;
	height: 44px;

	.back, .view-title, .extra-controls {
		flex-basis: auto;
		white-space: nowrap;
		overflow: hidden;
	}

	.extra-controls {
		margin-left: 10px;
	}

	.back {
		width: 50px;
	}

	.view-title {
		flex: 1;
		min-width: 0; /* or some value */

		h2 {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.iconed-section .section-title .action-icon {
	&.extender.active {
		padding-bottom: 5px;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	span {
		display: none;
	}
}

.iconed-section .new-record-task {
	background: #fafafa;
	padding: 5px;
	border-top: 1px solid #efefef;
	border-bottom: 1px solid #efefef;
	margin: 5px -10px 0 -50px;
}

.search-form.small:before {
	top: 11px;
}

.new-tag .untag {
	visibility: visible;
	float: right;
	margin: -3px -4px -2px 5px;
	height: auto;
	padding: 3px 6px;
	border-radius: 20px;
	background: #f2f2f2;
	line-height: auto;
	font-size: 1.1em;
	border-left: none;
}

#menu {
	background: #2b466e;
	background: #fcfcfc;
	bottom: 0;
	height: 52px;
	position: fixed;
	z-index: 100;
	width: 100%;
	padding: 0;

	.identifier {
		display: none;
	}

	.panel-filter-links {
		margin-bottom: 0;

		&.top {
			width: 100%;
			display: table;
			table-layout: fixed;

			a {
				display: table-cell;
				border-radius: 0;
			}
		}

		&.bottom {
			display: none;
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: table;
		table-layout: fixed;
		width: calc(100% + 20px);

		li {
			display: inline;
			display: table-cell;
			text-align: center;
			width: auto;
			padding: 0;

			a {
				display: block;
				color: #ccc;
				padding: 8px 6px;
				border-radius: 5px;
				margin: 0 5px;
				width: 50%;
				font-size: 1.5em;

				&.active {
					background: #efefef;

					i {
						color: #2b466e;
					}
				}
			}
		}
	}
}

.scope-container {
	text-align: center;
}

.scope-tab-container {
	margin: 0;
	margin-bottom: 10px;

	i {
		display: none;
	}

	.scope-tabs {
		border-radius: 5px;
		box-shadow: 0 0 10px #e0ecff;

		> span {
			border: 0;
			background: white;
			color: #777;

			&.active:not(.loading) {
				color: white;
				background: $green;
				border-bottom: 0;
			}

			&:first-of-type {
				border-bottom-left-radius: 5px;
			}

			&:last-of-type {
				border-bottom-right-radius: 5px;
				border-right: 0;
			}
		}
	}
}

ul.sections {
	text-align: center;

	li a i {
		display: none;
	}
}

.sort.table {
	.filtered-result-count {
		display: none;
	}
}

#main-sidebar {
	width: 100%;
	transform: translateX(-100%);
	position: fixed;
	z-index: 1000;
	margin-left: 0;
	padding: 0;
	transition: transform 200ms ease-in;
	will-change: transform;

	.content {
		height: 100%;
		box-sizing: border-box;
		background: linear-gradient(to bottom right, #ebf3ff, white);
		width: 300px;
		padding: 10px;
		border-right: 3px solid #85a8d6;
		box-shadow: 0 0 30px #555, 0 0 150px #aaaaaa;
	}

	.main-links a {
		margin-right: 0;
	}

	&.slide-in {
		transform: translateX(0);
	}

	&.slide-out {
		transform: translateX(-100%);
	}

	h3.section-title i.fa-minus-circle {
		display: inline-block;
	}
}

.swipe-action {
	width: 80px;
	text-align: center;
	box-sizing: border-box;
	background: #f5f9ff;
	border-left: 1px solid #e0ecff;
	height: 100%;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;


	label {
		margin-bottom: 0;
		font-size: 14px;
		margin-top: 5px;
		padding: 0;
		display: block;
	}

	i {
		font-size: 32px;
		color: $blue-etc;

		&.active {
			&:before {
				font-weight: 900;
			}
		}
	}
}

.file-table {
	.date {
		display: none;
	}

	.file-icon {
		width: 40px;
		font-size: 30px;
	}
}

.action-drop-down {
	z-index: 1000000;
	position: absolute;
	background: white;
	right: 10px;
	top: 50px;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #eaeaea;
	width: 250px;
	box-shadow: 0 0 100px #ccc;
	border-radius: 6px;

	&.hidden {
		display: none;
	}

	.action-name {
		display: inline;
	}

	&:after, &:before {
		bottom: 100%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: #ffffff;
		border-width: 9px;
		right: 10px;
		margin-left: -9px;
	}
	&:before {
		border-color: rgba(113, 158, 206, 0);
		border-bottom-color: #efefef;
		border-width: 10px;
		right: 10px;
		margin-left: -10px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			margin: 0;
			border-bottom: 1px solid #f2f2f2;
			padding: 8px 12px;

			&:first-of-type {
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
			}

			&:last-of-type {
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
				border-bottom: 0;
			}

			a, span {
				color: #444;
			}

			&:hover, &:active {
				background: #f5f9ff;

				.action-name {
					font-weight: 600;
					color: $blue-etc;
				}
			}

			.fa-stack {
				font-size: 1.2em;
			}

			.action-name {
				font-weight: 500;
				font-size: 14px;
				margin-left: 10px;
			}
		}
	}
}

.layer {
	display: flex;

	#main, .main {
		max-width: 90% !important;
	}
}

.main-content .subheader-nav {
	width: calc(100% + 40px);
	margin: -20px -20px 20px -20px;
}

.subheader-nav {
	display: block;
	box-sizing: border-box;
	background: #f6f6f6;
	width: 100%;
	padding: 5px;

	ul.sections li .console-button {
		i {
			display: none;
			width: 20px;
			margin-right: 4px;
			margin-left: -4px;
		}

		&.active {
			background: white;
			font-weight: 700;

			i {
				display: inline-block;
			}

			.count {
				color: #8cbf7e;
				background: #eaf7e5;
			}
		}
	}
}

.milestone-swipe-controls {
	display: flex;
	background: #fafafa;
	margin: -20px -20px 20px -20px;
	padding: 10px 20px;
	align-items: center;

	.control {
		flex-shrink: 0;
		color: #ccc;
		font-size: 22px;

		&:last-of-type {
			text-align: right;
		}
	}

	.title {
		font-weight: 500;
		color: $green;
		font-size: 15px;
		font-weight: 500;
		text-align: center;
		flex: 1;
	}
}

.search-header {
	border-top-left-radius: 0;
	border-top-right-radius: 0;

	form {
		width: 100%;

		i.back {
			visibility: visible;
		}
	}
}

.description.settings {
	margin-left: 0;
}

.user-options .option i {
	display: none;
}

.record-stats-bar {
	&.gray {
		> div > div {
			border-color: #f2f2f2;
		}
	}

	> div {
		display: block;

		> div {
			display: flex;
			align-items: center;
			padding: 10px;
			border-bottom: 1px solid #e0ecff;

			&:last-of-type {
				border-bottom: 0;
			}

			.icons {
				text-align: center;
				width: 40px;
				flex-shrink: 0;
				margin-bottom: 0;
			}

			.info-label {
				text-align: left;
				width: 150px;
				box-sizing: border-box;
				padding-left: 10px;
				margin-bottom: 0;
				flex-shrink: 0;
			}

			.info-value {
				flex: 1;
				text-align: center;
			}
		}
	}
}

.record-row {
	.row-controls {
		width: auto;
	}

	&.with-controls {
		.row-controls {
			display: flex;
			align-items: center;
		}
	}

	&:hover, &:active, &.active {
		.row-controls {
			display: none;
		}
	}
}

.email {
	margin-top: -20px;

	.first-email {
		margin: 0 -10px;

		.email-info-header {
			margin: -10px -20px 20px -20px;
		}
	}

	.message-frame {
		margin: -10px;
		width: calc(100% + 20px);
	}
}

.subheader-search, .subheader-info {
	background: #f9f9f9;
	padding: 10px;

	.search {
		display: flex;
		align-items: center;

		i {
			margin-top: 0;
		}
	}
}

/*
#header .avatar {
	width: 45px;
	height: 45px;
}
*/

#contact .panel .panel-filters {
	p, .intro-icon {
		display: none;
	}

	.filter-buttons {
		margin-top: 0;
		display: block;
		box-sizing: border-box;
		width: 100%;
		text-align: center;

		.search-toggle {
			display: none !important;
		}

		> div:not(.styled-select) {
			padding: 4px 6px;
			font-size: 12px;

			&:before {
				display: none;
			}
		}
	}

	.filter-buttons .search-input-container, .main-search {
		float: none;
		width: calc(100% + 20px);
		background: #f2f2f2;
		margin: -10px -10px 5px -10px;
		display: block;
		padding: 10px;
		box-sizing: border-box;

		.search-input, input[type="text"] {
			display: block;
			width: 100%;
			box-sizing: border-box;
		}
	}
}

.filter-container .filters {
	width: 100%;
	padding: 0;

	.table, .row {
		display: block;
	}

	.cell {
		display: flex;
		padding: 6px 10px;
		align-items: center;
		border-bottom: 1px solid #f2f2f2;

		&.filter-submit, &.extra-options {
			border-bottom: 0;
		}
	}

	.extra-options {
		float: left;
	}

	.filter-submit {
		float: right;
	}

	.table {
		width: 100%;
	}

	.file-name, .exchanged-with, .date-sent, .extra-options, .filter-submit {
		width: auto;
	}

	.label {
		width: 30%;
	}

	.input-field {
		text-align: right;
		flex: 1;

		input {
			max-width: 200px;
		}
	}
}

.inbox-stats {
	float: none;
	text-align: center;
	padding: 10px;
	background: #fafafa;
	margin: -20px -20px -20px -20px;

	li strong {
		background: white;
	}
}

.selected-single-mailbox {
	color: #8cbf7e;
	font-weight: 700;
	margin-top: 10px;
	font-size: 14px;
	background: white;
	display: inline-block;
	padding: 5px 15px;
	border-radius: 40px;
	box-shadow: 0 0 10px #e0ecff;
}

.structure.files, .structure.contacts {
	.info-panel {
		border: 0;
		box-shadow: 0 0 0;
		background: none;
		margin: -10px;
		display: block;
		width: 100%;
		max-width: 100%;

		.recent-files h3, .recent-contacts h3 {
			display: none;
		}
	}
}

#dashboard {
	#agenda {
		background: white;
		border: 0;
	}
}

.tripartite h2 {
	display: none;
}

.notification-index {
	.info-panel {
		display: none;
	}
}

#menu {
	.alert-number, .warning-number {
		background: none;
		color: firebrick;
		position: absolute;
		top: -5px;
		right: -10px;
		padding: 0;
		line-height: auto;

		span {
			display: none;
		}

		&.warning-number {
			color: #e0ecff;
		}

		&:before {
			font-size: 20px;
			font-weight: 900;
			font-family: 'Font Awesome 5 Pro';
			content: "\f111";
		}
	}
}

.qr-code-parent img {
	width: 200px;
}

}

