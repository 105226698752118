.search-results {
	.row .cell {vertical-align: middle; }
	margin: -20px;

	a.block {
		padding: 10px 20px;
		font-weight: 500;
		color: $blue-etc;

		.etc {
			font-weight: normal;
		}

		&:hover, &:active {
			background: #fafafa;
		}
	}

	.info-cell {
		font-size: 12px;
		color: #aaa;

		strong {
			display: block;
			font-weight: 500;
			color: #6ba35b;
			font-size: 15px;

			&.zero {
				color: #ccc;
			}
		}
	}
}
