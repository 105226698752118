.task-list-box {
	/*margin: -10px;*/
	background: #f9fcff;
	border: 2px solid aliceblue;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.task-list-heading {
	background: aliceblue;
	color: $blue-etc;
	font-size: 15px;
	font-weight: 500;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	padding: 10px;
	margin-bottom: 0;

	.add-block {
		font-size: 13px;
		margin: 5px 5px 0 5px;
		font-weight: 500;
		display: inline;
		display: none;

		.add {
			border-radius: 5px;
			padding: 5px 10px;

			&:hover, &:active {
				cursor: pointer;
				background: #b5d0ec;
				color: white;

				i {
					color: white;
				}
			}
		}

		i {
			color: #b5d0ec;
		}
	}

	.stats {
		float: right;
		display: block;
		font-size: 13px;
		font-weight: auto;
		margin-top: -7px;
		vertical-align: middle;

		.number {
			float: none;
			font-size: 13px;
			padding: 3px 5px;
			margin: 0;
			line-height: auto;
			margin-right: 3px;
			color: white;
		}

		strong {
			font-size: 20px;
		}

		.deadline {
			display: inline-block;
			padding: 4px 10px;
			cursor: pointer;
			border: 1px solid aliceblue;

			&.active {
				background: white;
				border-radius: 6px;
				border: 1px solid #ddeaf7;
				/*
				border-top: 2px solid #b5d0ec;
				border-bottom: 2px solid white;
				background: white;

				*/
			}

/*			margin-right: 15px;
			&:last-of-type {
				margin-right: 0;
			}*/
		}

		.overdue {
			color: firebrick;

			.number {
				background: firebrick;
			}
		}

		.today {
			color: goldenrod;

			.number {
				background: goldenrod;
			}
		}

		.this-week {
			color: forestgreen;

			.number {
				background: forestgreen;
			}
		}

		.overdue, .today, .this-week {
			&.empty {
				color: #b5d0ec;
				cursor: auto;

				.number {
					background: #b5d0ec;
				}
			}
		}
	}
}

.checkbox {
	width: 8%;
	text-align: center;

	&.with-circle {
		&:before {
			content: '\f111';
			font-family: 'Font Awesome 5 Pro';
			font-size: 1.4em;
			color: #ccc;
			line-height: 1em;
			cursor: pointer;
		}

		&:hover, &:active {
			&:before {
				content: '\f058';
				font-family: 'Font Awesome 5 Pro';
				color: #ccc;
			}
		}
	}
}

.task-list {
	margin: 0;
	padding: 0;
	text-align: left;
	width: calc(100%);
	box-sizing: border-box;
	display: table;
	/*border-left: 1px solid #f2f2f2;
	border-right: 1px solid #f2f2f2;*/

	label {
		font-size: 13px;
	}

	&.large label {
		font-size: 14px;
	}

	.task, .task-row {
		display: table-row;
		border-bottom: 1px solid #f2f2f2;

		.checkbox, .assignment, .date {
			margin: 0;
			padding: 6px 5px;
			display: table-cell;
		}

		&.overdue {
			.check, .date {
				color: #c54a52;
				font-weight: 500;
			}
		}

		&.today, &.tomorrow {
			.check, .date {
				color: #d8c200;
				font-weight: 500;
			}
		}

		.checkbox {
			width: 8%;
		}

		.assignment	{
			width: 72%;
			font-weight: 500;
		}

		.date {
			width: 20%;
			font-size: 13px;
			text-align: right;
		}

		.task-info {
			font-size: 12px;
			color: slategray;
		}

		&:last-of-type {
			.checkbox, .assignment, .date {
				border-bottom: 0;
			}
		}

		&.toggling {
			.checkbox.with-circle {
				-webkit-animation: fa-spin 2s infinite linear;
				animation: fa-spin 2s infinite linear;

				&:before {
					content: '\f3f4' !important;
					font-family: 'Font Awesome 5 Pro';
				}
			}
		}

		&.complete {
			.date {
				visibility: hidden;
			}

			.checkbox.with-circle {
				&:before {
					content: '\f058';
					font-family: 'Font Awesome 5 Pro';
					color: #67a157;
				}
			}
		}
	}

	.checkbox, .mark-task-incomplete {
		cursor: pointer;
	}

	&.bordered, &.work-list {
		li {
			border-bottom: 1px solid #efefef;
			padding: 5px;

			&:last-child {
				border-bottom: 0;
			}
		}
	}
}

div.work-list {
	.row {
		.cell {
			border-bottom: 1px solid #efefef;
			padding: 5px;
		}

		&:last-child {
			.cell {
				border-bottom: 0;
			}
		}
	}

	.name {
		color: #aaa;
		text-decoration: none;
		padding-left: 0;
	}
}

#sidebar .task-list {
	margin-bottom: 15px;
}

.tab, .tabs {
	margin-bottom: -11px;
	margin-left: -20px;

	ul {
		list-style-type: none;
		border-bottom: 1px solid #e0ecff;

		li {
			display: inline;
			margin: auto 5px;

			a {
				color: #aaa;
				border: 1px solid #d6d6d6;
				border-right: 0;
				border-bottom: 0;
				padding: 5px 10px;

				&:hover, &:active {
					color: #999;
				}

				&.active {
					color: #aaa;
					background: #fafafa;
					border-bottom: 1px solid #fff;
				}
			}

			&:first-child {
				a {
					border-top-left-radius: 3px;
				}
			}

			&:last-child {
				a {
					border-top-right-radius: 3px;
					border-right: 1px solid #d6d6d6;
				}
			}
		}
	}
}

.task-list {
	margin: 0 -10px;
	width: calc(100% + 20px);

	.circle {
		float: left;
		font-size: 1.4em;
		color: #ccc;
	}

	.text {
		font-weight: 500;
		margin-left: 25px;
	}

	.new-link {
		text-decoration: underline;
	}
}

.new-task.pop-under-form {
	border-color: #ddd;
}

.new-task, .edit-file, .new-form {
	background: #fafafa;
	border-radius: 6px;
	border: 1px solid #efefef;
	padding: 10px;

	&.new-task {
		.option {
			border-bottom: 0;
		}

		.title {
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}

		.submit {
			margin-bottom: 10px;
		}
	}

	.submit {
		background: none;
	}

	&.standalone, &.full-page {
		box-shadow: none;
		border: 0;
		border-top: 0;
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
		margin: -20px;

		.submit {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	&.full-page {
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;

		.submit {
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}

	.public-DraftEditor-content {
		height: 100px;
	}

	.option {
		box-sizing: border-box;
		border-bottom: 1px solid #f2f2f2;
		padding: 12px 8px;
		vertical-align: middle;
		width: 100%;
		text-align: left;
		box-sizing: border-box;

		&:last-of-type {
			border-bottom: 0;
		}

		&.title {
			background: #f2f2f2;

/*			input {
				border-color: #e0ecff;
				background: rgba(245, 249, 255, 0.54);
				font-weight: 500;
				color: $blue-etc;
			}
*/
		}

		&.with-icon {
			padding-left: 50px;

			i.option-icon {
				float: left;
				color: #ccc;
				font-size: 1.4em;
				margin-right: 10px;
				width: 30px;
				text-align: center;
				margin-top: 4px;
				margin-left: -40px;
			}
		}
	}


	.task-name {
		margin: -10px -10px 10px -10px;
		padding: 10px;
		background: #fff;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		border-bottom: 2px solid #efefef;
	}

	.options {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		margin: -10px;

		.double-row {
			display: flex;
			width: 100%;

			&.emphasized {
				background: #f6f6f6;
			}
		}
	}

	.submit {
		/*background: #efefef;*/
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		padding: 0;
		margin-bottom: 0;
		text-align: center;
		display: block;
		margin: 0;

		.more {
			background: #fafafa;
			border-radius: 4px;
			float: left;
			font-size: 14px;
			margin: 8px;
			border-radius: 6px;
			font-size: 14px;
			font-weight: 500;
			color: #99b1d3;
			padding: 6px 12px;
			cursor: pointer;

			i {
				color: #ccc;
				margin-right: 8px;
			}
		}

		input {
			margin: 12px;
		}
	}
}

.task-row input, .underlined-input input {
	border: 0;
	background: none;
	display: inline-block;
	border-bottom: 2px solid #efefef;
	border-radius: 0;
	padding: 0;
	padding-bottom: 2px;

	&:focus {
		border-color: #ccc;
	}
}
/*
.task {
	margin: 10px;

	.assignment {
		font-weight: 500;
		font-size: 14px;
		width: 65%;
		vertical-align: middle;

		span {
			cursor: pointer;
		}
	}

	&.complete {
		.assignment {
			color: #bbb;
			text-decoration: line-through;
		}
	}

	.assignment input, .date input {
		width: 100%;
		box-sizing: border-box;
	}

	.edit-controls {
		width: 7%;
		text-align: center;

		i {
			cursor: pointer;
			margin: 0 5px;
			font-weight: 1.5em;

			&.edit {
				display: none;
				color: #e0cd22;
			}

			&.save {
				color: #598a4c;
			}

			&.cancel, &.delete, &.close {
				color: firebrick;
			}
		}
	}

	&:hover, &:active {
		.edit-controls i.edit {
			display: inline;
		}
	}

	.date {
		vertical-align: middle;
		text-align: right;
		font-size: 13px;
		width: 20%;
	}

	.check {
		text-align: center;
		width: 8%;
		color: #ccc;

		&:hover, &:active {
			&:before {
				color: #6ba35b;
			}
		}

		&:before {
			content: '\f1db';
			font-family: 'Font Awesome 5 Pro';
			font-size: 1.5em;
			line-height: 1em;
			cursor: pointer;
		}
	}

	&.complete {
		.assignment {
			color: #bbb;
			text-decoration: line-through;
		}

		.check {
			&:before {
				content: '\f058';
				font-family: 'Font Awesome 5 Pro';
				color: #67a157;
			}
		}
	}
}*/

.new-task-inline, .new-task-inline-2 {
	padding: 10px;
	background: #f5f9ff;
	margin: 0 0 0 0;
	margin: -20px -20px 0 -20px;
	border: 0;
	border-bottom: 1px solid #e0ecff;
	border-radius: 0;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;

	label.standalone {
		color: #9bb8e2;
		font-weight: 600;
		margin-left: 5px;

		i {
			margin-right: 10px;
			font-size: 1.2em;
			color: #b8cdee;
		}
	}

	button.white {
		font-weight: 500;
		font-size: 14px;
		border-color: #e0ecff;

		&:hover, &:active {
			background: #e0ecff;
			border-color: #e0ecff;
		}
	}

	input[type="text"], .styled-select select {
		border-color: #e0ecff;
		background: white;

		&:focus {
			border-color: #b8cdee;
		}
	}
}

.new-header .new-task-inline {
	margin: -16px -16px 20px -16px;
	/*border-radius: 10px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border: 1px solid #e0ecff;*/
}

.new-task-inline-2 {
	background: #fafafa;
/*	border-radius: 0;
	border-left: 0;
	margin: 0 -20px;
	border-right: 0;*/
	border-radius: 5px;
	margin: 0;
	margin-bottom: 20px;
	border: 1px solid #efefef;
}

.milestone-group {
	position: relative;
	display: inline-block;

	.milestone {
		background: #9bb8e2;
		color: white;
		padding: 3px 6px;
		border-radius: 3px;
		font-size: 13px;
		cursor: pointer;
		font-weight: 500;

		i {
			margin-right: 0;
			margin-left: 5px;
			color: #f5f9ff;
		}
	}
}

.new-header .milestone-group {
	.milestone {
		margin-left: 20px;
	}

	.milestone-options {
		left: 20px;
	}
}

.create-from-input {
	color: $blue-etc;
	font-size: 12px;
	font-weight: normal;
}

.milestones {
	-webkit-column-count: 3;
	-webkit-column-gap: 20px;
	-moz-column-count: 3;
	-moz-column-gap: 20px;

	column-count: 3;
	column-gap: 20px;

}

.completed-task-divider {
	margin: 10px -10px -10px -10px;
	padding: 5px 10px;
	background: #fafafa;
	color: #6ba35b;
	font-weight: 500;
	font-size: 14px;

	&.empty {
		display: none;
	}

	&:hover {
		.fa-plus {
			display: inline-block;
		}
	}

	i {
		cursor: pointer;
		color: #ddd;
		margin-left: 5px;
		font-size: 1.2em;
	}

	.fa-plus {
		display: none;
	}

	.number {
		&.none {
			color: #ccc;
		}
	}
}

.milestone-panel {
/*	max-width: calc(33.3% - 20px);
	min-width: 300px;*/
	box-sizing: border-box;
	border: 1px solid #f2f2f2;
	-webkit-column-break-inside: avoid;
	-moz-column-break-inside: avoid;
	column-break-inside: avoid;
	/*float: left;
	margin: 0 10px 20px 10px;*/
	margin: 0;
	margin-bottom: 20px;
	border-radius: 5px;
	padding: 10px;

	.task-row .assignment {
		font-weight: 500;
		font-size: 13px;
		vertical-align: middle;
	}

	h3 {
		background: #fafafa;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		font-size: 15px;
		font-weight: 500;
		padding: 10px;
		margin: -10px -10px 10px -10px;
		color: $blue-etc;

		a {
			color: $blue-etc;

			&:hover, &:active {
				color: $link-blue;
			}
		}

		label {
			color: #444;
			font-size: 13px;
			font-weight: 500;
			margin-left: 5px;
			margin-bottom: -5px;
		}

		form {
			margin: 10px -10px -10px -10px;
			border-top: 1px solid #eee;
			/*background: #f2f2f2;
			border-bottom: 1px solid #eee;*/
			padding: 5px 10px 10px 10px;

		}

		.etc {
			margin-left: 22px;
			font-weight: normal;
			margin-bottom: -5px;
			line-height: 16px;
			margin-top: 5px;
			font-size: 13px;

			strong {
				font-weight: 600;
			}

			&.delete {
				margin-left: 27px;
			}
		}

		i {
			color: #ddd;
			margin-right: 10px;
		}

		.delete.action {
			margin: 0 auto;
			margin-top: 10px;
		}

		.controls {
			float: right;
			visibility: hidden;
			/*background: #f2f2f2;*/
			border-radius: 3px;
			padding: 2px 4px;
			line-height: auto;
			margin: -7px -7px 5px 5px;

			&.show {
				visibility: visible;
			}

			i {
				margin: 3px;
				margin-top: 0;
				font-size: 1.1em;
			}
		}

		.fa-pencil {
			color: #e0cd22;
		}

		.fa-trash {
			color: #a71e29;
		}

		&:hover, &:active {
			.controls {
				visibility: visible;
			}
		}
	}

	.progress {
		background: #f2f2f2;
		margin: -10px -10px 10px -10px;
		padding: 8px 10px;

		.small-progress {
			background: white;
		}
	}

	.table.full-width {
		margin: -10px;
		width: calc(100% + 20px);

		.cell {
			padding: 5px;
		}
	}
}

.left-side-panel.milestone-sidebar {
	.table {
		margin: 0 -20px;
		border-top: 2px solid #efefef;
		border-bottom: 2px solid #efefef;
		width: calc(100% + 20px);

		li {
			padding: 5px 10px;
			cursor: pointer;
			background: #fafafa;
			border-right: 2px solid #efefef;
			color: #777;

			strong {
				font-size: 14px;
			}

			.number {
				color: #777;
				margin-right: -5px;
				font-size: 13px;
			}

			&:hover, &:active, &.active {
				color: $blue-etc;
				background: linear-gradient(to right,  #fafafa 0%,#ffffff 100%);
			}

			&.active {
				background: #fff;
			}
		}
	}

	.small-progress {
		margin: 10px 0 5px 0;
		border: 0;
	}

	.table li strong {
		font-weight: 600;
	}
}

.left-side-panel.milestone-sidebar {
	.table {
		margin: 0 -20px;
		border-top: 2px solid #efefef;
		border-bottom: 2px solid #efefef;
		width: calc(100% + 20px);

		li {
			padding: 5px 10px;
			cursor: pointer;
			background: #fafafa;
			border-right: 2px solid #efefef;
			color: #777;

			strong {
				font-size: 14px;
			}

			.number {
				color: #777;
				margin-right: -5px;
				font-size: 13px;
			}

			&:hover, &:active, &.active {
				color: $blue-etc;
				background: linear-gradient(to right,  #fafafa 0%,#ffffff 100%);
			}

			&.active {
				background: #fff;
			}
		}
	}

	.small-progress {
		margin: 10px 0 5px 0;
		border: 0;
	}

	.table li strong {
		font-weight: 600;
	}
}

.milestone-sort {
	border-right: 2px solid #efefef;
	padding: 10px;
	margin-left: -20px;

	ul.sort li {
		i {
			display: none;
		}

		.button.active i {
			display: inline;
		}
	}
}

.task-queue, .email-queue {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-bottom: -20px;
	width: 300px;
	min-height: 400px;
	max-height: 600px;
	overflow: scroll;

	li {
		border-right: 2px solid #efefef;
		padding: 5px 10px;
		margin: 0;
		background: #fcfcfc;
		border-bottom: 1px solid #efefef;
		cursor: pointer;

		&:last-child {
			border-bottom: 0;
		}

		strong {
			font-weight: 500;
		}

		&.active {
			background: $blue-etc;
			color: aliceblue;
			border-right-color: $blue-etc;

			.fa-circle {
				color: aliceblue;
			}
		}

		.fa-circle {
			color: #ccc;
			margin-right: 8px;

			&:hover, &:active {
				color: forestgreen;
			}
		}
	}
}

.email-queue {
	li {
		padding: 10px;
		word-wrap: break-word;
		background: white;

		strong {
			font-weight: 600;
		}

		&.active {
			background: aliceblue;
			color: $blue-etc;
			border-right-color: $blue-etc;
		}
	}

	.contacts, .thread-date {
		color: #888;
		margin-bottom: 3px;
		font-size: 12px;
	}

	.thread-date {
		float: right;
	}
}

.task-attributes {
	display: table;
	background: #fcfcfc;
	border-top: 1px solid #f6f6f6;
	border-bottom: 1px solid #f6f6f6;
	margin: 10px -20px;
	width: calc(100% + 40px);

	.attribute {
		display: table-row;
	}

	.icon {
		display: table-cell;
		width: 10%;
		padding: 5px 0;
		text-align: center;
	}

	.fa {
		font-size: 1.8em;
		color: #ddd;
	}

	span {
		display: table-cell;
		color: $blue-etc;
		width: 40%;
		vertical-align: middle;
		font-weight: 500;
	}
}

.task-title {
	.fa-circle {
		font-size: 1.2em;
		color: #ccc;
		margin-right: 10px;
	}

	.controls {
		float: right;

		.fa {
			margin-right: 10px;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}
		}

		.edit {
			color: #ebe309;
		}

		.delete {
			color: #d6292c;
		}
	}
}

.expanded-task-list {
}

.task-assignees {
	margin: -20px -20px 20px -20px;
	background: #f2f2f2;
	padding: 10px 20px;

	i {
		color: #99b1d3;
		font-size: 1.4em;
		margin-right: 10px;
	}
}

.task-heading {
	background: #fafafa;
	padding-left: 75px;

	.task-icon {
		font-size: 70px;
		color: #ccc;
		margin-top: 2px;
		cursor: pointer;
		float: left;
		margin-left: -62px;

		&.upcoming {
			color: #e0cd22;
		}

		&.overdue {
			color: #ca5555;
		}
	}

	.task-info {
		margin-left: 10px;
		font-size: 15px;
		font-weight: 400;
		margin-bottom: 10px;
		color: #999;

		span {
			margin-right: 40px;
		}

		i {
			color: #ccc;
			font-size: 1.3em;
			margin-right: 10px;
		}
	}
}

.task-group {
	h2 {
		background: #f6f6f6;
		padding: 10px 20px;
		color: #444;
		margin: 0;

		i {
			background: #f2f2f2;
			border-radius: 20px;
			padding: 6px;
			width: 30px;
			text-align: center;
			float: left;
			margin: -5px 13px -5px -5px;
		}

		.show-all {
			float: right;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			color: $green;
			cursor: pointer;
			background: #fbfbfb;
			border-radius: 50px;
			padding: 2px 12px;
			margin-top: -2px;

			i {
				background: none;
				margin-right: 0;
				width: 20px;
				color: $green;
				font-size: 15px;
				line-height: 20px;
				text-align: center;
			}
		}
	}

	&.urgent {
		h2 i {
			background: #ffd5d5;
			color: firebrick;
		}
	}

	&.upcoming {
		h2 i {
			background: #f5efa5;
			color: #d6c102;
		}
	}

	&.today, &.tomorrow {
		.due-date {
			display: none;
		}
	}
}

#task-list:last-child .task-row:last-of-type {
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}

.milestone.archived {
	.breadcrumb {
		strong {
			color: #ccc;
			font-weight: 500;
		}

		&:before {
			color: #ccc;
			font-weight: 700;
			content: 'ARCHIVED ';
		}
	}
}

.milestone .breadcrumb {
	strong {
		color: #99b1d3;
		font-weight: 600;
		text-transform: uppercase;
	}

	.due-date {
		color: #ccc;
		font-weight: 600;
		text-transform: uppercase;

		&:before {
			content: " / DUE ON ";
			color: #ccc;
			font-weight: 400;
		}
	}
}

.task {
	display: flex;
	padding: 5px;
	border-bottom: 1px solid #f2f2f2;

	&:last-of-type {
		border-bottom: none;
	}

	&.complete {
		color: #aaa;

		.checkbox span {
			&:before {
				content: '\f058';
			}
		}

		.assignment {
			text-decoration: line-through;
			text-decoration-color: #ccc;
		}
	}

	&.urgent .assignment .due-date:before {
		color: #e09393;
	}

	&.upcoming .assignment .due-date:before {
		color: #e0cd22;
	}

	.checkbox {
		text-align: center;
		width: 50px;
		flex: none;
		padding-top: 1px;

		span {
			cursor: pointer;

			&:before {
				font-size: 1.7em;
				color: #ccc;
				content: '\f111';
				font-family: 'Font Awesome 5 Pro';
			}

			&:hover, &:active {
				&:before {
					color: #8cbf7e;
				}
			}
		}
	}

	.assignment {
		font-weight: 500;
		line-height: 24px;

		.due-date, .milestone, .completed-at, .record {
			padding: 0px 10px;
			margin-left: 10px;
			font-weight: 600;
			font-size: 13px;
			white-space: nowrap;
			display: inline-block;
			border-radius: 25px;

			&:before {
				font-family: 'Font Awesome 5 Pro';
				padding-right: 5px;
				font-size: 1.2em;
				font-weight: 400;
			}
		}

		.completed-at {
			background: #f2f2f2;

			&:before {
				content: '\f00c';
				color: #b3ccac;
			}
		}

		.record {
			background: #f2f2f2;
			color: $blue-etc;

			i {
				color: $blue-etc;
				margin-right: 5px;
			}
		}

		.due-date {
			margin-left: 5px;
			color: #999;
			font-weight: 600;
			background: #fafafa;

			&:before {
				content: '\f017';
				color: #ccc;
			}
		}

		.milestone {
			background: aliceblue;
			color: #99b1d3;
		}
	}
}

.side-panel .complete-tasks {
	.completed-task {
		color: #999;
		font-size: 13px;
		margin: 0 -10px;
		padding: 3px 10px;
		border-left: 3px solid transparent;
		border-bottom: 1px solid #fafafa;

		.toggle {
			cursor: pointer;
		}

		.task-controls {
			display: none;
		}

		&.with-controls {
			border-left-color: #ecf4ff;

			.task-controls {
				display: inline-block;
				background: #f6f6f6;
				padding: 2px 7px;
				margin-left: 5px;
				margin-top: 3px;
				border-radius: 4px;
				color: $blue-etc;
				font-size: 12px;

				i {
					color: $blue-etc;
					margin-right: 5px;
				}

				i.fa-undo {
					display: inline-block;
					margin: -3px -8px -3px 10px;
					/* margin-right: 0; */
					background: #f5efa5;
					color: #e0cd22;
					width: 20px;
					line-height: calc(100% + 10px);
					text-align: center;
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
					cursor: pointer;
				}
			}
		}
	}
}

.complete-tasks {
	margin: 10px -20px -20px -20px;
	padding: 20px;
	background: #f9f9f9;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;

	.task .assignment .due-date {
		display: none;
	}

	h2 {
		background: #efefef;
		margin: 0;
		border-radius: 6px;
		margin-bottom: 10px;
	}
}

.tasks-with-indicators {
	margin-left: -5px;
	margin: 20px;

	.task {
		border-left: 5px solid transparent;

		&.urgent {
			border-left-color: #fff2f2;
		}

		&.upcoming {
			border-left-color: #fffcd7;
		}
	}
}

.milestone-widget form.milestone-task {
	border-radius: 0;
	border: 0;
	border-bottom: 1px solid #efefef;
	margin-bottom: 0;

	.date-input {
		margin-top: 10px;
	}

	.submit {
		display: none;
		margin: 0;
		padding-top: 10px;

		&.visible {
			display: block;
		}

		.fa-spinner {
			margin: 0;
			line-height: auto;
		}

		.fa-check {
			color: $green;
		}

		.submit-button {
			width: auto;
			margin: 0;
			text-align: center;
			float: none;
		}
	}
}

form.milestone-task, form.new-task {
	margin-bottom: 20px;
	background: #fafafa;
	border: 1px solid #eaeaea;
	padding: 10px;
	border-radius: 6px;

	input[type="text"] {
		font-weight: 500;
		border-color: #e0ecff;
		color: #99b1d3;
		background: #fafcff;
	}

	.inline-label {
		display: inline-block;
		float: left;
		line-height: 33px;
		margin-right: 10px;
		color: #99b1d3;
		margin-left: 5px;
		font-weight: 500;
		font-size: 14px;

		i {
			font-size: 1.3em;
			margin-right: 10px;
			color: #bbb;
			margin-left: 5px;
		}
	}

	.submit {
		display: flex;
		flex-wrap: nowrap;

		.segmented, .date-input {
			flex-grow: 1;
		}

		&.fade-out {
			.submit-button span.saved {
				opacity: 0.0;
				transition: opacity 2s 2s linear;
			}
		}

		.submit-button {
			span {
				line-height: 30px;
				color: #61a74e;
				text-align: center;
				color: #e0cd22;
				/*background: #c5eab8;*/
				border-top-left-radius: 30px;
				border-bottom-left-radius: 30px;
				display: block;
				font-weight: 600;

				i {
					margin-right: 10px;
				}

				&.saved {
					color: $green;
				}
			}
		}

		input {
			margin: 0;
		}
	}
}

form.new-task {
	box-shadow: none;

	.option:not(.title), .submit {
		display: none;
	}

	.option.title {
		background: #f9f9f9;
		border-bottom: none;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	&.submittable, &.active {
		box-shadow: 0 0 10px #e0ecff;

		.option {
			display: block;
		}

		&.submittable {
			.submit {
				display: block;
			}
		}

		.title {
			background: white;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}

.milestone-list .assignment .meta .milestone {
	display: none;
}

.task-list-enter {
  background: #f9f4bb;
  color: goldenrod;
}

.task-list-enter.task-list-enter-active {
  background: none;
  color: #666;
  transition: 2000ms;
}

.task-list-exit {
  opacity: 1;
}

.task-list-exit.task-list-exit-active {
  opacity: 0.01;
  transition: 700ms;
}

.milestone-list .milestone-description {
	background: #f6f6f6;
	margin: -20px -20px 20px -20px;
	padding: 10px 20px;
	font-weight: 500;
	font-size: 14px;
	border-bottom: 1px solid #f2f2f2;
	color: #86a8d6;
	padding-left: 40px;

	&:before {
		font-size: 1.4em;
		font-family: 'Font Awesome 5 Pro';
		content: '\f05a';
		margin-left: -20px;
		margin-right: 10px;
		float: left;
		display: block;
		color: #c4d8f5;
	}
}

.milestone-widgets {
/*	column-width: 300px;
	column-gap: 10px;*/

	.milestone-widget {
		margin-bottom: 10px;
		/*break-inside: avoid-column;*/
		box-sizing: border-box;
		border-radius: 6px;
		border-radius: 6px;
		border: 1px solid #efefef;

		.task {
			.milestone, .completed-at, .due-date {
				display: none;
			}
		}

		h4 {
			font-weight: 600;
			color: $blue-etc;
			font-size: 14px;
			margin: 0;
			margin-bottom: 0;
			background: #fafafa;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
			padding: 10px 10px 10px 40px;

			a {
				span {
					-webkit-box-decoration-break: clone;
					box-decoration-break: clone;
					padding: 5px;
					border-radius: 5px;
				}

				&:hover, &:active {
					span {
						background: aliceblue;
					}
				}
			}

			.progress-indicator {
				font-size: 22px;
				position: absolute;
				margin-top: -3px;
				float: left;
				margin-left: -30px;
			}
		}

		.milestone-stats {
			background: #f2f2f2;
			padding: 5px 10px;
			font-size: 13px;
			margin: 0;
			font-weight: 500;
			color: #999;
		}
	}
}

.milestone-widgets .fa-thumbtack, .milestone-table .fa-thumbtack, .milestone-widgets .fa-plus, .contact-card .fa-thumbtack {
	color: #e0cd22;
	padding: 5px;
	cursor: pointer;
	margin-top: -3px;
	float: right;
	border-radius: 5px;
	margin-left: 5px;

	&:hover, &:active {
		background: #fdf9cd;
	}
}

.milestone-widgets .fa-plus {
	color: $green;

	&:hover, &:active, &.active {
		background: #e0f3da;
	}
}

.milestone-table .fa-thumbtack {
	color: #ccc;

	&:hover, &:active {
		background: #eaeaea;
		color: #e0cd22;
	}
}

.other-milestones, .contact-lists {
	margin: 10px -20px -20px -20px;
	padding: 20px;
	background: #f9f9f9;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;

	h2 {
		margin: 0;
		color: #888;
		margin-bottom: 10px;
		padding: 0;
	}
}

.milestone-stats {
	.due-date:before {
		content: 'Due on ';
		color: #bbb;
	}

	span {
		color: #aaa;
		font-weight: 600;

		&.incomplete-task-count {
			color: #e0cd22;
		}

		&.no-due-date {
			font-weight: 400;
		}

		&:after {
			margin: 0 5px;
			font-weight: 700;
			color: #ddd;
			content: "\00b7";
		}

		&:last-of-type:after {
			content: "";
		}
	}
/*
	span {
		font-weight: 500;
		display: inline-block;
		background: #efefef;
		padding: 3px 6px;
		border-radius: 5px;

		strong {
			font-weight: 600;
		}
	}*/
}

ul.table.milestone-table {
	width: 100%;

	li {
		border-bottom: 0;

		.milestone-stats {
			margin-top: 5px;
		}

		.block {
			padding: 10px;
			padding-left: 72px;
			border-radius: 10px;
			min-height: 52px;

			.more {
				color: #aaa;
			}

			.progress-indicator {
				margin-left: -62px;
				float: left;
			}

			&:hover, &:active {
				background: #f2f2f2;
			}
		}
	}
}

.milestone-widget .no-tasks {
	text-align: center;

	.fa-circle {
		color: #eee;
		margin: 10px;
		font-size: 8em;
		display: block;
	}

	strong {
		display: block;
		margin: 0 20px 20px 20px;
		color: #aaa;
		font-weight: 500;
	}
}

.task-timeline {
	box-sizing: border-box;
	width: calc(100% + 40px);
	margin: -20px -20px 60px -20px;
	padding: 0;
	height: 100px;
	background: #fafafa;
	border-top: 1px solid #f2f2f2;
	position: relative;

	.legend {
		position: absolute;
		top: 10px;
		left: 20px;
		font-weight: 600;
		font-size: 13px;
		overflow: hidden;
		border-radius: 6px;

		.key {
			position: relative;
			margin: 5px 8px;
			background: transparent;
			z-index: 100;
		}

		&:before {
			display: block;
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			opacity: 0.8;
			background: #efefef;
		}
	}

	.dates {
		background: #f6f6f6;
		width: 100%;
		padding: 8px 0;
		margin-top: -5px;
		display: table;
		table-layout: fixed;
		font-size: 12px;
		color: #999;
		font-weight: 500;
		border-top: 1px solid #efefef;
		border-bottom: 1px solid #efefef;

		span {
			display: table-cell;
			text-align: center;
		}
	}
}

.snapshot-milestone {
	display: flex;
	padding: 5px 10px;
	border-radius: 6px;
	width: 100%;
	box-sizing: border-box;

	&:hover, &:active {
		background: #f9f9f9;

		.progress-bar {
			background: #efefef;
		}
	}

	.name {
		flex: 1;
		font-size: 15px;
		font-weight: 500;
		padding-top: 5px;
	}

	.due {
		font-size: 13px;
		color: #bbb;
		width: 200px;
		text-align: center;
		font-weight: 500;
		line-height: 25px;
	}

	.progress-bar {
		background: #f2f2f2;
		border-radius: 50px;
		overflow: hidden;
		height: 25px;
		width: 200px;

		&.zero {
			.percentage {
				display: none;
			}

			.complete {
				display: none;
			}
		}

		&.done {
			.complete {
				border-right: 0;
			}
		}

		.percentage {
			color: white;
			font-size: 14px;
			font-weight: 600;
			line-height: 25px;
		}

		&.behind {
			.complete {
				background: #e0cd22;
			}

			.percentage {
				color: goldenrod;
				margin-left: 10px;
			}
		}

		.complete {
			background: $green;
			border-right: 2px solid white;
			height: 100%;
			text-align: right;
			float: left;
			padding: 0 10px;
			box-sizing: border-box;
		}
	}
}

.milestone-number {
	display: inline-block;
	font-size: 13px;
	color: #aaa;
	margin-left: 30px;
	float: right;

	.expand {
		display: inline-block;
		line-height: 18px;
		margin-top: -1px;
		padding: 3px 8px;
		border-radius: 60px;
		color: $blue-etc;
		font-size: 12px;
		font-weight: 500;
		margin-left: 15px;
		background: white;
		cursor: pointer;

		i {
			margin-left: 5px;
			margin-right: 0;
			color: $blue-etc;
		}

		&:hover, &:active {
			background: #fafafa;
		}
	}
}

form.new-task {
	padding: 0;
}

.contact .tasks-with-indicators {
	margin: 0;
	margin-top: -10px;
}

i.toggle-task-form {
	float: right;
	color: #ccc;
	cursor: pointer;
	line-height: inherit;
	margin-right: 0;

	&:hover, &:active, &.active {
		color: $green;
	}
}

.new-task-list .task-row , .view-task, .milestone-tasks .task-row {
	&.complete .checkbox {
		&:before {
			content: '\f058';
			color: $green;
			font-weight: 700;
		}
	}

	.checkbox {
		float: left;
		width: 60px;
		display: block;
		text-align: center;
		margin-top: 1px;
		margin-left: -65px;
		cursor: pointer;

		&:before {
			content: '\f111';
			font-size: 24px;
			border-radius: 50%;
			font-family: 'Font Awesome 5 Pro';
			color: #ccc;
		}

		&:hover, &:active {
			&:before {
				content: '\f058';
				color: $green;
				box-shadow: 0 0 10px #c5eab8;
			}
		}
	}
}

.new-task-list, .milestone-tasks {
	margin: 0 -20px;

	&.complete-tasks {
		.task-row.complete {
			background: none;
		}
	}

	.task-row {
		border-bottom: 1px solid #f2f2f2;
		padding: 8px 20px 8px 70px;
		position: relative;

		&:last-of-type {
			border-bottom: 0;
		}

		&:hover, &:active {
			.description i {
				display: inline-block;
			}

			.edit {
				display: block;
			}
		}

		&.complete {
			background: #fafafa;

			.meta .description, .meta .due-date {
				display: none;
			}

			.assignment {
				a {
					color: #bbb;
				}

				.meta a {
					text-decoration: none;
					color: #bbb;

					&:before {
						color: #ccc;
					}
				}

				a {
					text-decoration: line-through;
				}
			}
		}

		&.urgent {
			.assignment .meta .due-date {
				&:hover, &:active { background: none; }

				color: firebrick;

				&:before { color: firebrick; }
			}
		}

		.edit {
			display: none;
			position: absolute;
			right: 10px;
			top: 5px;
			background: white;
			padding: 5px;

			i {
				padding: 5px;
				border-radius: 5px;
				background: #f5f1c6;
				color: #e0cd22;
				cursor: pointer;

				&:hover, &:active {
					background: #f5efa5;
				}
			}
		}

		.assignment {
			font-weight: 500;
			padding-top: 2px;

			a {
				color: #666;

				span {
					padding: 3px 6px;
				}
			}
		}

		.description {
			font-size: 13px;
		}

		.meta {
			display: inline;
			margin-left: 10px;

			.milestone, .record, .completed-at, .due-date {
				color: #999;
				font-size: 13px;
				border-radius: 5px;
				/*padding: 3px 8px;*/
				padding: 0 8px;
				margin-right: 10px;
				display: inline-block;

				&:hover, &:active {
					background: #f9f9f9;
				}

				&.completed-at {
					color: $green;

					&:before {
						content: '\f274';
					}

					&:hover, &:active {
						background: none;
					}
				}

				&.due-date {
					&:hover, &:active {
						background: none;
					}

					&:before {
						content: '\f34e';
						color: #e0cd22;
					}
				}

				&:before {
					margin-right: 8px;
					color: $green;
					font-size: 14px;
					font-family: 'Font Awesome 5 Pro';
					font-weight: 300;
				}

				&.milestone {
					&:before {
						content: '\f277';
					}
				}

				&.contact {
					&:before {
						content: '\f2bd';
						color: $blue-etc;
					}
				}

				&.organization  {
					&:before {
						content: '\f1ad';
						color: $blue-etc;
					}
				}

				&.file {
					&:before {
						content: '\f15c';
						color: $blue-etc;
					}
				}
			}
		}
	}
}

.milestone-widget .milestone-tasks .task-row:last-of-type {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.milestone-tasks {
	margin: 0;

	.task-row {
		padding: 5px 10px 5px 40px;
		box-sizing: border-box;
		min-height: 38px;

		.checkbox {
			margin-top: 4px;
			margin-left: -40px;
			width: 40px;

			&:before {
				font-size: 22px;
			}
		}

		.assignment a {
			margin: 0;
		}

		.description {
			display: none;
		}

		.meta .milestone {
			display: none;
		}
	}
}

.view-task {
	border-radius: 5px;
	overflow: hidden;

	.responsibility {
		background: #f2f2f2;
		padding: 5px 10px 5px 40px;

		.text-button {
			margin: 5px 0;
		}

		.fa-user {
			float: left;
			margin-left: -35px;
			width: 30px;
			margin-top: 8px;
			font-size: 18px;
			text-align: center;
			color: #aaa;
		}
	}

	&.complete {
		h2 {
			color: #bbb;

			.edit {
				display: none;
			}
		}

		.checkbox:before {
			content: '\f058';
		}

		.completed-at {
			color: #8cbf7e;
			padding-top: 5px;
			line-height: initial;
			font-size: 16px;
			font-weight: 500;

			&:before {
				color: $green;
				font-family: 'Font Awesome 5 Pro';
				margin-right: 10px;
				content: '\f274';
			}
		}
	}

	.styled-select.small {
		line-height: initial;
		margin-top: 4px;
		margin-bottom: -4px;
	}

	.react-datepicker-wrapper {
		margin-top: 4px;
		margin-bottom: -4px;
	}

	&.edit {
		&:not(.existing) {
			h2 {
				i.edit {
					display: none;
				}

				input {
					width: 100%;
				}
			}
		}

		h2 {
			padding: 14px 20px;
			height: 64px;
			box-sizing: border-box;

			input {
				font-size: 16px;
				width: calc(100% - 70px);
				box-sizing: border-box;
				background: #f8fbff;
				border-color: #e0ecff;
				color: #86a8d6;
				font-weight: 500;
			}
		}
	}

	h2 {
		margin: 0;
		padding: 14px 20px;
		line-height: 28px;
		font-weight: 500;
		padding-left: 80px;
		font-size: 20px;

		.edit {
			float: right;
			cursor: pointer;
			color: #e0cd22;
			font-size: 18px;
			display: block;
			line-height: 35px;
			width: 35px;
			text-align: center;
			border-radius: 50%;
			margin: 0px 0px 0 10px;

			&.active {
				background: #e0cd22;
				color: white;
			}

			&:not(.active) {
				&:hover, &:active {
					background: #f5efa5;
				}
			}
		}

		.checkbox {
			width: 80px;
			margin-left: -80px;
			margin-top: 0px;

			&:before {
				font-size: 36px;
			}
		}
	}

	.meta {
		display: table;
		width: 100%;
		table-layout: fixed;
		background: #f6f6f6;
		padding: 10px 0 5px 0;

		> div {
			display: table-cell;
			text-align: center;
			font-weight: 500;
			padding: 10px;

			> span, > a {
				display: inline-block;
				padding: 3px 8px;
				border-radius: 6px;
				margin-top: 7px;
			}

			> a:hover, > a:active {
				background: #efefef;
			}

			&:before {
				font-family: 'Font Awesome 5 Pro';
				display: block;
				color: $green;
				font-weight: 300;
				font-size: 30px;
				line-height: 40px;
			}

			&.none {
				color: #999;

				&:before {
					color: #ccc;
				}
			}
		}

		.due {
			&:before {
				content: '\f073';
			}
		}

		.record {
			&:before {
				content: '\f111';
			}

			&.contact {
				&:before {
					content: '\f2bd';
				}
			}

			&.file {
				&:before {
					content: '\f15c';
				}
			}

			&.organization {
				&:before {
					content: '\f1ad';
				}
			}
		}

		.milestone {
			&:before {
				content: '\f277';
			}
		}
	}

	.description {
		font-size: 15px;
		color: #888;
	}
}

.new-task.pop-under-form {
	padding: 0;
	width: 450px;

	h2 {
		background: #fafafa;
	}

	.submit {
		background: none;
		margin-bottom: -20px;
	}
}

.task-created {
	padding: 30px;
	text-align: center;

	i {
		font-size: 120px;
		color: $green;
	}

	h5 {
		color: $green;
		margin-top: 40px;
		font-weight: 500;
		font-size: 20px;
	}
}

#task-list {
	margin-top: -20px;
	margin-bottom: -20px;
}

.button.new-task-button {
	float: right;
	font-weight: 600;
	margin-top: -3px;
}

.task-index-header {
	position: relative;

	.new-task.pop-under-form {
		top: 55px;
		right: 15px;
	}
}

.text-button {
	display: inline-block;
	background: #f6f6f6;
	font-size: 14px;
	padding: 3px 10px;
	border-radius: 6px;
	font-weight: 500;
	cursor: pointer;

	&.small {
		font-size: 13px;
		padding: 2px 8px;
	}

	&:hover, &:active {
		color: #333;
		background: #f2f2f2;
	}
}

.milestone-form .set-due-date {
	margin: 3px 0;
}

.file-tasks, .contact-tasks {
	/*margin: -10px 0 20px 0;
	padding: 0 20px;*/
	background: #fafafa;
	margin: -20px -20px 20px -20px;
	padding: 0px 20px;
	border-bottom: 1px solid #f2f2f2;

	.task-row.later {
		border-left: 3px solid #f5efa5;
	}

	.task-row.urgent, .task-row.overdue {
		border-left: 3px solid #e07878;
	}


	.task-row.complete {
		background: #f2f2f2;
	}

	h2 {
		margin: 0 -20px;
		display: none;

		i {
			width: 50px;
			text-align: center
		}
	}
}

.milestone-widgets {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  margin-left: -20px; /* gutter size offset */
  display: flex;
  width: auto;
}
.milestone-widget-column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}