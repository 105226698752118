#agenda, #inbox-container, .contacts .tripartite {
	h2 {
		font-size: 18px;
		margin-top: 0;
		padding: 0;
		text-align: center;

		strong {
			font-weight: 600;
			color: $green;
		}

		i {
			margin-right: 10px;
			color: $green;
			margin-right: 15px;
			color: #8cbf7e;
			background: #c5eab873;
			line-height: 40px;
			width: 40px;
			text-align: center;
			font-size: 20px;
			border-radius: 50%;
		}
	}

	.empty-congrats h2 {
		font-size: 24px;
		font-weight: 500;
	}
}

#dashboard {
	display: table;
	margin: -20px;
	width: calc(100% + 40px);
	border-radius: 6px;

	#agenda, #inbox-container {
		display: table-cell;
		box-sizing: border-box;
		padding: 0 20px;

		&:first-of-type {
			border-right: 1px solid #efefef;
			border-top-left-radius: 6px;
		}
	}

	#agenda {
		width: 35%;
		padding: 20px;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		background: #fcfcfc;

		h2 {
			text-align: left;
		}

		.no-results h2 {
			text-align: center;
		}

		.notifications {
			margin: 0 -5px 20px -5px;

			> div {
				background: #f8f8f8;
				padding: 5px;
				border-radius: 5px;
			}

			h2 {
				margin-top: 0;

				a {
					color: #555;
				}

				.text-button {
					line-height: 18px;
					color: #aaa;
					margin-top: 10px;
					font-weight: 500;
					font-size: 14px;
					float: right;
				}
			}
		}

		.new-task-list {
			.task-row {
				padding: 5px 10px 5px 45px;

				.meta {
					margin-left: 10px;
					font-size: 13px;
				}

				.checkbox {
					width: 45px;
					margin-left: -45px;
				}

				.assignment {
					font-size: 13px;
				}

				.description {
					display: none;
				}

				&.complete {
					background: none;

					.completed-at {
						display: none;
					}
				}
			}
		}

		.today, .tomorrow {
			.due-date {
				display: none;
			}
		}

		.today .date-heading {
			color: #ce6f6f;

			&:before {
				color: #ce6f6f;
				content: "\f017";
			}
		}

		.tomorrow .date-heading {
			color: #e0cd22;

			&:before {
				color: #e0cd22;
				content: "\f0f4";
			}
		}

		.date-heading {
			font-weight: 500;
			margin: 20px -20px 10px -20px;
			font-size: 16px;
		}

		.date-section {
			font-size: 14px;
			font-weight: 600;
			position: relative;
			margin: 10px -20px;

			span {
				display: inline-block;
				background: #f5f9ff;
				position: relative;
				margin-left: 10px;
				padding: 4px 10px;
				z-index: 100;
				border-radius: 6px;
				border: 1px solid #f5f9ff;
			}

			&:after {
				position: absolute;
				border-top: 2px solid #fafafa;
				width: 100%;
				top: 0px;
				display: block;
				z-index: 1;
				margin-top: 14px;
				content: " ";
			}
		}

		.overview {
			background: #f6f6f6;
			padding: 8px 0;
			display: table;
			width: calc(100% + 40px);
			table-layout: fixed;
			margin: 0 -20px 20px -20px;
			display: none;

			> div {
				display: table-cell;
				text-align: center;

				span {
					display: block;
					font-weight: 500;
					font-size: 14px;
				}

				strong {
					line-height: 40px;
					color: $blue-etc;
					font-size: 32px;
					font-weight: 500;
				}
			}
		}
	}

	#inbox-container {
		width: 65%;
		padding: 20px;

		.hero-search {
			display: none;
			text-align: center;
			width: 100%;

			input {
				font-size: 18px;
				text-align: center;
				width: 80%;
			}
		}

		h3 {
			margin: 10px -20px;
			color: #444;
			font-weight: 500;
			font-size: 18px;
			letter-spacing: -.2px;
		}

		.with-icon-before {
			&:before {
				margin-left: -5px;
				/*background: #fafafa;
				border-color: #f2f2f2;
				background: #e5f6df;
				border-color: #c8e1c1;*/
			}
		}

		.unread-emails {
			.email-table {
				margin: -10px -20px 10px -20px;
			}

			h3 {
				padding: 5px;

/*				border-top: 2px solid #f2f2f2;
				padding: 5px;
				background: #fafafa;
*/
				&:before {
					content: "\f2b6";
					color: $green;
					background: #fafafa;
					border-color: #f2f2f2;
				}
			}
		}

		.priority-contacts h3 {
			margin: 10px -20px 10px -5px;
		}

		.priority-files {
			clear: both;

		}
	}
}

.priority-contacts {
	margin: 20px -10px;

	h3 {
		margin: 10px -20px 10px -5px;

		&:before {
			background: #fafafa;
			border-color: #f2f2f2;
			content: "\f0c0";
			color: $green;
		};
	}

	.contact {
		float: left;
		text-align: center;
		width: calc(20% - 10px);
		box-sizing: border-box;
		margin: 5px;
		overflow: hidden;

		span {
			display: table-cell;
			vertical-align: middle;
		}

		&:hover, &:active {
			.name {
				/*background: #f6f6f6;*/
				color: #74af64;
			}

			.inline.avatar {
				box-shadow: 0 0 20px #c5eab8;
			}
		}

		.name {
			margin-top: 0px;
			/*background: #fafafa;*/
			display: table;
			width: 100%;
			padding: 4px;
			box-sizing: border-box;
			min-height: 46px;
			font-size: 14px;
			color: #444;
			border-radius: 6px;
			font-weight: 500;
		}

		.avatar.inline {
			display: inline-block;
			height: 60px;
			width: 60px;
			z-index: 0;

			i {
				font-size: 61px;
			}

			.initials-overlay {
				font-size: 14px;
				top: 38px;
			}
		}
	}
}

.with-icon-before {
	&:before {
		content: "\f39b";
		color: $green;
		display: inline-block;
		background: white;
		width: 30px;
		text-align: center;
		font-size: 18px;
		line-height: 24px;
		border: 1px solid #f6f6f6;
		border-left: 0;
		border-bottom-right-radius: 6px;
		border-top-right-radius: 6px;
		margin-right: 10px;
		padding: 5px 10px;
		font-family: 'Font Awesome 5 Pro';
	}

	&.contacts:before {
		content: "\f0c0";
	}
}