@media only screen and (min-width: 481px) {
	.subheader-nav, .h1-sidenav {
		text-align: center;
		/*background: linear-gradient(to bottom, white, #f5f9ff);*/
		background: #fafafa;
		padding: 10px;
		margin-top: 5px;

		&.h1-sidenav {
			background: none;
			padding: 5px;
			flex-basis: 100%;

			/*background: #f2f2f2;
	    margin: 10px -10px -10px -10px;
	    padding: 10px;*/

			ul.sections {
				li {
					.console-button {
						margin: 0 3px;
						font-size: 14px;
						color: #aaa;

						&.active {
							background: none;
							box-shadow: 0 0 30px #f2f2f2;
						}

						i {
							/*display: none;*/
							width: 26px;
							font-size: 20px;
						}
					}
				}
			}
		}

		ul.sections {
			li {
				.console-button {
					display: inline-flex;
					margin: 0 10px;
					background: none;
					align-items: center;
					border-radius: 10px;
					border: 0;
					font-size: 16px;
					line-height: initial;
					color: #bbb;

					.count {
						background: #f2f2f2;
						font-size: 13px;
						line-height: 22px;
						color: #aaa;
					}

					i {
						flex-shrink: 0;
						width: 35px;
						text-align: center;
						font-size: 26px;
						color: #ddd;
					}

					&:hover, &:active {
						background: none;
						box-shadow: 0 0 30px #f2f2f2;
					}

					&.active {
						background: white;
						color: $green;

						.count {
							background: white;
							color: $green;
						}

						i:before {
							color: $green;
							font-weight: 900;
						}
					}
				}
			}
		}
	}

	.contact-timeline {
		height: 100px;
	}
}