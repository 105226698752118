
.slide-in-appear {
	opacity: 0;
}

.slide-in-appear.slide-in-appear-active {
	opacity: 1;
	transition: opacity .9s ease-in;
}

.slide-in-enter.slide-in-enter-active {
	opacity: 1;
	transition: opacity 500ms ease-in;
}

.slide-in-disappear {
	opacity: 0;
	transition: opacity .9s ease-in;
}

.slide-in-exit.slide-in-exit-active {
	opacity: 0.01;
	transition: opacity 3000ms ease-in;
}
