button:focus {
	border: none;
}

acronym, abbr {
	border-bottom: 1px dotted #999;
	text-decoration: none;
}

pre code {
	overflow: scroll;
	max-width: 700px;
}

h1, h2, h3, h4 {
	font-weight: 600;
}

h2, h3 {
	font-size: 20px;
	font-weight: 500;
	color: #6ba35b;
	line-height: 24px;
	padding: 0;
	/*
	margin-bottom: 20px;
	border-bottom: 1px solid #efefef;
	background: #fafafa;*/

	i {
		color: #c5e3bb;
		font-size: 1em;
		margin-right: 10px;
	}

	a {
		color: #aaa;
	}
}

h3 {
	font-size: 16px;
	line-height: 22px;
	color: #666;
	font-weight: 500;

	i {
		font-size: 1.3em;
		color: #ccc;
		width: 30px;
		text-align: center;
	}
}

h4 {
	font-size: 16px;
	font-weight: 600;
	margin-top: 0;

	&.question {
		margin-bottom: -10px;
	}

	&.block {
		background: #fafafa;
		padding-top: 10px;
		border: 1px solid #f2f2f2;
		border-radius: 5px;
		color: #999;

		.icon i {
			color: #aaa;
		}

		&.underlined {
			border-bottom: 0;
			margin-bottom: 0;
		}
	}
}

hr {
	color: #efefef;
	height: 1px;
	border: 0;
	background: #efefef;
	margin: 10px 5% 10px 5%;
}

img {
	border: 0;
	vertical-align: middle;
}
