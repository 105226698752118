.resolved-badge, .snoozed-badge {
	display: none;
	float: left;
	padding: 5px 10px;
	background: #d8f1cf;
	color: #8cbf7e;
	font-size: 13px;
	font-weight: 600;
	text-transform: uppercase;
	margin-left: -10px;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	margin-right: 10px;

	i {
		margin-right: -5px;
		padding: 5px;
		font-size: 12px;
		border-radius: 3px;

		&:hover, &:active {
			background: #b3e4a1;
		}
	}

	&.snoozed-badge {
		background: #f5efa5;
		color: #e0cd22;

		i {
			&:hover, &:active {
				background: #efe45e;
			}
		}
	}
}

.email-table, .file-table {
	margin: -20px;

	.message-count {
		color: #aaa;
		background: #f2f2f2;
		border-radius: 10px;
		min-width: 18px;
		line-height: 18px;
		padding: 0 4px;
		font-weight: 400;
		margin-left: 4px;
		display: inline-block;
		text-align: center;
		box-sizing: border-box;
	}

	.snooze.pop-under-form {
		width: 250px;
	}

	.record-row {
		&:hover, &:active {
			background: #f5f9ff;
			cursor: pointer;

			.message-count {
				background: #f1f6ff;
			}
		}

		&.resolved, &.snoozed {
			.resolved-badge {
				display: block;
			}

			.snoozed-badge {
				display: none;
			}

			&:hover, &:active {
				.email-controls {
					display: none;
				}
			}

			.etc {
				display: none;
			}

			.subject, .participants, &.unread .subject, &.unread .participants {
				color: #999;
			}
		}

		&.snoozed {
			.resolved-badge {
				display: none;
			}

			.snoozed-badge {
				display: block;
			}
		}

		.resolved-badge {
			display: none;
		}

		&.unread {
			background: none;

			&:hover, &:active {
				background: #f5f9ff;
			}

			.subject, .participants {
				color: #444;
			}
			/*.subject {
				font-weight: 600;
				color: $blue-etc;
			}

			.participants {
				font-weight: 700;
				color: $blue-etc;
			}
	*/
			.etc {
				color: #666;
			}
		}
	}

	.subject, .participants {
		color: #777;
	}

	.participants {
		font-weight: 600;
		font-size: 13px;
	}

	.indicators {
		margin-right: 5px;
		float: left;

		i {
			font-size: 16px;
			margin-right: 5px;
		}

		.fa-paperclip {
			color: #e0cd22;
		}

		.fa-rss {
			color: $green;
		}

		.fa-flag {
			color: firebrick;
		}

		.fa-circle {
			color: $blue-etc;
		}
	}

	.date {
		float: right;
		font-size: 13px;
		text-align: right;
		font-weight: 500;
		margin-top: 0;

		.snooze-info {
			color: #e0cd22;

			i {
				margin-right: 5px;
			}
		}
	}

	.subject {
		font-weight: 500;
	}
}

.snooze {
	padding: 15px 10px;
	border-radius: 5px;
	color: $blue-etc;
	text-align: center;
	cursor: pointer;
	font-size: 12px;
	line-height: 14px;

	&.cell {
		&:hover, &:active {
			background: aliceblue;
		}
	}

	i {
		font-size: 2em;
		margin-bottom: 5px;
		display: block;
	}

	.num {
		font-size: 16px;
		font-weight: 500;
	}
}

.email {
	line-height: 1.5em;
}

.email-heading {
	background: #f9fcff;
	padding: 10px;
	border-bottom: 2px solid #e1f0ff;
	margin: -20px -20px 20px -20px;
	color: #999;
	font-size: 13px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;

	.button-icons {
		margin: 0;
		border: 0;
		background: #e1f0ff;

		a, span {
			&:hover {
				i {
					color: #b2c0d0;
				}
			}

			i {
				color: #cbdced;
			}
		}
	}

	.label {
		background: #eee;
		color: #bbb;
		padding: 2px 6px;
		border-radius: 4px;
		font-size: 13px;
		display: inline-block;
		margin-right: 5px;
		font-weight: 500;
	}

	.date, .participants {
		float: none;
		font-size: 15px;
		margin-top: 5px;
	}

	.date {
		margin-top: 0;
		font-size: 13px;
	}

	.participants {
		font-weight: 500;
	}

	.subject {
		margin-bottom: 5px;
		color: #666;
		line-height: 24px;
		font-size: 20px;
		font-weight: 600;
	}
}

#content .email-heading {
	border-top-right-radius: 0;
	border-top-left-radius: 0;

	.button-icons {
		background: none;
	}

}

.first-email {
	padding: 0 10px;

	.email-body {
		font-weight: 500;
		margin-left: 0;
		white-space: pre-wrap;

		br:nth-child(n + 1) { display: none; }​

		.message-frame {
			margin: -10px -20px;
			width: calc(100% + 40px)
		}
	}

	.email-info-header {
		font-size: 15px;
		font-weight: 500;
		margin: -10px -20px 20px -20px;
		background: #fafafa;
		/* border-radius: 6px; */
		padding: 5px 20px;
		display: flex;
		flex-wrap: wrap;
		border-top: 1px solid #f2f2f2;
		border-bottom: 1px solid #f2f2f2;

		.inline-attachments {
			background: #f2f2f2;
			display: block;
			box-sizing: border-box;
			width: calc(100% + 40px);
			padding: 3px 20px 3px 56px;
			font-size: 13px;
			margin: 5px -20px -10px -20px;

			> div {
				border: 1px solid transparent;
				border-radius: 6px;
				display: inline-block;
				padding: 3px 8px;
				margin: 2px 5px;

				&:hover, &:active {
					background: #f6f6f6;
					border-color: #efefef;
				}
			}

			.fa-paperclip {
				float: left;
				margin-left: -35px;
				margin-top: 5px;
			}
		}

		.avatar {
			width: 30px;
			height: 30px;
		}

		.from {
			flex: 1;
			line-height: 30px;
		}

		.to, .cc {
			width: 100%;
			border-top: 1px solid #f6f6f6;
			padding-top: 8px;
			font-size: 13px;
		}

		.from, .to, .cc {
			.field {
				display: inline-block;
				width: 50px;
			}
		}

		.date {
			margin-top: 0;
			line-height: 30px;
			font-size: 13px;

			&:before {
				content: "\f017";
				font-family: 'Font Awesome 5 Pro';
				margin-right: 5px;
				font-size: 1.3em;
				color: #99b1d3;
			}
		}
	}
}

.email-options {
	font-size: 2em;
	float: right;
	color: #ddd;
	display: none;
	vertical-align: middle;
	margin-left: 10px;
}

.mailing-lists {
	margin: -20px;

	h2 {
		background: #fafafa;
		margin: 20px -20px;
		padding: 10px 30px;
	}

	> a {
		padding: 8px 10px;
		box-sizing: border-box;
		display: flex;
		border-bottom: 1px solid #f2f2f2;

		> div {
			padding: 0 8px;
		}

		&:last-of-type {
			border-bottom: 0;
		}

		&:hover, &:active {
			background: #fffcd766;
		}
	}

	.activity-indicator {
		width: 50px;
		flex-basis: 0;
	}

	.mailing-list-info {
		flex: 1;

		.etc {
			margin-top: 5px;
			font-size: 13px;
			margin-left: 10px;

			strong {
				font-weight: 500;
				color: #999;
			}

			i {
				color: $green;
				font-size: 1.2em;
				margin-left: 5px;
				margin-right: 3px;
			}
		}
	}

	.mailing-list-frequency {
		width: 50px;
	}

	.frequency-description {
		width: 100px;
		color: #bbb;
		font-size: 13px;
		font-weight: 500;
	}
}

#inbox-container #inbox {
	.section {
		h3 {
			margin: 20px -20px;
			background: #f3f3f3;
			padding: 0 10px;
			line-height: 40px;

			ul.sort {
				margin: -2px 0 0 0;

				li {
					i {
						color: #aaa;
					}

					&.active i {
						color: $green;
					}
				}
			}

			i {
				font-size: 1.3em;
			}
		}

		&.flagged h3 i {
			color: firebrick;
		}

		&.unread h3 i {
			color: $green;
		}

		&.unread h3 i {
			color: $green;
		}
	}
}

.inbox-stats, #inbox-container h2 .inbox-stats {
	list-style: none;
	float: right;
	margin: 0;
	padding: 0;

	li {
		margin: 0;
		padding: 0;
		display: inline-block;
		margin-right: 30px;
		color: #999;
		font-weight: 500;
		font-size: 15px;

		&:last-of-type {
			margin-right: 0;
		}

		span, strong {
			vertical-align: middle;
		}

		strong {
			display: inline-block;
			background: #fafafa;
			border-radius: 8px;
			min-width: 30px;
			padding: 8px 4px;
			text-align: center;
			margin-right: 2px;
			font-size: 20px;
			font-weight: 600;
		}

		&.unread, &.unread strong { color: $green; }
		&.flagged, &.flagged strong { color: firebrick; }
		&.other, &.other strong { color: #aaa; }
	}
}

.not-sendable {
	background: #f7e8e8;
	border-radius: 6px;
	text-align: left;
	padding: 10px;

	h5 {
		margin: 0;
		margin-bottom: 10px;
		padding: 0;
		font-size: 15px;
		font-weight: 500;

		i {
			color: #ca7d7d;
			margin-right: 10px;
		}
	}

	ul, li {
		margin: 0;
	}

	li {
		padding: 3px 0;
	}
}

.public-DraftEditor-content {
	height: 100px;
	overflow: scroll;

	&:focus {
		height: 250px;
	}
}

.thread {
	#header .page-icon i.fa-box-check {
		color: $green;
	}

	.attached-files {
		margin-top: 20px;
	}

	.snooze.pop-under-form {
		width: 250px;
		right: 43px;
		top: 45px;
	}

	.snooze-notice {
		font-weight: 500;
		font-size: 14px;
		padding: 10px 20px 10px 45px;
		border-bottom: 0;
		color: #e0cd22;
		background: #fffcd7;
		border: 1px solid #f5efa5;
		border-radius: 6px;

		.unsnooze {
			font-size: 13px;
			display: inline-block;
			padding: 2px 5px;
			font-weight: 400;
			border-radius: 5px;
			cursor: pointer;

			&:hover, &:active {
				background: #f5efa5;
			}
		}

		&:before {
			margin-top: -5px;
			content: "\F34E";
			margin-left: -35px;
			width: 25px;
			font-weight: 500;
			text-align: center;
			float: left;
			font-size: 15px;
			font-family: 'Font Awesome 5 Pro';
			line-height: 25px;
			border-radius: 50%;
			color: #e0cd22;
			background: #f5efa5;
		}
	}
}

.structure .info-panel {
	box-shadow: 0 0 20px #f2f2f2;
	min-width: 200px;
	max-width: 25%;
	border-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-left: 1px solid #f2f2f2;

	.button-icons {
		margin: 0 -10px 10px -10px;
	}

	.resolved-badge {
		display: inline-block;
		margin: 0;
		float: none;
		text-align: center;
		width: 150px;
		margin-bottom: 20px;
		border-radius: 6px;
		line-height: 23px;

		i {
			cursor: pointer;
			float: right;
		}
	}

	h3 {
		margin: 0 -10px 10px -10px;
		padding: 0 10px;
	}

	.thread-info {
		margin-bottom: 20px;
		font-size: 13px;
		background: #f6f6f6;
		margin: -10px -10px 10px -10px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		padding: 5px;

		h5 {
			font-weight: 500;
			font-size: 14px;
			margin-bottom: 5px;
			color: #666;
			padding: 8px 5px;
			margin: -5px -5px 8px -5px;
			background: white;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;

			i {
				color: $green;
				width: 35px;
				font-size: 18px;
				text-align: center;
			}
		}

		> div {
			color: #999;
			margin-bottom: 8px;
			padding-left: 35px;

			&:last-of-type {
				margin-bottom: 0;
			}

			strong {
				font-weight: 500;
			}

			i {
				color: $blue-etc;
				font-size: 16px;
				width: 30px;
				float: left;
				margin-left: -35px;
				text-align: center;
			}
		}
	}
}

.thread .structure {
	.snooze-notice {
		margin: 0 0 10px 0;
		color: #9e8f0b;
		font-weight: 400;

		strong {
			display: block;
			color: #777;
		}

		p {
			margin-left: -35px;
			margin-top: 5px;
		}
	}
}

.inline-attachments {
	.fa-paperclip {
		color: #e0cd22;
		float: left;
		font-size: 18px;
		margin-left: -35px;
		margin-top: 8px;
		font-weight: 500;
		margin-right: 5px;
	}

	a {
		color: #666;
		font-weight: 500;

		&:hover, &:active {
			color: $blue-etc;
		}
	}

	.comma-section .post-comma {
		&:after {
			color: #aaa;
			padding-right: 5px;
		}
	}

	.fa-download {
		margin: 0;
		margin-left: 5px;
		width: auto;
		padding: 0;
		color: #ccc;

		&:hover, &:active {
			color: $blue-etc;
		}
	}
}

.reply-header {
	color: #888;
	background: #fafafa;
	border: 1px solid #f2f2f2;
	border-left: 0;
	margin: 10px 10px 10px 0;
	padding: 3px 8px 3px 30px;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	font-size: 13px;

	.toggle {
		cursor: pointer;
		color: #ccc;
		float: left;
		margin-left: -30px;
		text-align: center;
		font-size: 20px;
		width: 30px;
		margin-top: 5px;

		&.fa-caret-down {
			color: $green;
		}
	}

	.avatar {
		width: 30px;
		height: 30px;
		margin-right: 10px;
		float: left;
	}

	.sender {
		line-height: 30px;
		font-weight: 600;
		color: #666;

		.fa-paperclip {
			margin-right: 5px;
			color: #e0cd22;
		}
	}

	.date {
		float: right;
		margin: 5px 5px 5px 10px;
		color: #888;
		font-weight: 500;
	}
/*
	.toggle {
		cursor: pointer;
		margin: 5px 10px 5px 10px;
		float: left;
		font-size: 2em;
		color: #ccc;
		width: 15px;
		text-align: center;
		font-family: 'Font Awesome 5 Pro';

		&.collapse-reply:before {
			content: "\f0d7";
		}

		&.expand-reply:before {
			content: "\f0da";
		}
	}
*/
	.icon {
		display: inline-block;
		padding: 5px;
		text-align: center;
		width: 20px;
		height: 20px;
		font-size: 0.8em;
		margin-right: 10px;
		background: #f2f2f2;
		color: $blue-etc;
		border-radius: 20px;
		display: none;
	}

	strong {
		font-weight: 600;
	}
}

.email-reply {
	margin: 0 -10px;

	.email-body {
		letter-spacing: -0.08px;
		margin: 0px 10px 20px 20px;
		white-space: pre-wrap;
		font-size: 14px;
	}

	.snippet {
		font-size: 13px;
		margin-top: 1px;
		font-weight: 400;
		overflow: hidden;
		text-overflow: ellipsis;
		height: 16px;
	}

	&.collapsed {
		cursor: pointer;

		.snippet {
			display: block;
		}
		.reply-header {
			color: #999;
			border-bottom: 0;
			border-radius: 0;

			strong {
				font-weight: 500;
			}

			a {
				color: #999;
			}
		}
	}
}

.email-reply.collapsed .reply-header {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-bottom: 1px solid #f2f2f2;
}

.email-reply.preceding-collapsed {
	margin-bottom: -10px;

	.reply-header {
		border-bottom-right-radius: 0;
		border-bottom: 0;
	}
}

.email-reply:not(.preceding-collapsed) {
	.reply-header {
		border-bottom: 1px solid #f2f2f2;
	}
}

.email-reply.collapsed + .email-reply.collapsed {
	.reply-header {
		border-top-right-radius: 0;
	}
}

.attached-files {
	margin-top: 20px;
}

.mailing-list-threads {
	.email-table .email-row {
		&:hover, &:active {
			.email-controls {
				display: none;
			}
		}
	}
}

.recategorize-mailing-list-container {
	float: right;
	position: relative;

	.pop-under-form {
		width: 300px;
		right: 0;
		top: 35px;

		.centered {
			background: #fafafa;
			border-top: 1px solid #f2f2f2;
			margin: 10px -10px -10px -10px;
			border-bottom-right-radius: 6px;
			border-bottom-left-radius: 6px;
			padding: 0;

			i {
				color: #e0cd22;
				font-size: 30px;
				line-height: 36px;
				margin: 10px;
			}
		}
	}
}

.recategorize-mailing-list {
	padding: 5px 10px;
	color: #ceba0c;
	/*background: #fffac9;*/
	display: inline-block;
	font-weight: 500;
	border-radius: 6px;
	line-height: 18px;
	cursor: pointer;

	&:hover, &:active, &.active {
		background: #f7f2bf;
		background: #fffac9;
	}

	&:before {
		display: none;
		margin-right: 10px;
		font-size: 18px;
		color: #e0cd22;
		content: "\f00d";
		font-family: 'Font Awesome 5 Pro';
	}
}

.submit-options {
	display: table;
	background: #f2f2f2;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	width: 100%;

	.set-reminder {
		width: 50%;
		padding-left: 10px;
		display: table-cell;

		.toggle-reminder {
			color: $blue-etc;
			font-weight: 500;
			border-radius: 6px;
			cursor: pointer;
			padding: 7px 12px;

			&:hover, &:active {
				background: #fafafa;
			}

			i {
				color: $gold;
				margin-right: 10px;
			}
		}
	}

	.submit-button {
		text-align: right;
		width: 50%;
		display: table-cell;
	}
}

.reminder-options {
	.styled-select {
		display: inline-block;
		width: 150px;
	}

	i {
		margin-right: 5px;
		color: #bbb;
	}

	div:first-child {
		margin-bottom: 5px;
	}

	input[type="text"] {
		width: 350px;
	}

	span {
		margin: 0 5px;
		font-size: 13px;
		font-weight: 500;
		color: #888;
	}
}

.add-thread-message {
	margin-top: 20px;

	&.inactive {
		.message-type-tabs {
			> div {
				color: #666;
				background: #fafafa;
				border-radius: 6px;
				border: 1px solid #f2f2f2;

				i {
					color: $green;
				}
			}
		}
	}

	.message-type-tabs {
		display: table;
		width: 100%;
		table-layout: fixed;
		border-collapse: separate;
		border-spacing: 10px;
		margin-bottom: -10px;

		> div {
			padding: 8px 10px;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
			display: table-cell;
			color: #999;
			cursor: pointer;
			border-color: transparent;

			&:hover {
				background: #fafafa;
			}

			&:last-of-type {
				text-align: right;
			}

			i {
				color: #ccc;
			}

			&.draft-reply {
				i { margin-right: 10px; }
			}

			&.draft-forward {
				i { margin-left: 10px; }
			}

			&.active {
				font-weight: 500;
				background: #f2f2f2;
				letter-spacing: -0.2px;
				font-size: 16px;
				color: #666;

				i {
					color: $green;
					font-size: 22px;
					font-weight: 900;
				}
			}
		}
	}

	form {
		background: #fafafa;
		border-radius: 6px;
		border: 1px solid #f2f2f2;

		.include-replies-option {
			label {
				font-size: 14px;
				margin-bottom: 0;
			}

			.toggler {
				float: right;
			}
		}

		.cc {
			i {
				display: none;
			}
		}
	}
}

.mail-category {
	background: #f2f2f2;
	padding: 8px 5px 0 5px;
	display: none;

	ul {
		text-align: center;
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			padding: 0;
			margin: 0 5px;
			display: inline-block;

			span {
				color: #999;
				/*border: 1px solid transparent;
				border-bottom: 0;*/
				padding: 8px 15px 12px 15px;
				display: inline-block;
				font-weight: 500;
				font-size: 13px;
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;

				&:hover, &:active {
					background: #f6f6f6;
				}

				&.active {
					color: $blue-etc;
					font-weight: 600;
					/*border: 1px solid #ddd;
					border-bottom: 0;*/
					background: #f6f6f6;
				}
			}
		}
	}
}