.contact-card {
	.definitive {
		display: none;
		cursor: pointer;

		&.active {
			background: #d0ffd1;
			color: forestgreen;
			display: inline-block;

			i {
				color: forestgreen;
			}
		}
	}

	&:hover {
		.definitive {
			display: inline-block;
		}
	}
}

.recent-contacts {
	display: table;
	padding: 0;
	width: 100%;
	table-layout: fixed;
	margin-bottom: 10px;
	font-weight: 500;

	a {
		color: $blue-etc;
	}

/*	.contact {
		display: table-cell;
		padding-bottom: 0;
		width: auto;
		text-align: center;

		&:hover, &:active {
			font-weight: 600;

			img {
				box-shadow: 0 0 5px 2px #e0ecff;
			}
		}

		img {
			width: 80px;
			border: 1px solid $blue-etc;
			padding: 3px;
			box-shadow: 0 0 5px 2px #efefef;
			border-radius: 100px;
			margin-bottom: 8px;
		}
	}*/
}

.identifier {
	.overview-stats {
		table-layout: fixed;
		display: table;
		text-align: center;
		margin: 10px -20px 0 -20px;
		width: calc(100% + 40px);
		padding: 5px 0;
		font-weight: 400;
		font-size: 13px;
		color: $blue-etc;

		.amount {
			margin-bottom: 5px;
			font-weight: 500;
			font-size: 18px;
		}
	}
}

.time-zone {
	text-align: left;
	font-size: 14px;
	font-weight: 600;
	color: #999;;
	background: #f6f6f6;
	padding: 8px 8px 8px 35px;

	.etc.time-zone-name {
		color: #ccc;
	}

	&.open {
		color: $green;
	}

	&.closed {
		color: #aaa;

		i {
			color: #ddd;
		}
	}

	.indicator {
		font-size: 1.2em;
		margin-left: -25px;
		float: left;
	}

	.etc {
		font-weight: 500;
		color: #aaa;
	}
}

.addresses, .numbers, .email-addresses, .websites, .contact-social-profiles, .domain {
	font-size: 13px;

	.contact-social-profile {
		background: none;
		color: #444;

		&:before {
			font-family: 'Font Awesome 5 Brands';
		}

		&.twitter:before {
			content: "\f099";
			color: #1DA1F2;
		}

		&.facebook:before {
			content: "\f09a";
			color: #3B5998;
		}

		&.linkedin:before {
			content: "\f08c";
			color: #0077B5;
		}

		&.angellist:before {
			content: "\f209";
		}

		&.github:before {
			content: "\f09b";
		}
	}
}

.address, .phone-number, .email-address, .website, .contact-social-profile, .employment, .domain {
	padding: 8px 8px 8px 35px;
	display: block;
	color: #777;
	font-weight: 500;

	&:before {
		display: block;
		font-size: 1.2em;
		float: left;
		margin-left: -35px;
		/*background: #f2f2f2;*/
		border-radius: 5px;
		width: 30px;
		font-weight: 300;
		text-align: center;
		font-family: 'Font Awesome 5 Pro';
		color: $blue-etc;
	}

	&.participants:before {
		content: "\f0c0";
	}

	&.time-range:before {
		content: "\f017";
	}
}

.address:before {
	content: "\f041";
}

.employment {
	color: #444;
	font-size: 14px;
	padding: 10px 18px 10px 45px;
	font-weight: 500;
	background: #f2f2f2;

	&:before {
		font-weight: 900;
		font-size: 20px;
		content: "\f0b1";
	}
}

.phone-number:before {
	content: "\f095"
}

.email-address:before {
	content: "\f0e0";
}

.website:before, .domain:before {
	content: "\f0c1";
}

.website {
	font-weight: 500;

	&:last-of-type {
		border-bottom: 0;
	}

	.etc {
		font-weight: 400;
	}
}

#header .avatar {
	margin-right: 10px;
	height: 33px;
	width: 33px;
}

.avatar {
	width: 60px;
	height: 60px;
	/*border: 2px solid $blue-etc;
	box-shadow: 0 0 3px #ccc;
	background: aliceblue;*/
	box-shadow: 0 0 10px #ccc;
	border: none;
	border-radius: 100px;
	overflow: hidden;
	margin: auto;
	text-align: center;
	z-index: 11000000;
	/*background: #e4f1df;*/

	/*

	margin: auto;
	position: relative;
	top: -40px;
	left: calc(50% - 50px);
	position: absolute;

	*/

	i {
		color: $green;
	}

	.initials {
		display: block;
		font-size: 22px;
		padding: 0;
		font-weight: 500;
		width: 100%;
		margin: 22px 0 0 0;
		text-align: center;
		background: none;
		border: none;
		margin-top: 10px;
		box-shadow: none;
		color: $green;
	}

	img {
		width: 100%;
		height: 100%;
		border: 0;
		padding: 0;
		margin: 0;
		border-radius: 50%;
	}

	.edit-photo {
		color: #e0cd22;
		-webkit-text-stroke: 1px white;
		position: absolute;
		top: 50px;
		left: 38px;
		font-size: 2em;
	}
}

.heading-avatar .avatar {
	position: absolute;
	left: 10px;
	top: 10px;
}

.avatar {
	position: relative;
	display: inline-block;
}

.contact-card .avatar {
	float: left;
	margin-right: 10px;
	box-shadow: 0 0 8px #e6e6e6;
	border-radius: 60px;
	height: 36px;
	width: 36px;
	background: aliceblue;
	border: 1px solid $blue-etc;
}

i.generic-avatar {
	font-size: 60px;
	position: absolute;
	top: 0;
	left: 0;
}

.initials-overlay {
	color: $green;
	position: absolute;
	font-size: 16px;
	font-weight: 700;
	top: 52px;
	left: 0;
	text-align: center;
	width: 100%;
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'helvetica neue', 'helvetica', 'sans-serif';
}


.contact-view .main-photo .initials-overlay {
	top: 55px;
}

.main-photo {
	/*position: absolute;
	left: calc(50% - 50px);
	top: -55px;*/
	position: absolute;
	top: 10px;
	left: 20px;

	.user-avatar {
		margin: auto;
	}

	.avatar {
		width: 80px;
		height: 80px;

		.generic-avatar {
			font-size: 80px;
		}
	}
}

.inline.avatar {
	height: 40px;
	width: 40px;
	display: inline-block;
	text-align: center;
	/*box-shadow: 0 0 1px 1px $green;
	border-color: $green;*/
	box-shadow: 0 0 5px #ddd;
	background: #fffcd7;
	border: none;

	.initials-overlay {
		font-size: 18px;
		text-align: center;
		top: 32px;
		font-weight: 600;
	}
}

.collaborator {
	margin: 0 5px;

	strong {
		display: block;
		margin-top: 5px;
		color: $green;
	}
}

.top-collaborators {
	display: table;
	table-layout: fixed;
	width: 100%;
	box-sizing: border-box;

	> span {
		display: table-cell;
		text-align: center;
	}

	a {
		display: block;
		padding: 5px;

		&:hover, &:active {
			background: #fefefe;
			border-radius: 6px;
		}
	}

	.name {
		overflow: hidden;
		text-overflow: ellipsis;
		color: #999;
		font-size: 13px;
		hyphens: auto;
	}
}

.iconed-section {
	margin: 20px;
	border-bottom: 1px solid #f2f2f2;
	padding-bottom: 20px;

	&.empty {
		.section-icon i {
			font-size: 1.8em;
			color: #e6e6e6;
		}

		.section-title {
			color: #aaa;
		}
	}

	&.small {
		padding-bottom: 10px;
		margin: 10px;

		.section-body {
			margin-left: 40px;
		}

		.section-icon {
			width: 30px;

			i {
				font-size: 1.7em;
			}
		}
	}

	.section-body {
		margin-left: 70px;
	}

	&:last-of-type {
		border-bottom: 0;
	}

	.section-title {
		font-weight: 500;
		font-size: 14px;
		color: $blue-etc;
		line-height: 24px;

		&:before {
			content: "\f111";
			font-family: 'Font Awesome 5 Pro';
			font-size: 1em;
			color: #e0ecff;
			display: none;
		}

		.action-icon {
			float: right;
			line-height: 24px;
			padding: 0 10px;
			border-radius: 6px;
			background: #f9f9f9;
			cursor: pointer;
			color: $green;
			text-align: center;

			&.active {
				background: #efefef;

				&:before {
					content: "\f068";
				}
			}

			&:before {
				content: "\f067";
				font-family: 'Font Awesome 5 Pro';
			}

			&.edit:before {
				content: "\f040";
			}

			span {
				color: #aaa;
				font-weight: 500;
				margin-left: 10px;
			}
		}
	}

	.section-icon {
		float: left;
		width: 50px;
		text-align: center;

		i {
			font-size: 3em;
			color: $blue-etc;
		}
	}
}

.interaction-overview {
	display: flex;
	width: 100%;
	justify-content: space-between;

	&.uncontacted .contact-type {
		margin: auto 15px;
	}
}

.no-contact-explanation {
/*	background: #f5f9ff;
	border-bottom: 1px solid #e0ecff;
	border-top: 1px solid #e0ecff;
	background: #fafafa;
	border-color: #f2f2f2;
*/
	color: #86a8d6;
	color: #555;
	margin: 15px 20px;

/*
	&:before {
		font-family: 'Font Awesome 5 Pro';
		content: "\f111";
		color: #d0dff9;
		font-size: 25px;
		float: left;
		text-align: center;
		font-weight: 300;
		margin-top: 2px;
		margin-left: -35px;
	}
*/
	.other-interactors {
		color: #999;

		strong {
			font-weight: 500;
		}

		&:before {
			font-family: 'Font Awesome 5 Pro';
			content: "\f0c0";
			color: #ccc;
			color: $gold;
			font-size: 18px;
			width: 30px;
			margin: 0 10px;
			font-weight: 900;
		}
	}

	h3 {
		margin: 0;
		font-size: 14px;
		font-weight: 500;
		color: $blue-etc;
	}
}

.contact-timeline-container {
	background: #fcfcfc;
	background: #f5fbf3;
	background: linear-gradient(to bottom, white, aliceblue);
	position: relative;
	text-align: center;
	border-top: 1px solid #f2f2f2;
	box-shadow: inset 0 12px 12px -8px #ecf4ff, inset 0 -12px 12px -8px #ecf4ff;

	.contact-timeline {
		opacity: 0.6;
	}

	.name-etc {
		height: 100%;
		display: flex;
		/*align-items: center;*/
		flex-direction: column;
		text-align: left;
		justify-content: center;
	}

	&.no-user-interactions {
		background: white;

		.overlay {
			position: initial;
			display: block;
			padding: 0 10px 10px 100px;
		}

		.button-icons {
			margin-top: -20px;
		}

		.contact-type {
			position: initial;
			margin-left: 100px;
		}

		.contact-timeline {
			display: none;
		}

		.main-photo {
			.avatar {
				height: 60px;
				width: 60px;

				.generic-avatar {
					font-size: 60px;
				}
			}

			.initials-overlay {
				top: 38px;
				font-size: 14px;
			}
		}
	}

	&.with-kicker .name-etc {
		margin: 10px 0;
		height: calc(100% - 10px);
	}

	.button-icons {
		margin-right: 0;
		background: #f3f3f380;
		border: none;
		margin-top: 0;
	}

	.overlay {
		position: absolute;
		width: 100%;
		z-index: 100;
		box-sizing: border-box;
		padding: 10px;
		display: flex;
		bottom: 0;
		align-items: end;

		.value {
			color: $blue-etc;
			font-weight: 500;
			font-size: 15px;
		}

		.spacer {
			flex: 1;
		}

		.stat {
			padding: 5px 10px;
			flex-shrink: 0;
			text-align: left;
			position: relative;

			&:before {
				content: " ";
				width: 100%;
				position: absolute;
				height: 100%;
				z-index: -1;
				left: 0;
				top: 0;
				border-radius: 6px;
				opacity: 0.7;
				background: white;
			}

			&:last-of-type {
				text-align: right;
			}

			.label {
				font-size: 13px;
				color: #bbb;
				font-weight: 500;
				margin: 0;
				padding: 0;

				i {
					font-size: 1.2em;
					margin-right: 5px;
					color: #ddd;
				}
			}
		}
	}

	.avatar {
		margin-left: 0px;
		box-shadow: 0 0 10px #e0cd22;
		border: 0;
	}

	h1 {
		color: $green;
		font-weight: 600;
		display: inline-block;
		font-size: 22px;
		margin: 0 0 3px 0;
		letter-spacing: -.5px;
		line-height: 30px;

		.etc {
			letter-spacing: 0;
			font-weight: 500;
		}
	}

	.titles {
		position: absolute;
		padding-top: 60px;
		z-index: 1;
		left: 20px;
		right: 20px;
	}

	.date-label {
		background: rgba(255, 255, 255, 0.2);
		float: left;
		text-align: left;
		font-weight: 400;
		margin-top: -10px;
		color: $blue-etc;
		color: #aaa;
		min-width: 180px;

		&.right-aligned {
			text-align: right;
			float: right;
		}
	}

	.floated-title {
		font-weight: 600;
		font-size: 18px;
		background: rgba(224, 236, 255, .6);
		background: rgba(197, 234, 184, .4);
		background: rgba(255, 255, 255, 0.65);
		padding: 6px 20px;
		color: $blue-etc;
		color: $green;
		display: inline-block;
		border-radius: 30px
	}
}

.contact-table .contact-timeline {
	height: 100%;
	/*height: 60px;*/
}

.contact-timeline {
	width: 100%;
	position: relative;
	height: 70px;
	top: 0;

	svg {
		/*border-bottom: 1px solid rgb(245, 239, 165);*/
	}
}

.contact-table .contact-timeline-container {
	position: absolute;
	z-index: 0;
	height: 100%;
	width: 100%;
	margin: 10px;

	.titles, .floated-title {
		display: none;
	}
}

.chart-supplement {
	background: #f5f9ff;
	margin-top: -5px;
	color: $blue-etc;
	padding: 5px 10px;
	border-bottom: 1px solid #e0ecff;
}

.contact-table .block  {
	display: block;
	position: relative;
	border-top: 0;

/*	.container {
		position: relative;
		z-index: 100;
	}
*/
}

.contact-lists .contact-table .block {
//	overflow: hidden;
	border-radius: 10px;
	border: 1px solid #f2f2f2;
	background: white;

	&:hover, &:active {
		box-shadow: 0 0 5px #f2f2f2;
	}
}

.contact-lists {
	.contact-table {
		margin: 20px;

		.block {
			margin: 5px;
		}
	}
}

.contact-table {
	margin: -20px;

	.contact-timeline {
		/*padding: 0 10px;
		box-sizing: border-box;*/
		position: absolute;

		svg {
			border-bottom: 0;
			opacity: 0.75;
		}
	}

	.block {
		position: relative;
		box-sizing: border-box;
		min-height: 70px;
		padding: 0;

		&:hover, &:active, &.active {
			background: #fcfdff;

			.contact-controls {
				display: block
			}
		}

		&:last-of-type {
			border-bottom: 1px solid #f6f6f6;
		}

		strong {
			color: #888;
		}

		h4 {
			color: $blue-etc;
			margin: 0 0 3px 0;
			font-size: 15px;
			font-weight: 600;
		}

		.container {
			display: table;
			position: relative;
			box-sizing: border-box;
			min-height: 70px;
			top: 0;
			border-radius: 6px;
			width: 100%;
		}

		.etc {
			font-size: 13px;
		}

		.avatar-container, .main-cell, .stats {
			box-sizing: border-box;
			display: table-cell;
			vertical-align: middle;
			&.avatar-container {
				width: 70px;
				text-align: center;
			}

			&.main-cell {
				width: calc(100% - 70px - 150px);

				.employment-info {
					color: #aaa;
				}
			}

			&.stats {
				width: 150px;
			}
		}

		.avatar {
			height: 50px;
			width: 50px;
			line-height: 50px;

			i {
				font-size: 50px;
			}

			.initials-overlay {
				font-size: 10px;
				top: 13px;
			}
		}

/*
		&:hover, &:active {
			.avatar-container, .name, .stats, .action-icons {
				background: #f2f2f2;
			}

			.avatar-container {
				border-top-left-radius: 6px;
				border-bottom-left-radius: 6px;
			}

			.action-icons {
				border-top-right-radius: 6px;
				border-bottom-right-radius: 6px;
			}
		}
*/
		.stats {
			margin: 0;

			.stat {
				display: inline-block;
				width: 50px;
				text-align: center;
				font-weight: 400;
				font-size: 15px;
				color: #777;

				i {
					font-size: 1.3em;
					display: block;
					font-size: 18px;
					margin-bottom: 10px;
					color: $green;
				}
			}
		}

		.ownership-indicator {
			font-size: 2em;
			text-align: center;
			width: 30px;
			margin-right: 10px;
			color: #ddd;

			.fa-rss {
				color: $green;
			}
		}

		.avatar {
			margin: 0;
		}
	}
}

.new-contact-email-address {
	display: flex;
	justify-content: center;
	border-radius: 10px;

	input {
		font-size: 16px;
		font-weight: 500;
		width: 80%;
	}

	label {
		color: $blue-etc;
		font-weight: 500;
		font-size: 16px;

		i {
			font-size: 1.4em;
		}
	}

	.go {
		width: 15%;
		padding-top: 10px;
		text-align: center;

		i {
			cursor: pointer;
			font-size: 3em;
			background: #f2f2f2;
			border-radius: 50px;
			padding: 10px;
			width: 50px;
			height: 50px;
			text-align: center;
			line-height: 50px;
			color: $green;

			&.fa-circle-thin {
				color: #ccc;
			}
		}
	}

	div:first-of-type {
		flex: 1;
		width: 85%;
	}
}

/*.bio {
	background: #e0ecff7d;
	color: #99b1d3;
	font-weight: 500;

	&:before {
		width: 20px;
		text-align: center;
		content: "\f2ba";
		margin-left: -30px;
		float: left;
		color: #bed2ef;
		font-size: 1.3em;
		font-family: 'Font Awesome 5 Pro';
	}
}*/

.contact-lists {
	ul.section {
		display: inline-block;
		margin: 0;
		box-shadow: 0 0 10px #f2f2f2;
	}
}

.contact-section {
	overflow: hidden;
	margin-left: -20px;
	position: relative;

	.content {
		z-index: 100;
		position: relative;
		clear: none;
		margin-left: 40px;
	}

	&.bio {
		padding: 10px;
		font-weight: 500;
		font-size: 14px;
		white-space: pre-line;

		&:before {
			content: '\f2b9';
		}
	}

	&.contact-info {
		&:before {
			content: '\f2c2';
		}
	}

	&:before {
		z-index: 0;
		content: '\f2ba';
		left: 0px;
		padding: 10px;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		background: #f6f6f6;
		position: absolute;
		color: $blue-etc;
		float: left;
		width: 20px;
		text-align: center;
		font-size: 1.4em;
		font-family: 'Font Awesome 5 Pro';
	}
}

.main-content.contact-snapshot {
	width: 100%;
	box-sizing: border-box;
	padding-top: 0;
	display: table;

	.primary {
		display: table-cell;
		width: calc(100% - 300px);
	}

	.contact-feed {
		display: table-cell;
		width: 300px;
	}
}

.enriched-indicator {
	color: goldenrod;
	display: inline-block;
	margin-left: 10px;
}

.sub-bar .date-label {
	background: none;
}

.contact-ownership {
	background: #f2f2f2;
	padding: 10px;
	display: flex;
	align-items: center;
	position: relative;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom: 0;

	.ownership-indicator {
		vertical-align: middle;
		text-align: center;
		width: 60px;
		padding-right: 10px;

		&.global {
			color: $green;
		}

		&.personal {
			color: $gold;
		}

		i {
			font-size: 40px;
		}
	}

	.overview {
		flex: 1;

		strong {
			font-size: 16px;
			display: block;
			margin-bottom: 5px;
		}
	}

	/*.mailbox-switcher-container {
		flex: 1;
		position: relative;
	}*/

	.mailbox-switcher {
		box-shadow: 0 0 10px #f2f2f2;
		position: absolute;
		z-index: 100;
		background: white;
		border-radius: 5px;
		border: 1px solid #f6f6f6;
		right: 5px;
		top: 5px;
		display: flex;
		flex-direction: column;
		cursor: pointer;
		overflow: hidden;

		&.show-all .mailbox, .mailbox.active {
			display: flex;
		}

		.mailbox {
			padding: 5px 10px;
			display: none;
			align-items: center;
			border-bottom: 1px solid #f6f6f6;

			&:hover, &:active {
				background: #f5f9ff;
				/*border-color: #e0ecff;*/
			}
/*
			&:first-of-type {
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
			}

			&:last-of-type {
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
			}
*/
			&.active {
	/*			border-bottom: 0;*/
				order: -1;
			}

			> div {
				flex: 1;
			}

			.fa-caret-down {
				width: 30px;
				flex-grow: 0;
				margin: 0 0 0 20px;
				font-size: 30px;
				text-align: center;
				color: $gold;
			}

			&:not(.active) .fa-caret-down {
				display: none;
			}
		}
	}
}

.contact-stats {
	display: table;
	table-layout: fixed;
	box-sizing: border-box;
	color: $green;
	font-weight: 600;
	font-size: 15px;
	padding: 5px 10px;
	width: calc(100% + 40px);
	margin: -20px -20px 20px -20px;
	box-sizing: border-box;
	background: white;
	background: linear-gradient(to bottom, #fafafa, #fcfcfc);
	border-bottom: 1px solid #f2f2f2;
	border-top: 1px solid #f2f2f2;

	.stat-label {
		font-size: 13px;
		color: #bbb;
		font-weight: 500;
		margin-bottom: 3px;

		i {
			font-size: 1.2em;
			margin-right: 5px;
			color: #ddd;
		}
	}


	> div, > a {
		text-align: center;
		padding: 5px;
		display: table-cell;


		&:first-child {
			text-align: left;
		}

		&:last-child {
			text-align: right;
		}
	}

	> a {
		color: $green;

		&:hover, &:active, &.active {
			background: #f6f6f6;
			border-radius: 10px;
			padding: 5px;
		}
	}
}

.file .record-tasks {
	margin-top: -10px;
	margin-bottom: -10px;

	.tasks-with-indicators {
		margin-top: -10px;
	}

	h2 {
		padding: 0 10px;
	}
}

#contact, #organization {
	.panel-info {
		background: #fafafa;
		padding: 10px 20px;
		margin: 0 -20px;

		.new-task {
			top: 4px;
			right: 165px;
		}

		h3 {
			margin: 0;
		}
	}

	.recent-threads, .priority-contacts, .recent-files, .recent-contacts {
		margin: 10px -10px;

		h3.with-icon-before {
			margin-bottom: 5px;
		}
	}

	.recent-files .file-table {
		margin: 0 -10px;
	}

	.recent-threads {
		.email-table {
			margin: 0 -10px;
		}

		h3.with-icon-before:before {
			background: #fafafa;
			content: "\f0e0";
		}
	}

	.recent-files {
		.recent-file {
			float: left;
			text-align: center;
			width: calc(20% - 10px);
			box-sizing: border-box;
			margin: 5px;
			border-radius: 5px;
			padding: 5px;
			border: 1px solid transparent;

			&:hover, &:active {

				background: #f5f9ff;
				border-color: #e9f2ff;
			}

			.file-icon i {
				font-size: 32px;
				margin-bottom: 10px;
			}

			.file-name {
				overflow: hidden;
				text-overflow: ellipsis;
				font-weight: 500;
			}
		}

		h3.with-icon-before:before {
			background: #fafafa;
			content: "\f15c";
		}
	}

	h3.with-icon-before {
		margin-left: -10px;
	}

	.tasks.panel {
		.panel-info {
			position: relative;

			.button {
				float: right;
			}
		}
	}

	.workspace.panel {
		display: table;
		width: calc(100% + 40px);
		margin: 0 -20px -20px -20px;

		.records {
			display: table-cell;
			width: calc(100% - 250px);
		}

		.contact-snapshot-card {
			border-left: 1px solid #f2f2f2;
			box-shadow: -2px 0 10px -2px #f2f2f2;
			padding: 10px;
			width: 250px;
			display: table-cell;

			h3 {
				background: none;
				background: linear-gradient(to bottom, #f5f9ff, white);
				background: #f6f6f6;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				margin: -10px -10px 10px -10px;
				padding: 10px 10px 10px 50px;
				font-weight: 500;
				font-size: 14px;

				&:before {
					font-family: 'Font Awesome 5 Pro';
					content: '\f05a';
					box-shadow: 0 0 10px #dfdfdf;
					color: #8cbf7e;
					background: #fafafa;
					border: 1px solid #efefef;
					font-size: 26px;
					line-height: 32px;
					margin-left: -42px;
					width: 32px;
					text-align: center;
					float: left;
					margin-top: -7px;
					border-radius: 50%;
				}
			}

			.bio {
				color: #999;
				margin-bottom: 10px;
				font-size: 13px;
			}

			h4 {
				margin: 0 -10px;
				padding: 10px;
				background: #f9f9f9;
				font-size: 14px;
				font-weight: 500;
				color: $green;

				i {
					font-size: 1.2em;
					margin-right: 10px;
					color: $green;
				}
			}
		}
	}

	&#organization .panel .panel-filters {
		background: #f2f2f2;
	}

	.panel {
		width: auto;

		&.files {
			.panel-filters {
				.styled-select {
					display: inline-block;
					margin: 0 5px;
				}

				.filter-buttons {
					margin-top: 0;
				}

				input[type="text"] {
					width: 200px;
				}
			}
		}

		.panel-filters {
			margin: -20px -20px 20px -20px;
			padding: 5px;
			background: #fafafa;
			border-bottom: 1px solid #f2f2f2;
			font-size: 13px;
			font-weight: 500;
			color: #777;
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.spacer {
				min-width: 0;
				flex: 1;
			}

			.extra-options, .main-search {
				min-width: 300px;
				flex-grow: 1;
			}

			p {
				color: $green;
				display: inline;
				margin: 0;
			}

			.filter-buttons {
				text-align: right;
				flex: 1;

				.search-input {
					display: none;
				}

				.search-input-container {
					position: relative;
					display: inline-block;
					float: right;

					&.processing {
						&:after {
							position: absolute;
							content: '\f110';
							height: auto;
							line-height: initial;
							top: 8px;
							font-weight: 700;
							color: #e0cd22;
							right: 10px;
							font-size: 16px;
							-webkit-animation: fa-spin 2s infinite linear;
							animation: fa-spin 2s infinite linear;
							font-family: 'Font Awesome 5 Pro';
						}
					}
				}

				&.show-search {
					.search-input {
						display: block;
						float: right;
						width: 400px;
					}

					.flagged, .unread, .unresolved, .with-attachments {
						display: none;
					}
				}

				> div:not(.styled-select) {
					position: relative;
					display: inline-block;
					margin-right: 10px;
					padding: 4px 12px 4px 30px;
					background: #f2f2f2;
					border: 1px solid #f2f2f2;
					border-radius: 6px;
					cursor: pointer;
					margin-top: 3px;
					font-size: 13px;
					font-weight: 500;

					&:last-of-type {
						margin-right: 0;
					}

					&.on:not(.processing) {
						background: white;

						&:before {
							font-weight: 700;
						}
					}

					&:before {
						position: absolute;
						width: 30px;
						text-align: center;
						font-family: 'Font Awesome 5 Pro';
						left: 0;
						color: #bbb;
						display: block;
					}

					&.processing {
						&:before {
							content: '\f110';
							height: auto;
							line-height: initial;
							top: 6px;
							font-weight: 700;
							color: #e0cd22;
							-webkit-animation: fa-spin 2s infinite linear;
							animation: fa-spin 2s infinite linear;

						}
					}

					&:not(.processing) {
						&.search-toggle {
							padding: 4px 12px;
							color: #bbb;

							&.on {
								color: #86a8d6;
								background: #f5f9ff;
								border-color: #e0ecff;
							}
						}

						&.flagged {
							&:before {
								content: '\f024';
							}

							&.on, &.on:before {
								color: firebrick;
							}
						}

						&.unread {
							&:before {
								content: '\f0e0';
							}

							&.on, &.on:before {
								color: $green;
							}
						}

						&.with-attachments {
							&:before {
								font-weight: 300;
								content: '\f0c6';
							}

							&.on, &.on:before {
								color: $blue-etc;
							}
						}

						&.unresolved {
							&:before {
								content: '\f39b';
								font-weight: 300;
							}

							&.on, &.on:before {
								color: #e0cd22;
							}

							&.on:before {
								font-weight: 700;
							}
						}
					}
				}
			}

			i.intro-icon {
				margin: 0 10px;
				font-size: 16px;
				color: $green;
			}
		}
	}

	h2.history {
		background: #f6f6f6;
		margin: -20px -20px 0 -20px;
		padding: 8px;
		font-size: 16px;
		font-weight: 500;

		i {
			float: left;
			box-shadow: 0 0 10px #efefef;
			color: #8cbf7e;
			margin-right: 15px;
			background: white;
			border: 1px solid #efefef;
			padding: 10px;
			font-size: 35px;
			line-height: 40px;
			width: 40px;
			text-align: center;
			margin-left: 10px;
			margin-top: -18px;
			border-radius: 50%;
		}

		.sort {
			margin-top: 1px;

			&.active {
				/*border-bottom-color: #fafafa;*/
				background: linear-gradient(to bottom, #f7fff4, #fafafa);
			}

			li {
				&:first-of-type span {
					border-radius: 0;
				}

				&:last-of-type span {
					border-radius: 0;
				}

				span {
					color: #bbb;
					padding: 12px 14px;
					font-size: 14px;
					border-bottom: 1px solid #f6f6f6;
					border-top: 0;
					background: #f6f6f6;
				}
			}
		}
	}
}

.rolodex {
	display: none;
	width: 500px;
	box-sizing: border-box;
	margin-left: calc(50% - 250px);
	border: 1px solid #f2f2f2;
	border-radius: 6px;
	padding: 10px;

	h3 {
		margin: -10px -10px 10px -10px;
		padding: 10px;
		background: linear-gradient(to bottom, #f5f9ff, white);
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		color: $blue-etc;
		font-weight: 600;

		i {
			color: $blue-etc;
		}
	}
}

.contact-navigation {
	text-align: center;
	margin: 10px 0 0 0;
	background: #f2f2f2;
	background: white;
	padding-top: 5px;
	display: none;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		border-bottom: 1px solid #f2f2f2;

		li {
			display: inline-block;
			margin: 0 10px -1px 10px;
			padding: 0;

			&.active {
				span, span:hover, span:active {
					color: $blue-etc;
					background: white;
					border-bottom-color: white;
					/*background: linear-gradient(to bottom, #f5f9ff, #fafafa);
					border-bottom-color: #fafafa;*/
					font-weight: 600;
				}

				i {
					font-weight: 700;
					color: $blue-etc;
				}
			}

			span {
				border: 1px solid #f2f2f2;
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
				background: #fafafa;
				cursor: pointer;
				display: block;
				font-weight: 500;
				font-size: 14px;
				color: #aaa;
				padding: 6px 18px;

				&:hover, &:active {
					background: white;
				}

				i {
					font-size: 16px;
					color: #ccc;
					margin-right: 10px;
				}
			}
		}
	}
}

.upload-avatar {
	float: left;
	margin-left: 10px;
	width: calc(25%);
	text-align: center;
	font-size: 13px;
	background: #f2f2f2;
	border-radius: 6px;

	i {
		margin-top: 0;
		font-size: 60px;
		line-height: 60px;
		color: $green;
	}

	i.fa-spinner {
    font-size: 40px;
    color: #ccc;
	}

	.instructions-label {
		padding: 5px;
		font-size: 12px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		margin-top: 10px;
		cursor: pointer;

		&:hover, &:active {
			background: #efefef;
		}
	}

	&.active {
		background: #e5f4df;

		.instructions-label {
			cursor: pointer;
			background: #dbf1d3;
		}
	}

	.default-photo {
		padding-top: 10px;

		img {
			margin: auto;
			height: 60px;
			width: 60px;
			border-radius: 100px;
		}
	}
}

.organization-form .upload-avatar .default-photo img {
	border-radius: 0;
	width: auto;
	height: auto;
	max-height: 60px;
	max-width: 90px;
}

.main-info {
	margin-left: calc(25% + 30px);
}

.contact-form, .organization-form {
	.padded-section {
		padding: 10px;
	}

	.name-section {
		background: #f6f6f6;
		margin: -10px -10px 10px -10px;
		padding: 10px;
		border-bottom: 1px solid #f2f2f2;
	}

	.top-section {
		display: flex;

		.upload-avatar {
			min-width: 100px;
			margin-right: 10px;
		}

		.main-info {
			flex: 1;
			margin-left: 0;

			input {
				margin: 3px 0;
			}
		}
	}
}

.contact-form .fields, .organization-form .fields {
	> div {
		.ownership {
			flex-shrink: 0;
			width: 30px;
			text-align: center;
		}
	}
}

.contact-form .field-section, .organization-form .field-section {
	display: flex;
	align-items: center;
	background: white;
	padding: 5px;
	border-radius: 5px;
	border: 1px solid #f2f2f2;

	&:not(:first-of-type) {
		margin-top: 5px;
	}

	.remove {
		flex-shrink: 0;
		margin-right: 5px;
		width: 40px;
		text-align: center;

		i {
			color: #ccc;
			font-size: 20px;
			cursor: pointer;

			&:hover, &:active {
				color: $ruby;
				font-weight: 500;
			}
		}
	}

	.ownership {
		flex-shrink: 0;
		width: 40px;
		text-align: center;

		i {
			font-size: 20px;
			cursor: pointer;

			&.fa-lock {
				color: #aaa;
			}

			&.fa-rss {
				color: $gold;
				font-weight: 500;
			}
		}
	}

	.fields {
		flex: 1;

		table td {
			padding: 0;
		}

		input {
			margin: 3px;
		}
	}

	.location.fields table td {
		padding: 0 5px;
	}
}

.contact-form, .user-form, .organization-form {
	label {
		margin-bottom: 0;
		margin-top: 5px;
		font-size: 13px;
		margin-bottom: -2px;
		color: $blue-etc;

		i {
			font-size: 15px;
			margin-right: 8px;
			color: $green;
		}

		&:first-of-type {
			margin-top: 0;
		}
	}

	.bio-container {
		margin-top: 10px;
	}

	.other-info, .bio-container {
		padding: 10px;

		.section {
			margin-top: 20px;

			&:first-of-type {
				margin-top: 0;
			}
		}

		h4 {
			margin: 0 -20px 10px -20px;
			background: #f2f2f2;
			padding: 10px 20px;
			font-size: 14px;
			font-weight: 600;
		}

		h5 {
			margin: 0;
			margin-bottom: 10px;
			font-size: 13px;
			font-weight: 500;
			color: #666;

			.add-another {
				margin-left: 5px;
				background: #f2f2f2;
				font-size: 13px;
				color: #aaa;
				padding: 3px 8px;
				cursor: pointer;
				border-radius: 5px;

				&:hover, &:active {
					color: $green;
					background: #efefef;
				}
			}

			i.toggle {
				margin-left: -20px;
				cursor: pointer;
				margin-top: -2px;
				color: #aaa;
				float: left;
				font-size: 20px;
				width: 30px;
				margin-right: 0;
				text-align: center;
			}

			i {
				margin-right: 8px;
				color: $green;
			}
		}
	}
}

.websites, .social_profiles {
	> div {
		padding: 5px 0;
		margin-bottom: 5px;
		border-bottom: 1px solid #efefef;
		display: table;
		width: 100%;

		&:last-of-type {
			margin-bottom: 0;
			border-bottom: 0;
		}

		.value {
			display: table-cell;
		}

		i.fa-times-circle {
			display: table-cell;
			width: 30px;
			text-align: center;
			font-size: 18px;
			vertical-align: middle;
			cursor: pointer;

			&:hover, &:active {
				color: firebrick;
			}
		}
	}
}

.social-network {
	background: #f2f2f2;
	padding: 3px 10px;
	margin: 0 20px 0 10px;
	border-radius: 6px;
	font-size: 13px;
	color: #888;
	font-weight: 600;
}

.info-panel {
	.contact-stub-list {
		margin: 0;
	}
}

.contact-stub-row, .email-participant, .email-attachment, .info-panel .file {
	cursor: pointer;
	border: 1px solid #f5f9ff;
	padding: 5px 8px;

	.name {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&:hover, &:active {
		background: #fcfcfc;
		border-color: #f2f2f2;
	}
}

.contact-widget {
	background: white;
	border: 1px solid #f5f9ff;
	margin-bottom: 5px;

	div:last-of-type {
		border-bottom: 0;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
}

.attached-files {
	margin-top: 0;
}

.contact-stub-row, .email-participant, .email-attachment, .contact-widget .contact-heading, .info-panel .file {
	text-decoration: none;
	display: flex;
	align-items: center;
	background: white;
	margin-bottom: 5px;
	border-radius: 6px;
	padding: 5px 8px;

	.avatar-container {
		margin-right: 5px;
	}

	&.email-participant {
		display: table;
		width: 100%;
		box-sizing: border-box;

		&.unexpandable {
			background: none;
			border: 0;
			cursor: auto;
		}

		.avatar {
			display: table-cell;
		}

		.name {
			vertical-align: middle;
			display: table-cell;

			strong {
				font-weight: 600;
			}
		}
	}

	.initials-overlay {
		display: none;
	}

	.generic-avatar {
		font-size: 32px;
		display: inline-block;
	}

	.name {
		font-size: 13px;
		line-height: 15px;
		margin-bottom: 2px;
		flex: 1;
		font-weight: 500;
	}

	.etc {
		line-height: 15px;
	}

	.avatar.inline {
		height: 32px;
		width: 32px;
		border: 0;
		box-shadow: none;
		background: none;
		float: left;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
}

.email-attachment {
	.name {
		word-break: break-word;
	}

	i {
		width: 30px;
		font-size: 24px;
		margin-right: 5px;
		text-align: center;
		flex-shrink: 0;
	}
}

.contact-widget {
	border: 1px solid #f2f2f2;
	border-radius: 6px;

	.more {
		padding: 10px;
	}

	.employments {
		margin-bottom: 10px;

		.employment {
			padding: 0 0 0 30px;
			background: none;
			font-size: 12px;
		}
	}

	.social-profiles {
		background: #fafafa;
		padding: 2px 5px;
		text-align: center;
		border-top: 1px solid #f2f2f2;
		margin: 10px -10px -10px -10px;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;

		a {
			display: inline-block;
			border-radius: 50%;
			width: 30px;
			line-height: 18px;
			padding: 4px 0;
			background: #efefef;
			margin: 0 5px;
		}

		i {
			font-size: 18px;
			margin: 0;
		}
	}

	.contact-heading {
		background: #f6f6f6;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.background {
		font-size: 12px;
		line-height: 16px;
		color: #777;
	}
}

.contact-widget .contact-stats, .new-contact-card .contact-stats {
	display: table;
	width: 100%;
	background: #fafafa;
	padding: 0;
	margin: 0;

	.stat {
		text-align: center;
		padding: 5px 0 8px 0;
		display: table-cell;
		font-size: 16px;
		font-weight: 500;
		color: $blue-etc;

		i {
			margin-bottom: 8px;
			color: $green;
			margin-top: 5px;
			font-size: 24px;
			display: block;
		}
	}
}

.social-network, .social-profiles a {
	&.twitter {
		background: #1DA1F2;
		color: white;

		i { color: white; }
	}

	&.facebook {
		background: #3B5998;
		color: white;

		i { color: white; }
	}

	&.linkedin {
		background: #0077B5;
		color: white;

		i { color: white; }
	}

	i {
		color: #aaa;
		margin-right: 5px;
	}
}

.contact-grid {
	margin: -10px -10px 0 -10px;
}

.new-contact-card {
	display: inline-block;
	width: calc(25% - 10px);
	border: 1px solid #f2f2f2;
	border-radius: 6px;
	box-sizing: border-box;
	text-align: center;
	position: relative;
	margin: 5px;
	margin-top: 33px;

	.inline.avatar {
		width: 60px;
		height: 60px;
		top: -34px;
		left: calc(50% - 30px);
		position: absolute;

		.initials-overlay {
			position: absolute;
			top: 38px;
			font-size: 14px;
		}
	}

	.name {
		background: #f2f2f2;
		color: #888;
		font-weight: 500;
		clear: both;
		font-size: 15px;
		padding: 5px 10px;

		a {
			padding: 2px 8px;

			&:hover, &:active {
				background: #fafafa;
				border-radius: 6px;
			}
		}
	}

	&:hover, &:active {
		.inline-contact-controls i {
			visibility: visible;
		}
	}

	.contact-stats {
		margin: 0;
		border-bottom: 0;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	&.pinned {
		.fa-thumbtack {
			visibility: visible;
			color: #e0cd22;

			&:before {
				font-weight: 900;
			}

			&:hover, &:active {
				background: #fffcd7;
			}
		}
	}

	.contact-stats .stat {
		border-bottom: 0;
	}

	.inline-contact-controls {
		margin: 0 10px;

		.new-task.pop-under-form {
			top: -4px;
			left: 173px;
		}

		i {
			color: $blue-etc;
			cursor: pointer;
			width: 25px;
			text-align: center;
			font-size: 16px;
			margin-top: 5px;
			padding: 5px;
			box-sizing: border-box;
			visibility: hidden;
			border-radius: 6px;

			&:hover, &:active {
				background: #fafafa;

				&:before {
					font-weight: 900;
				}
			}
		}

		.fa-thumbtack {
			float: left;
			margin-right: 25px;
		}

		.fa-paper-plane, .fa-check-circle {
			float: right;
		}
	}
}

.contact-name .fa-thumbtack, .organization-name .fa-thumbtack {
	color: $green;
	margin-right: 10px;
}

#main-sidebar .sub.recent-contacts {
	a {
		width: 100%;
		box-sizing: border-box;
		display: table;
		padding: 3px 10px;

		> span {
			display: table-cell;
			text-align: left;
			position: relative;

			span {
				position: absolute;
				top: 0;
				padding: 3px 0;
				width: 100%;
				left: 0;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.record-name {
		vertical-align: middle;
	}

	.recent-avatar {
		width: 22px;

		img {
			height: 22px;
			width: 22px;
			border-radius: 50%;
			margin-right: 10px;
		}

		i {
			margin-right: 10px;
			color: $blue-etc;
			font-size: 22px;
			line-height: 22px;
			width: 22px;
		}
	}

	a.active .recent-avatar {
		i {
			color: white;
		}
	}
}

.contact-view {
	h1 a {
		color: $green;
	}

	h1 a.comments {
		color: #aaa;
	}

	.new-task.pop-under-form {
		top: 51px;
		z-index: 1000;
		right: 84px;
	}
}

#contact .contact-info-card {
	margin: 0 auto 20px auto;
	max-width: 500px;
	font-size: 13px;

	.card-body {
		margin: 0 10px;
		border-radius: 6px;
		border: 1px solid #efefef;
		background: #fafafa;
		box-shadow: 0 0 30px #f2f2f2;

		.bio {
			border-top-right-radius: 6px;
			border-top-left-radius: 6px;
			padding: 10px;
			font-weight: 500;
			background: white;
			white-space: pre-line;
		}

		.contact-info {
			padding: 0px 10px;

			.bit {
				display: flex;

				i {

				}
			}
		}

		.contact-sections {
			margin: 0;
			width: 100%;

			.empty-card-slot {
				color: #ddd;
				text-align: center;
				margin: 0 10px 10px 10px;
				font-size: 24px;
			}

			h4 {
				text-align: left;
				color: #888;
				font-size: 14px;
				font-weight: 500;
				margin: 0;
				padding: 8px;


				i {
					width: 20px;
					font-size: 18px;
					text-align: center;
					color: $green;
					box-sizing: border-box;
					margin: 0 15px 0 5px;
				}
			}
		}
	}
}

.contact-type {
	text-transform: capitalize;
	font-size: 13px;
	font-weight: 500;
	color: #777;
	background: white;
	display: inline-block;
	border: 2px solid #edf4ff;
	border-radius: 30px;
	margin: 10px;
	margin: 20px auto -20px auto;
	padding: 6px 16px;
	z-index: 10000;

	.text-button {
		margin-left: 10px;
		font-size: 12px;
		padding: 0px 6px;
		font-weight: 600;
		color: #ccc;
		text-transform: none;
		cursor: pointer;
		background: none;
		color: $green;
		font-size: 12px;

		&:hover {
			color: $blue-etc;
		}
	}

	.globalizing {
		color: $gold;
		margin-left: 10px;
		font-weight: 900;
	}

	&:before {
		font-family: 'Font Awesome 5 Pro';
		color: $blue-etc;
		margin-right: 10px;
		font-size: 17px;
		float: left;
		font-weight: 900;
	}

	&.personal:before {
		content: "\f30d";
		color: $blue-etc;
	}

	&.shared:before {
		content: "\f13e";
		color: $gold;
	}

	&.global:before {
		content: "\f09e";
		color: $green;
	}
}

.privacy-note {
	padding: 10px;
	display: flex;
	background: #fffcd7;
	border: 1px solid #f5efa5;
	color: #444;
	margin: 0 10px 10px 10px;
	border-radius: 5px;

	i {
		flex-shrink: 0;
		margin-right: 10px;
		width: 30px;
		text-align: center;
		color: #e0cd22;
		align-self: center;
		font-size: 24px;
	}
}

.contacts {
	.tripartite {
		width: calc(100% + 40px);
		margin-left: -20px;
		display: table;
		table-layout: fixed;
		background: linear-gradient(to bottom, #fafafa, white 80px);
		margin-top: -20px;
		border-top: 1px solid #f2f2f2;
		padding-top: 20px;

		> div {
			display: table-cell;
			border-right: 1px solid #f2f2f2;

			&:last-of-type {
				border-right: 0;
			}
		}
	}

	.contact-table {
		margin-top: 0;
		margin-bottom: 20px;
		width: calc(100% + 30px);

		.record-row {
			&:hover, &:active {
				.row-controls {
					display: none;
				}
			}
		}
	}
}

.contact-stub-list {
	margin: 10px;
}

i.reset-existing-organization {

}

i.remove-existing-organization {
	font-weight: 900;
	margin-left: 5px;
	padding: 5px;
	border-radius: 50px;
	color: $gold;
	cursor: pointer;
	background: #fafafa;

	&:hover, &:active {
		background: #f2f2f2;
	}
}


.current-overview {
	background: #fafafa;
	margin: -20px -20px 20px -20px;
	padding: 10px;
}

.duplicate-section .instructions {
	margin: -20px -20px 10px -20px;
	padding: 10px;
	color: #999;
	text-align: left;
	font-size: 15px;
	background: #f2f2f2;;
	font-weight: 500;

	&:before {
		font-family: 'Font Awesome 5 Pro';
		content: "\f05a";
		width: 40px;
		display: inline-block;
		font-size: 20px;
		text-align: center;
		color: #e0cd22;
	}
}

.duplicate {
	padding: 10px 20px;
	margin: 0 -20px;
	border-bottom: 1px solid #f2f2f2;
	display: flex;

	&:last-of-type {
		margin-bottom: -10px;
		border-bottom: 0;
	}

	.merge {
		width: 40px;
		text-align: left;

		.merge-control {
			background: #fafafa;
			display: inline-block;
			width: 20px;
			height: 20px;
			text-align: center;
			border-radius: 50%;
			padding: 5px;
			border: 1px solid #efefef;
			cursor: pointer;

			&:active, &:hover {
				background: $green;
				border-color: $green;

				i {
					color: white;
				}
			}
		}
	}

	.contact-info {
		flex-grow: 1;
	}

	.primary {

	}

	&.current {
		border: 0;
		background: white;
		margin: 0;
		border-radius: 6px;
		border: 1px solid #f2f2f2;

		.merge, .primary {
			display: none;
		}
	}

	.name {
		font-size: 16px;
		color: $blue-etc;
		font-weight: 600;
		letter-spacing: -0.2px;
	}

	.background {
		margin-top: 3px;
	}

	.email-addresses, .websites {
		padding: 5px 0 5px 40px;
		color: #999;

		&:before {
			font-family: 'Font Awesome 5 Pro';
			float: left;
			color: #e0ecff;
			margin-left: -40px;
			text-align: center;
			width: 40px;
			font-size: 16px;
		}

		&.email-addresses:before {
			content: "\f0e0";
		}

		&.websites:before {
			content: '\f0c1';
		}
	}
}

.contact-share-list {
	margin: -20px;

	.contact-share-row {
		padding: 10px 20px;
		border-bottom: 1px solid #efefef;
		display: flex;
		align-items: center;
		width: calc(100% - 40px);

		strong {
			width: 40%;
		}

		.multipart-console {
			width: 60%;
			text-align: right;;
		}
	}
}