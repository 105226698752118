
.matches {
	margin-bottom: 2px;
	font-weight: 500;
	padding: 6px 10px;
	display: inline-block;
	border-radius: 20px;
	background: #fafafa;
	color: #aaa;

	i {
		color: #aaa;
		margin-right: 5px;
	}
}

.filters .extra-options.cell {
	vertical-align: bottom;
	color: #ccc;
	font-size: 1.5em;

	.toggle-icon {
		cursor: pointer;
		margin-right: 10px;

		&.has_attachment i.fa {
			color: #e0cd22;
			font-weight: 500;
		}

		&.unread i.fa {
			color: $green;
		}

		&.flagged i.fa {
			color: firebrick;
		}

		i {
			margin-bottom: 6px;
			font-weight: 900;
		}

		&:last-of-type {
			margin-right: 0;
		}

		&.off {
			i.fa {
				color: #ccc;
				font-weight: 300;
			}
		}
	}
}

.filter-submit {
	vertical-align: bottom;
	text-align: center;

	i {
		color: $green;
		font-size: 24px;
		margin-bottom: 5px;
	}
}

.filters .table {
	input[type="text"] {
		width: 100%;
		box-sizing: border-box;

		&.active {
			background: #fffcd7;
			border-color: #f5efa5
		}
	}
}