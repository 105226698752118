.feed {
	position: relative;
	width: 300px;

	&:before {
		top: 20px;
		z-index: 0;
		bottom: 10px;
		position: absolute;
		width: 3px;
		left: 13px;
		z-index: 0;
		border-radius: 5px;
		content:"";
		display: block;
		background: #cfdcec;
	}

	.feed-item {
		position: relative;
		margin: 20px 0;
		z-index: 100;

		.body {
			margin-left: 50px;
			padding: 5px 10px;
			margin-top: -5px;
			font-size: 13px;
			background: #fcfcfc;
			border: 1px solid #f2f2f2;
			border-radius: 6px;
			color: #666;
			position: relative;

			h3 {
				margin: 0;
				font-size: 13px;
				font-weight: 500;
				letter-spacing: -.2px;
				line-height: 18px;
				margin-bottom: 3px;
			}

			&:before {
				position: absolute;
				background: #fcfcfc;
				content:"\25CF";
				color: white;
				text-shadow: 0 0 1px #f2f2f2;
				font-size:12px;
				line-height:12px;
				text-indent:6px;
				top:10px;
				left:-10px;
				width: 17px;
				height: 17px;
				-ms-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				border-left:1px solid #f2f2f2;
				border-bottom:1px solid #f2f2f2;
			}
		}

		i {
			position: absolute;
			color: $blue-etc;
			background: aliceblue;
			z-index: 100;
			border-radius: 50px;
			width: 25px;
			height: 25px;
			top: 5px;
			border: 2px solid #cfdcec;
			line-height: 25px;
			text-align: center;
		}
	}
}

.sfeed {
	margin-left: 20px;
	width: 300px;
	background: aliceblue;
	position: relative;

	&:before {
		top: 20px;
		z-index: 0;
		bottom: 10px;
		position: absolute;
		width: 1px;
		left: 50px;
		float: left;
		border-radius: 5px;
		content:"";
		display: block;
		background: $blue-etc;
	}
}

.sfeed-item {
	position: relative;
	margin-top: 20px;

	&:last-child:before {
		top: 27px;
		z-index: 0;
		bottom: 10px;
		position: absolute;
		width: 1px;
		left: 50px;
		float: left;
		border-radius: 5px;
		content:"";
		display: block;
		background: white;
	}

	.ago {
		font-size: 12px;
		width: 40px;
		color: $blue-etc;
		text-align: center;
		font-weight: 600;
		margin-top: 12px;
		float: left;
	}

	.dot {
		background: $blue-etc;
		border-radius: 20px;
		height: 11px;
		width: 11px;
		margin-left: 3px;
		z-index: 100;
		margin-top: 13px;
		float: left;
		border: 2px solid aliceblue;
	}
}

.timeline-item {
	margin: 10px;
	margin-left: 80px;
	padding: 10px 20px 10px 50px;
	margin-top: -5px;
	font-size: 13px;
	background: #fcfcfc;
	border: 1px solid #f2f2f2;
	border-radius: 6px;
	color: #666;
	position: relative;

	&:before {
		position:absolute;
		background: #fcfcfc;
		content:"\25CF";
		color: white;
		text-shadow: 0 0 1px #f2f2f2;
		font-size:12px;
		line-height:12px;
		text-indent:6px;
		top:10px;
		left:-10px;
		width: 17px;
		height: 17px;
		-ms-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		border-left:1px solid #f2f2f2;
		border-bottom:1px solid #f2f2f2;
	}

	.text {
		margin-top: 10px;
	}

	.timeline-created {
		display: inline;
		margin-top: 0;
		color: #ccc;
		font-size: 12px;
		margin-right: -10px;
		float: right;
	}

	h3{
		background: none;
		word-break: break-all;
		font-size: 15px;
		line-height: 18px;
		font-weight: 600;
		margin-bottom: 0;
		margin-top: 0;

		a {
			color: $blue-etc;
		}
	}

	.timeline-icon {
		display: inline-block;
		float: left;
		margin-top: -2px;
		margin-left: -38px;
		text-align: center;

		i {
			color: #bcd7ef;
			font-size: 24px;
			margin: 0;
			padding: 0;
		}
	}

	.note {
		margin-bottom: 0;

		.text {
			margin: auto;
		}
	}
}

.activity-types {
	border-top: 0;
	padding: 0;
	margin-top: -5px;
	color: #eee;

	a {
		&:hover, &:active {
			color: $medium-blue;
		}

		&.on {
			color: $blue-etc;
		}
	}
}
