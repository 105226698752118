body {
	margin: 0;
	/*border-top: 3px solid maroon;*/
	background: #f2f5f9;
	/*background: linear-gradient(to bottom, 0 50, #ebf3ff, #f2f5f9);*/
	/*background: linear-gradient(to bottom right, #ebf3ff, white);*/
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'helvetica neue', 'helvetica', 'sans-serif';
	font-weight: 400;
	font-style: normal;
	color: #666;
	font-size: 14px;
	line-height: 18px;
}

a {
	cursor: pointer;
	text-decoration: none;
	color: $blue-etc;

	&.secondary {
		color: #ccc;
	}

	&.em {
		padding-right: 10px;

		&:after {
			position: absolute;
			content: '';
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			border-left: 5px solid $link-blue;
			margin-top: 5px;
			margin-left: 5px;
		}
	}
}


p {
	margin-top: 0;

	&.small {
		font-size: 13px;
	}

	&.teaser {
		font-size: 20px;
		line-height: 26px;
		color: #aaa;
		margin-top: -15px;
		margin-bottom: 30px;

		&.padded {
				margin-top: 0;
		}
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.separator {
	margin: auto 10px;

	&:before {
		content: '\00b7';
	}
}

.date {
	color: #aaa;
}

span.small {
	font-size: 14px;
}


.buttons, #buttons {
	margin-top: -2px;
	margin-right: 0;

	.button {
		font-weight: normal;
		font-size: 14px;
		width: 220px;
		color: white;

		.icon {
			display: inline-block;
			width: 20px;
			text-align: center;
			margin-right: 8px;
			margin-left: 2px;
		}

		&:last-child {
			margin-right: 10px;
		}
	}
}



h1.underlined:after, h2.underlined:after, h3.underlined:after, h4.underlined:after, h5.underlined:after {
	height:1px;
	background:$medium-blue;
	width:45%;
	display:block;
	content:'';
	margin:6px auto;
}

h1.full.underlined:after, h2.full.underlined:after, h3.full.underlined:after, h4.full.underlined:after, h5.full.underlined:after {
		width: auto;
}


h5.full.underlined {
	color: #999;
	font-size: 14px;
	font-weight: normal;
	margin-bottom: 0;
}

.etc {
	color: #888;
	font-size: 12px;

	&.normal-size {
		font-size: 14px;
		line-height: 18px;
	}

	i {
		font-size: 0.9em;
		color: #ccc;
	}

	&.inline {
		margin-left: 10px;
	}

	&.top-line {
		margin-left: -10px;
	}

	a.underlined {
		color: #888;
		border-bottom: 1px solid #ccc;
		font-weight: normal;
	}
}

.timeline-item {
	&:hover {
		.timeline-created {
			display: inline;
		}
	}
}

h3 .domain {
		color: #ccc;
		font-size: 18px;
}

h3 .action {
/*    background: #f5f9ff;
		padding: 5px;
		border-radius: 5px;*/
}

h3 .method {
		color: #ccc;
}



.right-aligned {
	text-align: right;
}

.failed {
	color: #f69f9f;
}

.main-form table {
	td {
		padding: $small-padding;
	}
}

#page {
	margin: 20px 3% 0 3%;
/*  width: 960px;*/
	text-align: left;
	border-top: 0;
	border-bottom: 0;
	position: relative;
}

.info.heading, .extra.heading, .notice.heading, .error.heading, .integration.heading {
	padding: 15px;
	text-align: center;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	margin: 0;

	&.large {
		padding: $medium-padding;
		font-size: 14px;

		i {
			font-size: 2.5em;
		}

		h1 {
			font-size: 20px;
			font-weight: normal;
			color: $blue-etc;
			margin-left: 10px;
			display: inline;
		}
	}

	&.integration {
		background: #f5f9ff;
		border-bottom: 1px solid #d4ebff;
		margin: (-$medium-padding);
		margin-bottom: 20px;
		text-align: left;
		padding: 0;
		overflow: auto;

		.icon {
			background: #d4ebff;
			padding: 8px;
			border-radius: 6px;
			display: inline-block;
			float: left;

			img {
				max-height: 30px;
			}
		}

		&.large {
			padding: $small-padding;

			h1 {
				display: inline-block;
			}
		}
	}

	&.notice {
		background: #d0ffd1;
		border-color: #99d089;
	}
}

#content, .main-content {
	/*overflow: hidden;*/
	width: auto;
	padding: $medium-padding;
	background: white;
	/*border-radius: 6px;*/
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	/*display: flex;
	flex-direction: row;
	flex-wrap: nowrap;*/

	.main-panel {
		min-width: calc(100% - 220px);
	}

	&:last-child.main-panel {
		width: 100%;
	}

/*
	.share-bar {
		flex-shrink: 0;
		margin: 0 20px;
		width: 200px;
		box-sizing: border-box;
		background: #fafafa;
		border: 1px solid #f2f2f2;
		border-radius: 6px;

		.heading {
			padding: 10px 10px 10px 40px;
			margin: 0;
			background: white;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			font-weight: 500;
			color: $green;
			font-size: 14px;
			letter-spacing: -0.2px;
			border-bottom: 1px solid #f2f2f2;

			i {
				margin-left: -40px;
				width: 40px;
				text-align: center;
				color: $gold;

				&:before {
					font-weight: 600;
				}
			}
		}

		.options {
			background: #f6f6f6;

			span {
				font-size: 13px;
				padding: 5px;
				margin: 5px;
				box-sizing: border-box;
				font-weight: 500;
				border-radius: 5px;
				border: 1px solid #efefef;
				cursor: pointer;
				color: #888;
				display: inline-block;
				width: calc(50% - 10px);
				text-align: center;
				background: white;

				&:hover, &:active {
					background: #f9f9f9;
				}

				&.following {
					background: $green;
					color: white;
					font-weight: 600;

					&:hover, &:active {
						background: #7cb36e;
					}
				}
			}
		}

		.sharees {
			font-size: 13px;
			margin: 10px;
			color: #888;

			.post-comma {
				font-weight: 500;
			}
		}
	}*/

	&.gray {
		background: #fafafa;
	}

	&.bordered {
		border-top: 2px solid #f2f2f2;
	}

	&.headingless {
		border-top-right-radius: 6px;
		border-top-left-radius: 6px;
	}

	&.with-sidebar {
		padding-left: 320px;
	}

	&.accent {
		background: #f5f9ff;
		margin: (-$medium-padding);
		padding: $medium-padding;

		.clean tr td {
			border-color: $medium-blue;

			input {
				border-color: $medium-blue;
			}
		}
	}

	.left-side-panel {
		margin-left: -300px;
	}

	&.search-report {
		.extra {
			margin: 0 0 20px 0;
			border: 1px solid #d4ebff;
			border-radius: 6px;
		}
	}
}

#triage-bar {
	background: #013a69;
	padding: 7px 40px;
	color: white;
}

#top-bar {
	background: $link-blue;
	background: $link-blue; /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YyZjJmMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlZGVkZWQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: linear-gradient(to bottom,  $link-blue 0%,#005fa5 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$link-blue', endColorstr='#005fa5',GradientType=0 );
	padding: 0;
	color: white;
	position: relative;

	.logo {
		text-align: center;
		line-height: 60px;
		color: white;
		font-size: 22px;
		font-weight: 600;
	}

	a {
		color: white;
		position: relative;

		&.profile, &.right-link {
			margin: 0 2px;
			padding: 6px 8px;
			line-height: 24px;
			float: right;
			margin-top: 12px;
			width: 24px;
			height: 24px;
			font-size: 16px;
			border-radius: 40px;
			text-align: center;
			margin-right: 20px;

			&.right-link {
				margin-right: 0;
			}

			&:hover, &:active {
				background: #0074c8;
			}

			&.active {
				background: #013a69;
			}
		}
	}

	ul {
		list-style-type: none;
		display: inline-block;
		margin: 10px;
		margin-left: 0;

		li {
			display: inline-block;

			a {
				display: inline-block;
				margin: 1px 4px;
				padding: 8px 10px;
				border-radius: 5px;
				color: $blue-etc;
				font-weight: 500;

				i {
					color: $blue-etc;
					font-size: 1.3em;
					margin-right: 10px;
				}

				&:hover, &:active {
					background: #0074c8;
				}

				&.active {
					background: #013a69;
				}
			}

			&:first-child {
				margin-left: 0;
			}
		}
	}
}


.contact-card, .contact-row {
	margin: auto 20%;

	.heading {
		background: #f5f9ff;
		margin: -10px -10px 0 -10px;
		padding: 15px;
		color: $blue-etc;
		text-align: left;
		font-size: 16px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;

		a {
			color: $blue-etc;
			border-bottom: 1px solid #d4ebff;
		}

		.affiliation {
			margin-top: 5px;
			font-size: 13px;
		}

		i {
			font-size: 2em;
			margin: 0 10px;
		}
	}
}

.modal .contact-card {
	margin: -10px;
	width: auto;
}


div.error {
	background: #ffd5d5;
	padding: 10px 20px;
	margin: 0 0 20px 0;
	font-weight: 500;
	color: #cc4f4f;
	text-shadow: none;
	border-radius: 9px;

	&.inline {
		margin: 10px 0;
		text-align: left;
		padding: 5px 10px;
		border-radius: 10px;
	}

	ul.table {
		li {
			border-color: #f69f9f;
		}
	}
}

a.external {
	font-size: 12px;
	color: #888;

	i {
		font-size: 1em;
	}
}

div.info, .notice, .left-rasa, .active-filter {
	background: #fffedb;
	border-bottom: 1px solid #fef544;
	margin: (-$medium-padding);
	margin-bottom: 20px;
	padding: 10px 20px;
	font-weight: 500;

	p {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&.half {
		border-right: 1px solid #fef544;
		border-bottom-right-radius: 6px;
	}
}

.info.with-icon {
	border-bottom: 0;
	text-align: center;

	.info-icon {
		display: block;
		margin-bottom: 20px;
		font-size: 2em;
		margin-right: 10px;
	}

	.text {
		display: block;
	}
}

.rasa, .sub-rasa, .super-rasa {
	margin: -($medium-padding);
	padding: $medium-padding;
	background: #f5f9ff;
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YyZjJmMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlZGVkZWQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(to bottom,  #f7fcff 0%, #f5f9ff 100%);
	background: -webkit-linear-gradient(to bottom,  #f7fcff 0%,#f5f9ff 100%);
	background: -o-linear-gradient(to bottom,  #f7fcff 0%,#f5f9ff 100%);
	background: -ms-linear-gradient(to bottom,  #f7fcff 0%,#f5f9ff 100%);
	background: linear-gradient(to bottom,  #f7fcff 0%,#f5f9ff 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7fcff', endColorstr='#f5f9ff',GradientType=0 );

	&.inverse {
		background: none;
	}

	h2 {
		color: $blue-etc;
		text-align: center;
		font-weight: normal;
		margin: 20px;
	}

	.add-button {
		text-align: center;

		i {
			color: #86a8d6;
			margin-top: 3px;
		}
	}

	.text {
		margin: auto;
		max-width: 75%;
		background: white;
		border-radius: 10px;
		border: 1px solid $medium-blue;
		padding: $medium-padding;
		color: #999;

		.overview {
			margin: ($medium-padding) (-($medium-padding)) (-($medium-padding)) (-($medium-padding));
			padding: $medium-padding;
			background: #fcfcfc;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			border-top: 1px solid #efefef;
		}

		.features {
			border: 1px solid $medium-blue;
			margin: 20px -80px -21px -80px;
			padding: $medium-padding;
			background: white;
			border-radius: 6px;

			dl {
				margin: -10px auto;
				display: table;

				dt {
					font-weight: 600;
					border-bottom: 1px solid #efefef;
					display: table-cell;
					width: 180px;
					vertical-align: top;
					padding: 10px 10px;

					.icon {
						width: 30px;
						font-size: 1.2em;
						display: inline-block;
						text-align: center;
						color: forestgreen;
					}
				}

				dd {
					display: table-cell;
					border-bottom: 1px solid #efefef;
					padding: $small-padding;
				}
			}

			div:last-child {
				dd, dt {
					border-bottom: 0;
				}
			}
		}
	}

	.record-icon {
		margin: auto;
		display: inline-block;
		border-radius: 60px;
		color: white;
		width: 60px;
		height: 60px;
		text-align: center;
		border: 1px solid $blue-etc;
		background: $blue-etc;

		i {
			font-size: 2.5em;
			line-height: 60px;
			color: white;
		}
	}
}

.rasa, .sub-rasa {
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}

.sub-rasa {
	border: 1px solid $medium-blue;
	margin-top: 0;
	margin: 20px;
	border-radius: 20px;
}

.super-rasa {
	border-bottom: 1px solid $medium-blue;
	margin-bottom: 20px;
	border-radius: 0;
}

.rasa-icon, .info-icon, .warning-icon, .disable-icon {
	float: left;
	margin-right: 20px;

	i.fa-circle {
		color: #fef544;
	}

	i.fa-info {
		color: #fffedb;
	}
}

.warning-icon {
	i.fa-circle {
		color: #ffd5d5;
	}

	i.fa-warning {
		color: #f69f9f;
	}
}

.disable-icon {
	i.fa-circle {
		color: $info-border;
	}

	i.fa-warning {
		color: goldenrod;
	}
}

.inline-warning, .duplicate {
	.warning-icon, .disable-icon {
		float: left;
		margin-top: 0;
		margin-right: 10px;
	}

	.text {
		margin-left: 50px;
		margin-top: 0;

		h4 {
			color: firebrick;
			padding-top: 10px;
		}
	}
}

.modal label {
	font-size: 14px;
}

label.inline {
	display: inline;
	font-weight: normal;
}

.small-progress, .slider {
	border: 1px solid #efefef;
	border-radius: 6px;
	height: 10px;

	div {
		height: 100%;
		background: #ccc;
		border-radius: 5px;
	}

	&.failing {
		div {
			background-color: #a71e29;
		}
	}

	&.pending {
		div {
			background-color: #e0cd22;
		}
	}

	&.successful {
		div {
			background-color: #67a157;
		}
	}

	&.blue {
		border-color: $blue-etc;

		div {
			background-color: $blue-etc;
		}
	}
}

.small-progress div.complete {
	border-radius: 5px;
}


.extra {
	h4 {
		color: $blue-etc;
		margin: 0;

		font-size: 14px;
		margin-left: -10px;
	}

	.search-summary {
		font-size: 12px;
		margin: -5px;
		margin-left: 10px;
	}
}

span.search-term, .term {
	background: #d4ebff;
	padding: 4px;
	border-radius: 5px;
}

.list {
	margin-bottom: 30px;

	&:last-child {
		margin-bottom: auto;
	}
}

.modal h4.block {
	margin-left: -10px;

	input[type='checkbox'] {
		font-size: 20px;
	}
}

.dropbox-address {
	background: #efefef;
	margin: 20px;
	border-radius: 30px;
	font-size: 16px;
	padding: 5px 40px;
	text-align: left;
}

.count {
	float: right;
	margin-left: 5px;
	margin-right: -5px;
	background: #eee;
	color: #999;
	padding: 0 8px;
	font-size: 12px;
	box-sizing: border-box;
	min-width: 20px;
	line-height: 20px;
	font-weight: 600;
	border-radius: 20px;
	text-align: center;

	&.alert {
		background: $rust;
		color: white;
	}

	&.warning {
		background: $gold;
		color: white;
	}

	&.blue {
		background: $blue-etc;
	}

	&.inline {
		float: none;
		margin: auto;
	}

	&.large {
		font-size: 13px;
		padding: 4px 12px;
	}

	&.medium {
		font-size: 12px;
		padding: 0 8px;
	}
}

.close-button, .contact-card .definitive, .action {
	padding: 5px 15px 5px 30px;
	background: #f2f2f2;
	border-radius: 35px;
	font-size: 13px;
	cursor: pointer;
	color: #999;
	border: 1px solid #efefef;
	display: inline-block;

	&:hover, &:active {
		background: #eaeaea;
		border-color: #eaeaea;
	}

	&.inline {
		font-weight: normal;

		i {
			font-size: 14px;
			margin-top: 4px;
		}
	}

	&.small {
		font-size: 12px;
		padding: 4px 12px 4px 28px;

		i {
			font-size: 15px;
			margin-top: 2px;
		}

		.count {
			margin-right: -3px;
			font-size: 9px;
			line-height: 16px;
			height: 16px;
			padding: 0;
			width: 16px;
			margin-top: 1px;
			margin-left: 8px;
		}
	}

	&.medium {
		font-size: 15px;
		padding: 5px 15px 5px 28px;

		.count {
			margin-right: -5px;
			font-size: 9px;
			margin-left: 5px;
		}

		i {
			font-size: 1.3em;
			margin-top: 0;
		}
	}

	&.large {
		border: 1px solid black #eee;
		font-size: 16px;
		font-weight: 400;
		padding: 8px 20px 8px 35px;

		i {
			font-size: 1.2em;
			margin: 0;
			margin-right: 15px;
			margin-left: -15px;
			padding: 0;
		}
	}

	&.inline {
		padding-top: 2px;
		padding-bottom: 2px;
	}

	&.tiny {
		font-size: 10px;
		padding: 4px 8px 4px 24px;
	}

	&.iconless {
		padding-left: 15px;

		&.small {
			padding: 2px 8px 2px 8px
		}

		&.medium {
			padding: 3px 15px;
		}

		&.tiny {
			padding: 4px 8px;
		}
	}

	.fa-stack, i {
		color: #ccc;
		margin-left: -20px;
		margin-top: 3px;
		float: left;
	}

	.fa-stack {
		margin-top: -5px;
		margin-left: -25px;
		font-size: 0.8em;

		i {
			margin-left: 0;
		}
	}

	&.right-icon {
		padding: 5px 30px 5px 15px;

		i {
			float: right;
			margin-left: -10px;
			margin-right: -22px;
		}
	}

	&.white {
		background: white;
		color: #ccc;
		color: $blue-etc;
		border: 1px solid #efefef;

		i {
			color: $blue-etc;
		}

		&:hover, &:active {
			background: #fafafa;
			border-color: #eee;
		}
	}

	&.light {

	}

	&.green {
		color: white;
		background: $green;
		border-color: $green;

		i {
			color: white;
			/*color: white;*/
		}
	}

	&.bold {
		font-weight: 500;
	}

	&.success, &.yes {
		background: #d0ffd1;
		color: #0d4d05;

		i {
			color: #0d4d05;
		}

		.count {
			background: forestgreen;
		}
	}

	&.pending, &.info, &.maybe {
		background: #e9d627;
		background-color: #e9d627;
		color: #9b8c10;

		i {
			color: #9b8c10;
		}

		.count {
			background: #9b8c10;
		}
	}

	&.warning, &.no, &.red, &.delete {
		background: #ffd5d5;
		color: #e07b81;
		border-color: #f0b2b4;

		i {
			color: #f69f9f;
		}
	}

	&.dark-blue {
		background: #5b80bb;
		border-color: #5b80bb;
		color: white;
		font-weight: 500;
		padding-left: 20px;
		padding-right: 20px;

		i {
			color: #f5f9ff;
		}
	}

	&.blue-white {
		background: white;
		color: #bbb;
		border-color: #efefef;

		i {
			color: #ddd;
		}
	}
}

a.action.togglable:hover, a.action.togglable:active, a.action.togglable.active {
		background: #ddd;
		color: #777;

		i {
			color: #999;
		}
	}

h4 .action {
	font-weight: normal;
}





.success_tag, .successful_tag, .yes_tag, .won_tag {
	background: forestgreen;
	text-shadow: none;
	color: #0d4d05;
	padding: 5px;

	a {
		color: white;
	}
}

.failure_tag, .no_tag, .lost_tag {
	background: firebrick;
	text-shadow: none;
	color: white;
	padding: 5px;

	a {
		color: white;
	}
}

.pending_tag, .maybe_tag {
	background: goldenrod;
	text-shadow: none;
	color: #858503;
	padding: 5px;

	a {
		color: white;
	}
}

.disabled_tag, .approvable_type, .page_type_tag {
	background: slategray;
	padding: 5px;

	a {
		color: white;
	}
}

.info_tag, .transaction {
	background: #eee;
	color: #999;
	padding: 5px;

	a {
		color: white;
	}

	&.transaction {
		background: #d0ffd1;
		color: forestgreen;
		margin-right: 5px;
	}
}

.fields div.field {
	border-bottom: 1px solid #f5f9ff;
	padding: 5px 10px;

	&:last-child {
		border-bottom: none;
	}
}

#sidebar table.full-width {
	margin: auto (-$small-padding);
	width: calc(100% + 20px);
}

.shrink, .expand {
	margin-top: 5px;
	display: inline-block;
	float: right;
	background: #efefef;
	border-radius: 5px;
	padding: 5px;

	i {
		color: #ccc;
	}
}

.description, .background {
	color: #999;
	font-size: 15px;
	line-height: 20px;
	white-space: pre-wrap;

	&.settings {
		margin-left: 40px;
		margin-top: -10px;
	}

/*
	padding-left: 30px;
	padding-right: 30px;

	&:before {
		margin-left: -30px;
		float: left;
		color: $blue-etc;
		content:'\f129';
		font-family: 'Font Awesome 5 Pro';
		font-size: 1.2em;
		display: inline-block;
	}
*/
	a {
		color: #86a8d6;
		border-bottom: 1px solid $medium-blue;
	}
}

.card, .integration-form, .server-block {
	padding: $small-padding;
	display: block;
	background: #fcfcfc;
	border: 1px solid #efefef;
	border-radius: 6px;
	box-shadow: 0 0 2px 1px #fcfcfc;
	color: #999;

	&.inverse {
		background: white;

		h4 {
			background: #fcfcfc;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}
	}

	.chart {
		height: 300px;
		width: auto;
	}

	h4 {
		margin: 0;
		border-bottom: 1px solid #efefef;
		font-size: 15px;
		font-weight: normal;
		padding: 8px;
		margin: (-$small-padding);
		margin-bottom: $small-padding;

		.fa-stack {
			margin-right: 3px;
		}

		i {
			margin-top: -2px;
		}
	}

	table.clean {
		&.small {
			font-size: 13px;
		}

		th {
			background: #fafafa;
			font-size: 14px;
			color: #666;
			border-bottom: 0;
		}

		td {
			border-bottom: 1px solid #efefef;
			font-size: 13px;
		}

		tr:last-of-type td {
			border-bottom: 0;
		}
	}

	table.full-width {
		margin: 0 -10px;
		width: calc(100% + 20px);
	}

	table.underlined {
		th {
			background: none;
			border-bottom: 2px soldi #efefef;
		}
	}

	&.tabled {
		padding: 0 $small-padding;

		table.clean th {
			&:first-of-type {
				border-top-left-radius: 6px;
			}

			&:last-child {
				border-top-right-radius: 6px;
			}
		}
	}

}
.email {
	td {
		padding: 5px;
	}

	.subject {
		overflow: hidden;
	}
}


.add-tag {
	cursor: pointer;
}

.fc-content {
	clear: none;
}

.quoted-email {
	color: #999;
	margin-top: 50px;
}


.note {
	margin-bottom: 20px;
	margin-top: 10px;

	.text {
		margin-left: 0;
	}

	.author {
		font-weight: normal;
	}

	.source-icon {
		margin-top: -25px;
		float: left;
		margin-left: -25px;
	}
}

.reply {
	font-size: 11px;
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 20px;
	color: #999;

	&.collapsed {
		.body {
			display: none;
		}

		.metadata {
			cursor: pointer;
		}

		.expander {
			display: inline-block;
			margin-right: 5px;
			font-size: 1em;
			cursor: pointer;
			width: 12px;
			text-align: center;
		}
	}
}

.main-form.gray .toggler span {
	background: white;
}

.new-task .toggler span {
	font-size: 13px;
}

.user-options .segmented.toggler {
	span {
		border: 0;
	}

	span.active {
		background: $blue-etc;
		color: white;
		font-weight: 700;
	}
}

.toggler {
	cursor: pointer;
	display: inline-block;

	&.segmented {
		&.small {
			span {
				font-size: 13px;
				padding: 4px 7px;
			}
		}

		span {
			border: 1px solid #eaeaea;
			border-right: 0;
			font-weight: 500;

			&.active {
				background: rgba(197, 234, 184, 0.3);
				color: $green;
				font-weight: 500;
			}

			&:last-of-type {
				border-right: 1px solid #eaeaea;
			}
		}
	}

	&.small {
		span {
			font-size: 13px;
			padding: 5px 8px;
		}
	}

	span {
		display: inline-block;
		background: #f2f2f2;
		padding: 5px 8px;
		font-size: 14px;
		font-weight: 500;
		color: #777;
		text-align: center;
		min-width: 30px;
		/*border: 1px solid #efefef;*/

		&.active {
			font-weight: 700;
		}

		&.on.active {
			background: $green;
			color: white;
		}

		&.off.active {
			background: #e07b81;
			color: white;
		}

		&:first-of-type {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			border-right: 0;
		}

		&:last-of-type {
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}

	&.on {
		color: forestgreen;
	}

	&.off {
		color: #ccc;
	}
}

.valid {
	border-color: #99d089;
}

.valid-icon, .invalid-icon, .pending-icon {
	margin-right: 5px;
	display: none;
	min-width: 20px;
}

.valid-icon {
	color: forestgreen;
}
.pending-icon {
	color: #ccc;
}
.invalid-icon {
	color: firebrick;
}



ul.bullets {
	margin: 10px 20px;
	padding: 0;
	list-style-type: none;
	font-size: 13px;

	li:before {
		color: $link-blue;
		content:'\2022';
		font-size: 13px;
		padding-right: 10px;
	}
}

#facebox {
	z-index: 10000;
}

.table {
	.action-icons {
		float: right;
		margin-right: 20px;
		margin-top: 5px;

		i {
			font-size: 1.4em;
			display: inline-block;
			margin-left: 7px;
		}
	}
}

ul.table, #sidebar ul.table, ul.integration-features, ul.queue-list {
	list-style-type: none;
	margin: 0;
	padding: 0;

	&.wide {
		li {
			padding: 8px 3px;

			.block {
				margin: -8px -4px;
				padding: 8px 16px;
			}
		}
	}

	&.narrow {
		li {
			padding: 3px;
		}
	}

	li {
		border-bottom: 1px solid #f3f3f3;

		&:last-of-type {
			border: none;
		}

		.block {
			display: block;
			padding: 19px;
			border: none;

			&.go {
				&:hover, &:active, &.active {
					background: #f4f9ff;

					&:before {
						float: right;
						color: $blue-etc;
						font-family: 'Font Awesome 5 Pro';
						content: '\f061';
					}
				}
			}

			&:hover, &:active, &.active {
				background: #f5f9ff;
			}

			.count {
				background: $medium-blue;
				color: $link-blue;
			}
		}
	}

	&.full-width {
		margin: 0 -20px;
	}
}

ul.table.gray a.block {
	&:hover, &:active {
		background: #fafafa;
	}
}





ul.inline-tabs {
	display: block;
	list-style-type: none;
	margin: 0;
	padding: 0;
	padding-bottom: 4px;
	border-bottom: 1px solid #efefef;
	text-align: center;

	li {
		display: inline;
		margin: 0 3px;

		a {
			background: #fcfcfc;
			border: 1px solid #f2f2f2;
			padding: 6px 12px;
			font-size: 12px;
			color: #999;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;

			&:hover, &:active, &.active {
				background: white;
				border-bottom: 1px solid white;
			}
		}
	}
}

.panel {
	width: 920px;
}

#content .panel {
	width: auto;
}

.instructions {
	padding: 0 30px 0 20px;
	color: #ccc;
	font-size: 16px;
	line-height: 20px;
	text-align: center;

	p {
		margin-bottom: 20px;
		color: #aaa;
	}
}

.panel {
	.sidebar {
		margin-left: -20px;
		width: 250px;
		float: left;
		padding: 10px;
	}

	.content {
		border: 2px solid #f5f9ff;
		margin-left: 240px;
		padding: 10px;
		border-radius: 6px;
		background: white;

		.heading {
			background: #f5f9ff;
			margin: -10px;
			margin-bottom: 10px;
			color: $blue-etc;
			padding: 10px;
			font-size: 15px;
			font-weight: 500;
			border-bottom: 2px solid #f5f9ff;
		}
	}

	&.small-sidebar {
		.sidebar {
			width: 50px;
		}

		.content {
			margin-left: 40px;
		}
	}

	&.large-sidebar {
		.sidebar {
			width: 350px;
			padding: 0 20px 20px 20px;
		}

		.content {
			margin-left: 350px;
			border: 0;
			padding: 0 20px 20px 40px;

			h3 {
				color: $blue-etc;
				margin-left: -40px;
				font-size: 16px;
				font-weight: 500;

				.icon {
					display: inline-block;
					width: 40px;
					color: $blue-etc;
					text-align: center;
				}
			}
		}
	}

	&:after {
		visibility: hidden;
		display: block;
		font-size: 0;
		content: ' ';
		clear: both;
		height: 0;
	}
}


.checklist .fa-stack {
	cursor: pointer;
}

.extra {
	.data-points {
		margin: 0;
		padding: 0;

		.data-point {
			margin: 0;
			padding: 0;

			h6 {
				padding-bottom: 0;
			}
		}
	}
}

#top-bar ul li a {
	&.green {
		a {
			background: forestgreen;
		}
	}
	&.red {
		a {
			background: firebrick;
		}
	}
	&.blue {
		a {
			background: #0074c8;
		}
	}
	&.yellow {
		a {
			background: goldenrod;
		}
	}
	&.purple {
		a {
			background: #9900FF;
		}
	}
}

.separated {
	padding: 3px 0;
	border-bottom: 1px solid #efefef;

	&:last-child {
		border: none;
	}
}

.modal .form-header {
	margin: (-($small-padding)) (-($small-padding)) $small-padding (-($small-padding));
	background: #f5f9ff;
	border-bottom: 1px solid #d4ebff;
	padding: $small-padding;

	input {
		border-color: #d4ebff;
	}

	.associated-record {
		display: block;
		margin-left: 5px;
		margin-top: 5px;
		line-height: 25px;

		a {
			background: #d4ebff;
			line-height: 16px;
			margin: 0;
			color: $blue-etc;
		}
	}
}


#sidebar .social-links ul li {
	border-bottom: 0;
	margin: 0;
	padding: 0;
}

h4.underlined {
	color: #86a8d6;
	font-size: 15px;
	font-weight: 600;

	a {
		border-bottom: 1px dashed $medium-blue;
		font-weight: 600;
		color: #86a8d6;
	}

	.icon {
		display: inline-block;
		width: 40px;
		text-align: center;
		color: $blue-etc;
	}

	span.etc {
		font-weight: normal;
		float: right;
		font-size: 13px;
	}

	.action {
		font-weight: normal;

		i {
			margin-left: -20px;
		}
	}

	&.inactive {
		color: #999;
		font-weight: normal;

		.icon {
			i {
				color: #ccc;
			}
		}
	}
}


.background p a.edit-background {
	border-bottom: 0;
	margin-left: 5px;
}

.pin-holder {
	position: relative;

	&:hover, &:active {
		.pin {
			display: block;
		}
	}
}

.pin {
	position: absolute;
	top: 10px;
	right: 10px;
	display: none;

	i {
		color: #bdd8f0;
	}

	&:hover, &:active {
		i {
			color: $blue-etc;
		}
	}
}

.flash {
	position: absolute;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
	font-weight: 500;
	top: 14px;
	right: 0;
	display: none;
	overflow-x: none;
	font-size: 14px;
	margin: 0;
	padding-left: 20px;
	padding-right: 10px;

	&.notice {
		background: $green;
		color: white;
	}

	&.error {
		background: #ffffd0;
		border: 2px solid #f4f400;
		color: #8a8a02;
	}
}



.panel .info {
	border: 0;
	margin: -10px;
	color: #ccc;
	background: none;
	font-size: 15px;
}


#bar-wrapper .content-block {
	top: 30px;

	.heading {
		padding: 5px 100px;
	}
}


.shade {
	height:100%;
	width:100%;
	position:fixed;
	left:0;
	top:0;
	z-index: 9999999;
	background-color:black;
	filter: alpha(opacity=5); /* internet explorer */
	-khtml-opacity: 0.5;      /* khtml, old safari */
	-moz-opacity: 0.5;       /* mozilla, netscape */
	opacity: 0.5;           /* fx, safari, opera */
	display: none;
}



.line-break {
	white-space: pre-wrap;
}



.main-filter {
	background: #efefef;
	margin: -20px -20px 20px -20px;
	padding: 10px;
	text-align: center;
}

.record-top-float {
	float: left;
	width: 200px;
	margin

	.profile-photo {
		float: none;
		margin: 0;
		margin-right: 0;
	}
}

.record-top-main {
	margin-left: 220px;
}


.owner {
	font-size: 13px;
	font-weight: 500;
	color: #aaa;
}

dl.labels, dl.table {
	margin: 0;
	padding: 0;

	dt, dd {
		margin: 0;
		margin-bottom: 5px;
		padding: 3px 8px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	dt {
		width: 15%;
		display: block;
		margin-right: 20px;
		float: left;
		background: #efefef;
		text-align: center;
		border-radius: 6px;
	}

	dd {
		display: block;
		margin-left: 20px;
	}
}

.list-with-panel {
	overflow: hidden;
}

.top-filter, .sort.table {
	ul.sections, ul.sort {
		a.active {
			i.sort {
				display: inline-block;
			}
		}

		.fa-spinner, i.sort {
			display: inline-block;
			width: 30px;
			text-align: center;
			margin-right: -10px;
			display: none;
			color: #ccc;
		}

		a.active.updating {
			i.sort {
				display: none;
			}
		}

		a.updating {
			.fa-spinner {
				display: inline-block;
				color: #ccc;
			}
		}
	}
}

.top-filter {
	z-index: 1;
	margin-bottom: 20px;
	position: relative;

	.sort-label {
		float: left;
		display: block;
		padding: 3px 8px;
		background: rgba(250, 250, 250, 0.65);
		border-radius: 5px;
		margin: 6px 0px 4px 20px;
		font-size: 13px;
		font-weight: 500;
		color: #aaa;
	}

	&:before {
		content: '';
		top: 17px;
		height: 1px;
		position: absolute;
		width: 100%;
		z-index: -1;
		background: #efefef;
	}

	.ul.order {
		float: left;
	}

	ul.sort {
		float: right;
		z-index: 10000;
		margin-right: 20px;
		margin-top: 1px;

		li a, li span.button {
			font-size: 13px;
			font-weight: 400;
			padding: 5px 10px;
		}
	}
}

.console-label {
	color: #aaa;
	font-weight: 500;
	line-height: 20px;
	padding-right: 10px;
	display: inline-block;
}

.infinite-paginator {
	text-align: center;
	margin-top: 40px;

	li .blank {
		cursor: auto;
	}

	li a i {
		&.fa-spinner {
			font-size: 16px;
			color: #e0cd22;
		}
	}

	li:first-of-type {
		> a, span {
			border-left: 1px solid #efefef;
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
		}
	}

	li:last-of-type {
		> a, span {
			border-right: 1px solid #efefef;
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
		}
	}

	a {
		text-align: center;
		min-width: 100px;

		.fa-arrow-circle-left {
			font-size: 20px;
			margin-right: 10px;
		}

		.fa-arrow-circle-right {
			font-size: 20px;
			margin-left: 10px;
		}
	}
}

.top-filter .infinite-paginator {
	text-align: left;
	margin-left: 20px;
}

.cover {
	position: absolute;
	height: calc(100% + 40px);
	width: calc(100% + 40px);
	padding-top: 50px;
	margin: -20px;
	z-index: 1000000;
	color: #ddd;
	text-align: center;
	vertical-align: middle;
	background: #fff;
	opacity: 0.8;
}


.task-preview {
	padding-top: 10px;

	.overview {
		margin-left: 60px;
		margin-bottom: 10px;
		color: #aaa;
	}

	h2 {
		margin: 10px 0;
		color: $blue-etc;
		letter-spacing: 0;
		font-weight: 400;
		font-size: 22px;
		/*
		background: #fafafa;
		margin: -10px -10px 10px -10px;
		padding: 10px;
		border-bottom: 1px solid #efefef;
		*/
	}
}

.search-wrapper {
	input {
		border: 2px solid #aad49f;
	}

	&:before {
		color: #387028;
	}
}

.search-wrapper {
	margin-right: -20px;
	float: right;

	input {
		border-color: $green;
		margin-top: -5px;
	}

	&:before {
		top: 3px;
		color: #70a361;
	}
}

i.fa-stack-1x {
	color: white;
}


.sort.table {
	margin: 0;
	padding: 5px 10px;
	width: calc(100% - 20px);
	background: #f6f6f6;

	.match-count {
		font-weight: 500;
		text-align: right;
		font-size: 13px;
		color: #aaa;

		strong {
			font-weight: 600;
		}
	}
}

.filters {
	padding: 5px 10px;

	input[type='text'], textarea, input[type='password'], input[type='email'], .public-DraftEditor-content, select {
		&:disabled {
			background: #efefef;
		}
	}

	&.gray {
		background: #fafafa;
		border-top: 1px solid #f2f2f2;
		border-bottom: 1px solid #f2f2f2;
	}
}

.email-provider-logos {
	width: 28%;
	float: right;
	padding: 1%;

	img {
		max-height: 24px;
		background: #efefef;
		padding: 5px;
		border-radius: 5px;
		margin: 0 5px;
	}
}

div.submit {
	text-align: center;
	margin-top: 10px;
	margin-bottom: -10px;
}

.imap-settings, .connect-account {
	position: relative;
	background: #fafafa;
	border-top: 4px solid #efefef;
	padding: 10px;
	color: #444;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	margin: 0 -20px -20px -20px;

	&:after, &:before {
			bottom: 100%;
			border: solid transparent;
			content: ' ';
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
	}

	&:after {
			border-bottom-color: #fafafa;
			border-width: 19px;
			left: 50%;
			margin-left: -19px;
	}
	&:before {
			border-bottom-color: #efefef;
			border-width: 20px;
			left: 50%;
			margin-left: -20px;
			z-index: 1000;
	}

	&.with-heading {
		&:after {
			border-bottom-color: #efefef;
		}
	}

	&.connect-account {
		padding: 20px;
		padding-top: 0;
	}
}

.connect-icon {
	max-height: 20px;
	margin: 0 2px;
}

.bio-link, .new-task-link {
	color: #bbb;
	padding: 5px;
	display: inline-block;
	margin: 0;
	margin-left: -5px;
	border-radius: 5px;

	&:hover, &:active {
		color: #e0cd22;
		background: #fffcd7;
		cursor: pointer;
	}
}

.contact-emails .email.table {
	margin-left: -10px;
	margin-right: -20px;
	margin-top: -5px;
	padding: 0;
	border: 0;
	border-top: 1px solid #efefef;

	.email-row {
		.from {
			display: none;
		}

		.subject {
			width: 70%;
		}

		.last-date {
			width: 30%;
		}
	}

	ul li.block {
		padding: 0;

		&:hover, &:active {
			background: #f5f9ff;
		}

		a {
			margin: 0;
			padding: 0;
		}

		&:first-of-type:hover, &:first-of-type:active {
			border-top-radius: 10px;
		}
	}
}

.content-block li a.block {
	font-size: 13px;
	padding: 1px;
}

.content-block .block {
	font-size: 13px;
	line-height: 15px;
}




.example-enter {
	visibility: visible;
	/*
	max-height: 500px;
	transition: max-height 500ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
	*/
}

.example-exit {
	visibility: hidden;
	/*
	overflow: hiddden;
	max-height: 0;
	transition: max-height 500ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
	*/
}



.content-block.inline {
	position: relative;
}

.dashboard {
	padding: 0;

	.left-column, .middle-column, .right-column {
		box-sizing: border-box;
	}

	.left-column {
		float: left;
		width: 22%;
		border-right: 2px solid #efefef;
	}

	.middle-column {
		float: left;
		width: 56%;
		padding: 20px;
	}

	.right-column {
		float: left;
		width: 22%;
		padding: 10px;
		background: #f5f9ff;
		border-left: 1px solid #e0ecff;
	}
}

/*
.email-body {
	margin-left: 40px;
}

.email-reply:nth-of-type(odd) {
	background: #fafafa;
	margin: 20px -20px;
	padding: 20px;
}
*/
.file-preview img {
	max-width: 100%;
	border-radius: 5px;
	border: 2px solid #efefef;
	padding: 3px;
}




.profile-photo-img {
	margin: 0;
	border-radius: 100px;
	width: 100px;
	border: 1px solid #ccc;
	box-shadow: 2px 0 2px 0 aliceblue;
}


.main.left-padded {
	.new-header, #content {
		position: static;
		padding-left: 250px;
	}
}

h3.section-heading {
	font-size: 16px;
	/*color: $blue-etc;*/

	i {
		color: $blue-etc;
		margin-right: 10px;
	}

	&.block {
		/*background: #f2f2f2;
		background: #efefef;*/
	}

	.email-counts {
		float: right;
		font-size: 14px;
		color: #aaa;
		font-weight: 500;
		/*border-bottom: 1px solid #f2f2f2;*/
		background: #f9f9f9;
		border-radius: 50px;
		padding: 3px 12px;
		margin-top: -3px;

		.fa {
			color: #ccc;
			margin-right: 0;
			margin: 0 0 0 5px;
		}

		a {
			text-decoration: none;
			padding: 3px;
			border-radius: 3px;
		}

		.count-set {
			margin-left: 10px;

			&:first-of-type {
				margin-left: 0;
			}
		}
	}
}


.progress-bar-appear {
	.small-progress {
		 width: 0%;
	 }
}

.progress-bar-appear.progress-bar-appear-active {
	.small-progress {
		 width: 0%;
	 }
	transition: opacity .5s ease-in;
}

.tag-list, .project-list {
	padding: 10px 10px 10px 40px;
	margin: 0;
	border-bottom: 2px dotted #e0ecff;

	.action {
		line-height: 19px;
	}

	&:before {
		content: '\f02c';
		text-align: center;
		width: 40px;
		margin-left: -40px;
		font-size: 1.5em;
		color: #e6e6e6;
		display: inline-block;
		font-family: 'Font Awesome 5 Pro';
	}

	&.empty {
		&:before {
			color: #ccc;
		}
	}

	.highlighted {
		display: inline-block;
	}
}

.project-list:before {
	content: '\f0b1';
}

.sign-out {
	float: right;
	font-size: 13px;
	font-weight: 500;

	i {
		font-size: 1.5em;
		margin-top: 2px;
		margin-right: 5px;
	}
}

.settings-panel {
	display: table-cell;
	padding: 20px;

	.table.full-width {
		margin-left: -10px;
		margin-right: -10px;
		width: calc(100% + 20px);
	}
}

.subheading i, h2 i {
	line-height: 24px;
	color: $blue-etc;
	font-size: 24px;

	 &.edit {
	 	color: $gold;
	 }
}

h2 {
	/*color: $green;*/
	color: #555;
	padding: 10px;
	font-size: 17px;
	line-height: 24px;

	&.zero {
		color: #aaa;

		i {
			color: #ccc;
		}
	}
}

.temporary-message {
	position: absolute;
	padding: 10px 20px;
	right: 0;
	background: #e6e6e6;
	z-index: 10000000;
	top: 15px;
	font-size: 14px;
	color: #888;
	font-weight: 500;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;

	i {
		color: #bbb;
		margin-left: -5px;
		margin-right: 10px;
		font-size: 1.3em;
	}

	&.loading {
		background: #e0cd22;
		color: white;
	}

	&.complete {
		background: #67a157;
		color: white;

		i {
			color: #c5e3bb;
		}
	}
}

.contact-emails .wide.email.table.full-width {
	margin: 0 -20px;
}




.sub-record {
	background: #f9fcff;
	border-top: 1px solid #f2f8ff;
	border-bottom: 1px solid #f2f8ff;
	margin: 20px -20px -20px -20px;
	padding: 0 20px 10px 20px;

	.badge {
		display: inline-block;
		color: $blue-etc;
		margin: 0 0 0 -20px;
		font-size: 13px;
		font-weight: 500;
		background: #f2f8ff;
		border-bottom-right-radius: 5px;
		padding: 6px 20px;

		.fa {
			color: #bacff4;
			margin-right: 10px;
		}
	}

	h4 {
		margin: 10px 0;
	}
}



.stats {
	/*float: right;*/
	margin: -00px auto 20px auto;
	display: table;

	.stats-row {
		display: table-row;
	}

	.stats-cell {
		display: table-cell;
		padding: 0 10px;
		text-align: center;

		&.zero {
			.value {
				color: #ccc;
			}

			.field {
				background: #f2f2f2;
				color: #aaa;
				font-weight: normal;
			}
		}
	}

	.field {
		color: #999;
		padding: 3px 10px;
		border-radius: 6px;
		font-size: 12px;
		font-weight: 600;
	}

	.value {
		color: $blue-etc;
		font-size: 28px;
		padding-bottom: 10px;
	}
}

.search-wrapper {
	position: relative;
	margin-right: -30px;
	background: #1b2a48;
	float: none;
	display: block;

	input {
		width: 200px;
		border-color: #9aa9c6;
	}

	&:before {
		font-family: 'Font Awesome 5 Pro';
		font-size: 16px;
		color: #99b1d3;
		content: '\f002';
		z-index: 1000;
		position: absolute;
		top: 0;
		margin-right: 30px;
		left: 8px;
	}
}

ul.email.table {
	margin: -20px;

	.file-icon .fa {
		font-size: 1.5em;
	}
}

.star-container {
	display: inline-block;
}

.star  {
	.fa-star {
		color: #e0cd22;
		-webkit-text-stroke: 1px #e0cd22;
		font-size: 1.1em;
		margin-right: 3px;
	}

	&.empty {
		visibility: hidden;

		.fa-star {
			cursor: pointer;
			color: white;
			-webkit-text-stroke: 1px #ccc;
		}
	}
}

.table.snapshot-files, .table.snapshot-emails, .table.projects {
	margin: 20px 0;

	.file-icon {
		float: left;
		margin-right: 20px;
		margin-top: -3px;
		font-size: 3em;
	}

	li {
		border-bottom: 1px solid #f2f2f2;

		a {
			padding: 10px;
			display: block;
			border: 1px solid transparent;

			&:hover, &:active {
				margin: -1px 0;
				padding: 11px 10px;
				border: 1px solid #f5f9ff;
			}
		}
	}

	.action-icons {
		margin-right: 0;
	}
}

.new-email.table {
	textarea {
		width: 100%;
	}
}

.last-viewed-record {
	padding: 5px 10px;
	margin: 0 -10px;
	border-radius: 5px;
	display: block;
	color: #777;

	&:hover, &:active {
		background: #f2f2f2;
		color: #666;

		i {
			color: #aaa;
		}
	}

	i {
		font-size: 1.2em;
		color: #ccc;
		margin-right: 8px;
		margin-top: 2px;
		float: left;
	}

	.name {
		font-weight: 500;
		display: block;
		word-break: break-word;
		margin-left: 20px;
	}
}

.snippet {
	color: #aaa;
	display: block;
	line-height: 16px;
	max-height: 32px;
	overflow: hidden;
	font-size: 13px;
}

.table.snapshot-emails {
	margin-bottom: 0;

	.from {
		color: #666;
	}

	.date {
		margin: 0;
		font-size: 13px;
		font-weight: normal;
		float: right;
	}

	.subject {
		font-weight: 500;
		margin: 3px 0;
		color: #666;
	}

	a.block.unread {
		.subject, .from {
			color: $blue-etc;
		}

		.from {
			&:before {
				content: '\f111';
				font-family: 'Font Awesome 5 Pro';
				font-size: .8em;
				margin-top: -38px;
				display: inline-block;
				margin-right: 5px;
			}
		}
	}

	a.block.flagged {
		.from {
			&:before {
				content: '\f024';
				font-family: 'Font Awesome 5 Pro';
				font-size: .8em;
				color: firebrick;
				margin-top: -38px;
				display: inline-block;
				margin-right: 5px;
			}
		}
	}
}

.note {
	margin: 10px -20px;
	background: none;
	border: none;
	padding: 10px;
	border-top: 1px solid #fafafa;
	border-bottom: 1px solid #fafafa;
	font-size: 15px;
	color: #549442;

	.about {
		background: #fff4c5;
		display: none;
		font-size: 12px;
		color: #a99c61;
		padding: 5px;
	}
}


.more {
	color: #666;
	font-weight: 500;
}

.content-block.white .block {
	padding: 8px 10px;
}

.subheading .button-icons {
	background: none;
	border: 0;
	margin-right: 0;
	margin-top: 2px;

	.close {
		cursor: pointer;

		i {
			color: firebrick;
		}
	}
}

.no-results, .blank-slate, .email-sent, .email-sending, .loading, .unpreviewable, .enter-search-query, .empty-congrats, .congrats, .app-error {
	text-align: center;

	&.empty-congrats, &.congrats {
		h2 {
			color: $green;
		}

		i {
			color: #f1e76b;
		}
	}

	&.unpreviewable, &.app-error {
		h2 {
			color: #888;
		}
		i {
			color: #ffd5d5;
			margin: 0;
		}
	}

	&.loading i {
		color: #e0ecff;
	}

	i {
		color: #eaeaea;
		font-size: 10em;
		margin: 20px;
	}

	h2 {
		color: #aaa;
		font-weight: 400;
		margin-top: 20px;
		line-height: 32px;
		font-size: 24px;
	}

	p {
		font-size: 16px;
		margin-top: 20px;
		line-height: 22px;
		margin-bottom: 20px;
		color: #aaa;
	}
}

.main-form .loading {
	padding-bottom: 40px;

	p {
		margin-bottom: 0;
	}
}

.loading.full-page {
	i {
		color: #86a8d6;
		margin-top: 200px;
	}
}

.email-sent {
	margin-bottom: 0px;
	padding: 20px 0 10px 0;

	h2, i {
		color: $green;
	}

	h2 {
		padding-bottom: 10px;
		font-weight: 500;
		letter-spacing: -0.4px;
	}
}

.file-icon {
	text-align: center;
	color: $blue-etc;
}

.file-icon, .slice.subheading {
	h2 {
		color: #555;
	}

	i.page-icon {
		color: $blue-etc;
	}

	&.unread h2 i {
		color: $green;
	}

	&.flagged h2 i, &.top h2 i {
		color: firebrick;
	}

	&.snoozed h2 i.fa-clock-o, &.starred h2 i.fa-star {
		color: #e0cd22;
		-webkit-text-stroke: 0;
	}

	&.excel, &.spreadsheet, &.spreadsheets h2 i {
		color: $green;
	}

	&.presentations h2 i, &.presentation {
		color: #d14424;
	}

	&.pdf, &.pdfs h2 i {
		color: #f69f9f;
	}

	&.archive {
		color: #bbb;
	}

	&.large i {
		display: block;
		font-size: 20em;
		line-height: auto;
	}
}

.project-links {
	list-style-type: none;
	margin: 0;
	padding: 0;
	margin-top: 10px;

	li {
		margin: 4px 0;
		padding: 0;

		a {
			padding-right: 22px;
			margin-right: -22px;
			display: block;
			padding: 10px;
			color: #aaa;
			font-weight: 500;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
			border: 2px solid transparent;
			font-size: 15px;
			/*margin-left: -22px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;*/

			i {
				color: #ccc;
				display: inline-block;
				width: 30px;
				font-size: 1.2em;
				text-align: center;
				margin-right: 10px;
			}

			&:hover, &:active {
				background: #f6f6f6;
				border-color: #fafafa;
				border-right-color: #eaeaea;
			}

			&.active {
				border-color: #efefef;
				background: white;
				border-right-color: white;
				color: $green;

				i {
					color: $green;
				}
			}
		}
	}
}

.show-more {
	display: none;
	color: $green;

	i {
		color: $green;
	}
}

.info-panel {
	h2 {
		padding: 0;
		word-break: break-word;
	}
}

.subheading .close {
	width: 30px;
	text-align: center;

	i {
		cursor: pointer;
		color: #ccc;
	}

	&:hover, &:active {
		i {
			color: firebrick;
		}
	}
}

.info-box {
	background: #f4f4f4;
	padding: 10px;
	border-radius: 0;
	margin: 10px -20px;
	border: 0;

	.table.full-width {
		margin-left: -10px;
		margin-right: -10px;
		width: calc(100% + 20px);
	}

	.heading {
		margin: 0;
		width: auto;
		margin-top: -10px;
		text-align: center;
		font-weight: 500;
		background: none;
	}
}

.associations {
	.tag-list, .project-list {
		border-bottom: 1px solid #f2f2f2;

		&:last-of-type {
			border-bottom: 0;
		}
	}
}

.highlighted {
	color: #aaa;
	background: #fafafa;
	padding: 5px 10px;
	border-radius: 6px;

	i {
		color: #999;
		margin-right: 10px;
	}

	&:hover, &:active {
		background: #efefef;
	}
}

.cc {
	font-size: 13px;
	color: #aaa;
	margin-left: 5px;

	span {
		cursor: pointer;
		padding: 3px 6px;
		border-radius: 3px;

		i {
			margin-right: 3px;
			color: #ccc;
		}

		&:hover, &:active {
			background: #efefef;
		}
	}
}

.attachments {
	margin-left: 40px;
	border-left: 2px solid #f2f2f2;
	padding: 5px 10px;
	font-size: 13px;
	color: #999;
	margin-bottom: 10px;

	strong {
		font-weight: 500;
	}

	i {
		color: #ccc;
		margin-right: 10px;
	}
}

.attachment-list, .attachments {
	font-weight: 400;

	a {
		color: #666;
		font-weight: 500;

		&:hover, &:active {
			color: $blue-etc;
		}
	}

	.comma-section .post-comma {
		&:after {
			color: #aaa;
			padding-right: 5px;
		}
	}

	.fa-download {
		margin: 0;
		margin-left: 5px;
		width: auto;
		padding: 0;
		color: #ccc;

		&:hover, &:active {
			color: $blue-etc;
		}
	}
}

.user-finder.inverted {
	.recipient-list .recipient {
		background: #fafafa;
	}
}

.tiny-avatar {
	display: inline-block;
	width: 25px;
	margin-right: 5px;
	height: 25px;
	border-radius: 300px;
	overflow: hidden;
	margin-bottom: -8px;
	margin-top: -5px;
	margin-left: -4px;

	img {
		height: 25px;
		width: 25px;
	}
}

.draft-attachment {
	line-height: 20px;

	.size {
		color: #aaa;
	}

	i {
		color: #ccc;
		margin-right: 5px;
	}
}

.upload-progress {
	float: right;
	width: 20%;
	margin-top: 3px;
}

.upload-icon {
	float: right;
	font-size: 1.2em;

	&.success i {
		color: $green;
	}

	&.error i {
		color: #e0cd22;
	}

	i.remove {
		color: firebrick;
	}

	&.removable {
		cursor: pointer;

		.remove {
			display: none;
		}

		&:hover, &:active {
			.status {
				display: none;
			}

			.remove {
				display: inline;
			}
		}
	}
}

.load-drafts, .header-side-link {
	margin: 0 10px;
	background: #fafafa;
	padding: 5px 8px;
	margin-top: -4px;
	border-radius: 5px;
	font-size: 14px;
	color: #999;
	display: inline-block;
	cursor: pointer;
	text-decoration: none;
	/*margin-right: 15px;*/

	&.load-drafts {
		float: none;
		margin-top: 0;
	}

	&:hover, &:active, &.active {
		background: #f2f2f2;
	}

	&.unsubscribe {
		margin-top: 9px;
		background: #e0cd22;
		color: white;

		i {
			color: #fffcd7;
		}

		&:hover, &:active, &.active {
			background: #bead19;
		}
	}

	&.active-section {
		background: $green;
		color: white;

		i {
			color: white;
		}
	}

	i {
		font-size: 1.2em;
		color: #ccc;
		margin-right: 5px;
	}
}

.mini .header-side-link {
	font-size: 13px;
}

.draft-list {
	font-size: 13px;
	margin: -10px;
	text-align: left;
	max-height: 450px;
	overflow: scroll;
	display: block;

	li span.block {
		cursor: pointer;
		padding: 6px;
		line-height: 16px;
	}

	.subject {
		color: $blue-etc;
		font-weight: 500;
	}

	.last-update {
		color: #999;
		font-weight: 400;
	}

	.to {
		color: #999;
	}
}

.list-filter-container {
	display: inline-block;
	position: relative;
}

.loading-status {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		text-align: center;
		width: 33%;
	}

	.number-loaded {
		font-size: 40px;
		line-height: 52px;
		color: $green;
	}

	.type-loaded {
		color: #999;
		font-size: 16px;
		font-weight: 500;
		display: inline;
		margin-bottom: 10px;

		i {
			display: block;
			font-size: 1.5em;
			margin-bottom: 5px;
			color: #ddd;
		}
	}
}

.user-welcome i {
	font-size: 1.5em;
}

table.gray th {
	background: #f2f2f2;
}

#top-bar {
	display: none;
}

.button-icons.clear {
	background: none;
	border: 0;
	padding: 2px 0;
}

.button-icons.white {
	background: white;
	border: 1px solid #efefef;

}

.add-tag {
	background: #efefef;
	padding: 5px 10px;
	text-align: center;
	color: $green;
	font-weight: 500;
	margin: 10px -20px 0 -20px;

	input[type='text'] {
		margin-right: 10px;
	}
}

.split-header {
	background: #fafafa;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	padding: 20px;
	border-bottom: 1px solid #f2f2f2;
	overflow: visible;

	.button-icons {
		margin-top: -29px;
	}
}

i.enriched {
	margin-left: 10px;
	color: $green;
}

.sub-bar {
	background: #f5f9ff;
	padding: 10px 20px;
	margin: 0;
	border-bottom: 1px solid #e1ecff;
	color: $blue-etc;

	.pseudobutton {
		background: #fafafa;
		color: #bbb;
		display: inline-block;
		margin-top: 0;
		margin-left: 10px;
		border: 2px solid #eaeaea;
		font-weight: 500;
		padding: 6px 15px;
		border-radius: 50px;

		&:hover, &:active {
			background: #fff;
			color: $green;

			.fa {
				color: $green;
			}
		}

		.fa {
			color: #ddd;
			margin-right: 5px;
		}
	}

	&.warning {
		background: #ffd7d7;
		border: none;
		color: #c76565;
	}

	.highlighted {
		background: #e4ebf3;
		color: $blue-etc;

		&:hover, &:active {
			background: #d8e2ee;
		}
	}

	&.gray {
		background: #f2f2f2;
		border: 0;
		color: #999;
		padding-left: 20px;
		font-weight: 500;
	}

	&.dark-blue {
		background: #e1ecff;
		border-bottom: 1px solid #e4ebf3;
	}

	&.blue {
		background: aliceblue;
		border-bottom: 1px solid #e4ebf3;
	}

	&.light-gray {
		background: #fafafa;
		border-bottom: 1px solid #efefef;
	}

	.tag-list {
		padding: 0;
		margin: 0;

		&:before {
			content: '';
		}
	}
}

.flat.tertiary.button {
	float: none;
	margin: 0;
	margin-left: 10px;
	font-size: 12px;
	border-radius: 4px;
	background: #f2f2f2;
	padding: 3px 8px;
	border: none;
	color: #aaa;

	&:hover, &:active {
		background: #efefef;
	}
}

.mailing-list-frequency {
	text-align: right;

	i {
		margin-left: 3px;
	}
}

.top-threads {
	.thread {
		display: block;
		padding: 10px;
		color: #666;
		position: relative;
		margin-top: 3px;

		&:after {
			content: '';
			width: 100%;
			bottom: 0;
			height: 3px;
			background: white;
			left: 0;
			position: absolute;
			display: block;
			border-bottom: 1px solid #efefef;
		}

		.date, h3 {
			margin: 0;
		}

		p {
			color: #888;
		}

		h3 {
			font-weight: 500;
		}

		&:hover, &:active {
			border-radius: 6px;
			background: aliceblue;
		}
	}
}

.left-side-panel .full-width {
	margin-left: -10px;
	margin-right: -10px;
	width: calc(100% + 20px);
}

h3 .count {
	margin-right: 10px;
}

.section-header {
	background: #fafafa;
	border: 1px solid #f2f2f2;
	border-radius: 4px;
	padding: 5px 10px;

	h3 {
		margin: 0;
		padding: 0;
	}
}

.with-tags.subheading {
	.tag-list {
		margin-top: -20px;
		font-size: 13px;
		margin-left: 80px;
		font-weight: 500;
		color: #aaa;
		padding-left: 0;

		a.highlighted {
			border: 2px solid #efefef;
			border-radius: 6px;
			background: #fafafa;

			&:hover, &:active {
				background: white;
			}
		}

		&:before {
			content: '';
			font-size: 1em;
		}
	}
}

.inline {
	display: inline;
}

.sidebar-search {
	z-index: 10;
	position: absolute;
	left: 70px;
	bottom: 73px;
	background: white;
	border-radius: 6px;
	width: 300px;
	border: 1px solid #ccc;
	box-shadow: 0 3px 15px rgba(43, 70, 110, 0.25);

	.results {
		max-height: 500px;
		overflow: scroll;

		.collection-divider {
			background: #efefef;
			padding: 5px 10px;
			color: #999;
			font-size: 13px;
			font-weight: 600;

			a {
				display: inline;
			}

			&.empty {
				color: #bbb;
				font-weight: 500;

				i {
					color: #ccc;
				}

				.count {
					background: #ddd;
				}
			}

			i {
				margin-right: 10px;
				font-size: 15px;
				width: 15px;
				text-align: center;
				color: #8cbf7e;
			}
		}

		a {
			display: block;
			padding: 5px 10px;
			border-bottom: 1px solid #efefef;
			font-size: 13px;
			font-weight: 500;

			&:last-of-type {
				border-bottom: none;
			}
		}
	}

	.input {
		background: #fafafa;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		padding: 8px;
		position: relative;
		border-bottom: 1px solid #efefef;

		input {
			width: 100%;
			box-sizing: border-box;;
		}

		&.processing {
			.fa-spinner {
				display: inline-block;
			}
		}

		.fa-spinner {
			display: none;
			font-size: 1.5em;
			position: absolute;
			right: 11px;
			top: 14px;
			color: #e0cd22;
		}
	}
}

.all-results-bar {
	text-align: center;
	margin: 10px -10px -10px -10px;
	padding: 10px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.heading {
	margin-bottom: 10px;
}

.smart-filter-section {
	display: none;
}

.email.table {
	width: calc(100% + 40px);
	margin: -20px;
	display: table;

	.row {
		display: table-row;

		&:hover, &:active {
			background: #f5f9ff;
		}

		.etc {
			font-size: 13px;
		}

		.cell {
			padding: 10px;
		}

		.date {
			font-size: 13px;
			margin-top: 0;
		}

		.actions {
			width: 20%;
			text-align: right;

			i {
				font-size: 1.5em;
				margin: 2px 5px 0 5px;
			}
		}

		.file-icon {
			display: table-cell;
			width: 6%;
			vertical-align: middle;
			text-align: center;
			font-size: 2em;
		}
	}
}

.sidebar-filter {
	display: block;
	color: #999;
	padding: 6px 8px;
	border-radius: 6px;
	font-weight: 500;

	&:hover, &:active {
		background: #efefef;
	}

	i {
		width: 30px;
		text-align: center;
		font-size: 1.3em;
	}
}

.search-drawer-wrapper {
	margin-bottom: 20px;
	margin: -20px -20px 20px -20px;
	padding: 10px;
	background: none;
	border-bottom: none;
}

.record-stats-bar {
	background: #f5f9ff;
	margin: 0;
	border-bottom: 1px solid #e0ecff;
	border-top: 1px solid #e0ecff;
	color: $blue-etc;
	font-weight: 600;

	&.gray {
		background: #fafafa;
		border-top-color: #f2f2f2;
		border-bottom-color: #f2f2f2;
		color: #aaa;

		.info-value span {
			background: #efefef;
		}

		i {
			color: #ccc;
		}
	}

	> div {
		display: table;
		table-layout: fixed;
		width: 100%;

		> div {
			display: table-cell;
			text-align: center;
			padding: 10px;
		}
	}

	.info-label {
		margin-bottom: 3px;
		margin-top: -2px;
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 400;
	}

	.info-value {
		display: inline-block;

		span {
			background: #e0ecff;
			padding: 3px 8px;
			border-radius: 6px;
		}

		i {
			font-size: 1.3em;
			margin-right: 10px;
		}

		&.file-type::first-letter {
			text-transform: capitalize;
		}
	}

	i {
		color: #b8cfef;
	}

	.icons {
		display: block;
		margin-bottom: 10px;

		i {
			margin: 0 5px;
			font-size: 2em;
			display: inline-block;
		}
	}
}

.capitalize {
	text-transform: capitalize;
}

.activity-graph {
	margin: -20px;
	background: #f2ffef;
	position: relative;
	border-top: 1px solid #eaeaea;
	padding-top: 10px;

}

.date-label {
	display: inline-block;
	border-radius: 6px;
	font-weight: 500;
	font-size: 15px;
	background: $green;
	background: white;
	/*border: 1px solid #efefef;*/
	z-index: 1000000;
	color: $green;

	.inline-label {
		font-size: 13px;
		font-weight: 400;
		color: #aaa;
	}

	i {
		margin-left: 5px;
	}
}

.right-aligned .date-label i {
	margin-left: 0;
	margin-right: 5px;
}

.subheading .date-label {
	margin-left: 0;
	position: absolute;
	font-size: 13px;
	right: 10px;
	bottom: 10px;
	border: 0;
	color: #aaa;
	opacity: 0.4px;
}

h3.feed-heading {
	margin: 0;
	margin-bottom: 10px;
	color: #aaa;

	i {
		color: #ccc;
	}
}

.employer-tags {
	color: #aaa;
	font-size: 12px;
	margin-top: 10px;
	margin-left: 20px;
	position: relative;

	&:before {
		position: absolute;
		left: -20px;
		content: '\f02c';
		font-family: 'Font Awesome 5 Pro';
		color: #ccc;
	}
}

.filter-button {
	display: inline-block;
	border: 1px solid #efefef;
	background: #fafafa;
	border-color: transparent;
	border-radius: 6px;
	padding: 6px 12px;
	color: #aaa;
	font-weight: 500;
	cursor: pointer;

	i {
		font-size: 1.2em;
		margin-right: 5px;
	}

	&.active {
		background: #f2f2f2;
		border-color: #efefef;
		color: $green;

		&.starred {
			color: #e0cd22;

			i {
				color: #fffcd7;
				-webkit-text-stroke: 1px #e0cd22;
			}
		}

		&.attachment {
			color: $green;
		}

		&.flagged {
			color: firebrick;
		}
	}
}

.new-social-profile {
	display: block;
	background: aliceblue;
	background: white;
	border-radius: 6px;
	border: 1px solid #f2f2f2;
	position: relative;
	margin: 0 10px 0 20px;
	margin-bottom: 20px;

	.handle {
		font-weight: 600;
		padding: 8px 5px 8px 20px;
		background: #f2f2f2;
		border-top-right-radius: 6px;
		border-bottom: 1px solid #f2f2f2;

		&.standalone {
			border-bottom-right-radius: 6px;
			border-bottom: 0;
		}

		&:after {
			content: '\f061';
			font-family: 'Font Awesome 5 Pro';
			color: #ccc;
			font-size: 1.3em;
			display: inline-block;
			position: absolute;
			right: 10px;
			top: 9px;
			pointer-events: none;
		}
	}

	.network {
		color: #aaa;
		font-weight: 400;
		font-size: 11px;
		text-transform: uppercase;
		display: none;
	}

	i {
		position: absolute;
		left: -20px;
		top: -10px;
		top: -1px;
		background: aliceblue;
		background: #f2f2f2;
		line-height: 16px;
		padding: 10px;
		border-radius: 50px;
	}

	&:hover, &:active {
		border: 1px solid #e0ecff;

		.handle {
			background: #f5f9ff;
			border-color: #e0ecff;

			&:after {
				color: #e0cd22;
			}
		}

		i {
			background: #e0ecff;
		}
	}
}

.social.table {
	color: #999;
	border-spacing: 10px;

	.data {
		display: table-cell;
		text-align: right;
		padding-right: 10px;
		font-size: 16px;
	}

	.data-label {
		display: table-cell;
		font-size: 13px;
		text-align: left;
		color: #ccc;
		font-weight: 500;
	}
}

.message-frame {
	width: 100%;
	height: auto;
	border: none;
	overflow: hidden;

	table {
		width: auto;
		border-collapse: auto;
	}
}

.gmail_extra {
	color: #aaa;
}

.queue-settings, .broadcast-settings {
	color: #888;
	font-weight: 500;

	.setting-info {
		width: 70%;
		font-size: 15px;
	}

	.toggle-icon, .icon {
		vertical-align: top;
	}

	.toggle-icon i {
		&:before {
			content: "\f111";
			font-family: 'Font Awesome 5 Pro';
		}
	}

	.selected {
		color: $green;
		background: rgba(197, 234, 184, 0.3);
		background: #f2f2f2;
		font-weight: 600;

		.toggle-icon i {
			&:before {
				content: "\f058";
				color: $green;
			}
		}

		.icon {
			color: $green;
		}
	}

	td {
		border-bottom: 1px solid #efefef;

		&.inactive {
			color: #aaa;

			strong {
				font-weight: normal;
			}
		}
	}

	.user-finder {
		display: none;
		margin-top: 10px;
	}

	td.value {
		text-align: center;
	}


	.icon {
		color: $green;
		font-size: 1.5em;
		width: 30px;
		text-align: right;

		i {
			width: 20px;
			/*background: #f2f2f2;*/
			line-height: 30px;
			border-radius: 10px;
		}
	}

	.toggle-icon {
		color: #ddd;
		font-size: 1.9em;
		text-align: center;

		i {
			cursor: pointer;
			width: 30px;
			line-height: 30px;
			border-radius: 10px;
		}
	}
}

.broadcast-settings {
	tr {
		cursor: pointer;
	}

	.selected {
		.user-finder {
			display: block;
		}
	}

	.setting-info {
		width: 83%;
	}

	.icon {
		font-size: 2em;
		width: 12%;
		text-align: center;

		i {
			width: auto;
		}
	}
}

.single-page-subheading, .inline-heading {
	line-height: 22px;
	padding: 20px;
	background: linear-gradient(to bottom, #e8f4ff, white);
	/*background: linear-gradient(to bottom, white, #e8f4ff);*/
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	font-size: 20px;
	font-weight: 500;
	color: #555;
	margin-bottom: -10px;
	/*color: $blue-etc;*/

	i {
		margin-right: 10px;
		font-size: 1.2em;
		color: $blue-etc;
		color: #c3d7f1;
	}
}

.inline-heading {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	margin: -10px -10px 0px -10px;
}

.agenda-bar {
	padding: 10px 20px;
	background: #e8f4ff;
	border-top: 1px solid #e8f4ff;
	border-bottom: 1px solid #e8f4ff;
	background: #fafafa;
	border-color: #f2f2f2;
}

.agenda {
	margin: 10px 0;
	border-collapse:separate;
	border-spacing: 0 5px;

	.item {
		display: flex;
		color: #666;
		padding: 10px;
		border: 1px solid transparent;
		border-radius: 6px;

		&:hover, &:active {
			border-color: #f5f9ff;
			background: #fafafa;
			color: $blue-etc;
		}
	}

	.main-info {
		flex: 1;
		border: 1px solid #f2f2f2;
		border-right: 0;
		border-color: transparent;
	}

	.icon-indicator {
		text-align: center;
		color: #ddd;
		font-size: 3em;
		padding: 10px 20px 10px 10px;
	}

	.action-options {
		text-align: center;
		float: right;
		vertical-align: top;
		border-left: 0;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		border-color: transparent;

		i {
			font-size: 1.2em;
			padding: 5px;
			border: 1px solid #efefef;
			border-color: transparent;
			border-radius: 50px;
			margin: 0 5px;
			line-height: 20px;
			width: 20px;
			color: #ddd;
		}
	}

	.action-options {
		white-space: nowrap;
	}

	.from {
		font-weight: 700;
	}

	.date {
		margin: 0;
		font-size: 13px;
		font-weight: normal;
		float: right;
	}

	.subject {
		font-weight: 500;
		margin: 3px 0;
	}

	a.block.unread {
		.subject, .from {
			color: $blue-etc;
		}

		.from {
			&:before {
				content: '\f111';
				font-family: 'Font Awesome 5 Pro';
				font-size: .8em;
				margin-top: -38px;
				display: inline-block;
				margin-right: 5px;
			}
		}
	}

	a.block.flagged {
		.from {
			&:before {
				content: '\f024';
				font-family: 'Font Awesome 5 Pro';
				font-size: .8em;
				color: firebrick;
				margin-top: -38px;
				display: inline-block;
				margin-right: 5px;
			}
		}
	}
}

.section-switcher {
	background: white;
	border-radius: 6px;
	padding: 6px 10px;
	font-size: 13px;
	border: 1px solid #e8f4ff;
	font-weight: normal;
	margin-left: 20px;
	color: #999;
	box-shadow: 1px 0 1px 0 aliceblue;

	strong {
		color: #666;
		font-weight: 600;
	}

	&:hover, &:active {
		background: aliceblue;
		color: $blue-etc;
	}
}

.agenda a {
	display: table-row;
	margin-bottom: 5px;
}

a:hover .action-options i, a:active .action-options i {
	border-color: #efefef;
	background: #f2f2f2;
}

a:hover .action-options i:hover, a:active .action-options i:active {
	border-color: $green;
	background: $green;
	color: white;
}

h3.divider {
	position: relative;
	margin: 10px -10px;
	padding-left: 20px;
	overflow: hidden;
	line-height: 40px;

	strong {
		color: $blue-etc;
	}

	i {
		color: #c3d7f1;
	}

	span {
		display: inline-block;
		vertical-align: baseline;
		zoom: 1;
		display: inline;
		vertical-align: auto;
		position: relative;
		padding: 0 20px;
		background: #f1f8ff;
		border-radius: 6px;
		padding: 6px 15px;
		margin: 0 20px;
		margin: 0;
		color: #999;
		border: 1px solid #e0ecff;

		&:before, &:after {
			content: '';
			display: block;
			width: 1000px;
			position: absolute;
			top: 16px;
			border-top: 3px double #e0ecff;
		}

		&:before { right: 100%; }
		&:after { left: 100%; }
	}
}

.pseudobutton {
	background: #f2f2f2;
	padding: 5px 8px;
	border-radius: 5px;
	cursor: pointer;
	text-decoration: none;
	color: #aaa;

	&:hover, &:active {
		background: #efefef;
	}
}

.contact-card, .contact-row, .group-row {
	flex: 1;
	background: #fafafa;
	border: 1px solid #f2f2f2;
	border-radius: 5px;
	padding: 8px;
	box-sizing: border-box;
	margin: 5px;
	cursor: pointer;
	min-width: 200px;
	max-width: 300px;
	word-wrap: break-word;
	min-height: 58px;

	.name {
		background: white;
		margin: -8px -8px 8px -8px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		padding: 6px 10px;

		.first {
			font-size: 14px;
			line-height: 18px;
			font-weight: 500px;
		}

		.last {
			font-weight: 600;
			font-size: 16px;
			line-height: 23px;
			text-transform: uppercase;
		}
	}
/*
	.default-photo {
		width: 40px;
		height: 40px;
		margin-left: -50px;
	}

	.avatar-container {
		margin-left: -50px;

		.generic-avatar {
			font-size: 40px;
		}
	}
*/
	&:hover {
		background: #f6f6f6;
	}

	.action-icons {
		float: right;
	}

	.default-photo {
		box-shadow: 0 0 8px #efefef;
	}

	.stat {
		background: #efefef;
		padding: 2px 8px;
		color: #bbb;
		font-weight: 500;
		border-radius: 50px;
		font-size: 12px;
		display: inline-block;
		margin-right: 10px;

		i {
			margin-right: 5px;
			color: #ccc;
		}
	}
}

hr.dotted {
	background: none;
	border-top: 2px dotted #efefef;
	margin: 10px -20px;
}

.sub-sub-bar {
	background: #efefef;
	margin: -20px -20px 20px -20px;
	padding: 10px;
}

.section-background {
	text-align: center;
	margin: -20px -20px 20px -20px;
	padding: 5px;
	position: relative;
	background: #f2f5f9;

	&.blue {
		background: #dae9fd;
	}

	.sections {
		float: none;
		margin: 0;

		a {
			padding: 6px 18px;
		}
	}
}

.group-row {
	padding-left: 20px;
	margin: auto;
	width: auto;
	display: block;
	border-radius: 8px;

	.avatars {
		float: right;

		.default-photo {
			margin: 0 5px;
			display: inline-block;
		}
	}
}

.card-list {
	margin: -10px;

	.contact-card {
		box-sizing: border-box;
		margin: 10px;
		float: left;
		min-height: 92px;

		.default-photo {
			box-shadow: 0 0 2px 1px #ddd;
		}
	}

	@media (max-width: 700px) {
		.contact-card {
			width: calc(100% - 20px);
		}
	}
	@media (min-width: 700px) and (max-width: 1200px) {
		.contact-card {
			width: calc(50% - 20px);
		}

		&:nth-child(odd){
			clear: both;
		}
	}
	@media (min-width: 1200px) and (max-width: 1800px) {
		.contact-card {
			width: calc(33.3% - 20px);

			&:nth-child(3n+1){
				clear: both;
			}
		}
	}
}

.mobile-nav-bar {
	display: none;
}

.new-record-task {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 5px;

	> div {
		padding: 3px;
	}

	.assignment {
		flex: 1;
		min-width: 300px;
	}

	.due-date, .submit-container {

	}

	.due-date {
		display: inline-block;

		.styled-select, input {
			min-width: 160px;
			max-width: 200px;
		}
	}

	.submit-container {
		input {
			margin-top: 5px;
		}
	}
}

.email-sidebar-preview {
	margin: 0px -10px 10px -10px;
	background: #f2f2f2;
	background: white;
	box-shadow: 0 0 2px 1px #f6f6f6, 0 0 2px -1px #f6f6f6;
	border-top: 1px solid #f2f2f2;
	border-bottom: 1px solid #f2f2f2;

	td {
		border-bottom: 1px solid #f2f2f2;
		padding: 7px;

		&:first-of-type {
			padding-left: 10px;
		}

		&:last-of-type {
			padding-right: 10px;
		}
	}

	tr:last-of-type td {
		border-bottom: 0;
	}

	.subject {
		font-size: 15px;
		font-weight: 600;
	}

	.label {
		width: 60px;
		color: #999;

		&:after {
			content: ":";
		}
	}

	.value {
		color: #999;
		font-weight: 500;
	}
}

.light-heading.file-email-info {
	background: #f2f2f2;
	margin: 10px -10px 0 -10px;
	padding: 5px 15px;
}


.option-info {
	font-size: 13px;
	color: #888;
}


.settings-check {
	color: $green;
	font-size: 2em;
}

.badge {
	white-space: nowrap;
	padding: 8px;
	border-radius: 6px;
	background: #ddf5d5;;
	color: $green;
	font-weight: 600;
	margin-right: 10px;

	i {
		color: $green;
		margin-right: 10px;
	}
}

.main-form.gray.full-width.user-profile {
	margin: 0;
	border: 1px solid #efefef;
	border-radius: 6px;
	margin-left: 20%;
}

.profile-settings {
	.label {
		width: 30%;
		color: #999;
		vertical-align: top;
		padding-top: 20px;
	}

	.value {
		font-weight: 500;
		width: 70%;
	}
}

.inline.action.white {
	border: 0;
	white-space: nowrap;
	background: none;
	font-weight: 500;
	padding: 5px 15px;
	font-size: 14px;

	&:hover, &:active {
		background: #f6f6f6;
	}

	&.edit {
		color: #e0cd22;
		i {
			color: #e0cd22;
		}

		&:hover, &:active {
			background: #fffcd7;;
		}
	}

	&.delete, &.delete i {
		color: #e07b81;
	}

	i {
		float: none;
		margin: 0;
		margin-right: 8px;
	}
}

.activation-link {
	margin: -20px -10px 30px -10px;
	background: #fffcd7;
	padding: 10px;
	border-radius: 10px;
	border: 1px solid #f5efa5;

	input[type="text"] {
		box-sizing: border-box;
		margin-top: 5px;
		width: 100%;
		background: #fffeec;
		cursor: pointer;
		border: 2px dashed #f5efa5;
	}
}


.plan {
	background: #fafafa;
	padding: 10px;
	box-sizing: border-box;
	display: inline-block;
	position: relative;
	border: 1px solid #efefef;
	border-radius: 6px;
	box-shadow: 0 0 6px 2px #f9f9f9;

	.features {
		color: #999;

		.module {
			width: 60%;
			font-weight: 500;

			i {
				color: #ccc;
				margin-right: 10px;
				font-size: 20px;
				width: 30px;
				text-align: center;
			}
		}

		.value {
			font-weight: 600;
			width: 40%;

			.fine-print {
				font-weight: 400;
				font-size: 13px;
			}
		}
	}

	.description {
		display: block;
		font-weight: 500;
		border-top: 1px solid #efefef;
		border-bottom: 1px solid #efefef;
		margin: 0 -10px;
		padding: 5px 10px;
	}

	.pricing {
		font-size: 16px;
		color: #aaa;
		float: right;

		.dollars {
			font-size: 18px;
			font-weight: 600;
			color: $blue-etc;
		}

		.action {
			float: right;
		}
	}

	h4 {
		font-size: 20px;
		font-weight: 600;
		padding-left: 20px;
		margin-bottom: 10px;
		line-height: 32px;
		color: $blue-etc;

		i {
			font-size: 32px;
			vertical-align: middle;
			color: $green;
			margin-right: 20px;
		}
	}

	&.active {
		background: #fffcd7;
		border-color: #f5efa5;
		color: $green;
		box-shadow: 0 0 6px 2px #fffcd7;

		.description {
			color: #c7b50f;
			border-color: #f5efa5;
		}

		.pricing {
			padding-left: 100px;

			.dollars {
				color: $green;
			}
		}

		.features {
			color: $green;

			i {
				color: $green;
			}

			td {
				border-bottom-color: #f5efa5;
			}
		}

		.active-banner {
			display: none;
			background: $green;
			text-transform: uppercase;
			color: white;
			font-size: 13px;
			position: absolute;
			top: 20px;
			left: -20px;
			transform: rotate(-45deg);
			padding: 5px 10px;
			border-radius: 6px;
			font-weight: 500;
		}

		h4 {
			color: $green;

			i {
				color: $green;
			}
		}
	}
}

.broadcast-info {
	margin: 10px 0;
	padding: 7px 10px;
	border-radius: 6px;
	font-size: 13px;
	font-weight: 500;
	min-height: 40px;
	background: rgba(197, 234, 184, 0.28);
	padding-left: 50px;

	a {
		color: #72ad62;
	}

	.user-avatar {
		float: left;
		margin-left: -45px;
	}

	img {
		height: 40px;
		width: 40px;
		border-radius: 40px;
		margin: 0 10px 0 -40px;
		float: left;
	}
/*	background: #fffcd7;
	border: 1px solid #f5efa5;
	color: #c7b50f;
*/
}
.user-avatar {
	img {
		height: 40px;
		width: 40px;
		border-radius: 40px;
	}
}

.subscribed-user {
	font-weight: 500;
	display: block;
	padding: 5px 10px;
	padding-left: 60px;
	background: white;
	border: 1px solid #f2f2f2;
	border-radius: 6px;

	&:hover, &:active {
		background: aliceblue;
		border: 1px solid #e0ecff;
		text-decoration: none;
	}

	.name {
		font-size: 15px;
		line-height: 22px;
	}

	.user-avatar {
		float: left;
		margin-left: -50px;
	}
	.email-address {
		color: #aaa;
	}

	&.email-participant {
		margin-bottom: 4px;

		&:last-of-type {
			margin-bottom: 0;
		}

		.name {
			font-size: 13px;
		}
	}
}

.spotlight {
	background: #f5f9ff;
	margin: -10px -10px 10px -10px;
	padding: 10px;
	border-bottom: 1px solid #e0ecff;
	color: $blue-etc;
	font-weight: 600;
	text-align: center;

	input[type="text"] {
		font-size: 20px;
	}
}

.reply-cluster {
	&.unclustered {
		.uncluster {
			display: none;
		}
	}

	.uncluster {
		margin: 20px 10px;
		border: 1px solid #f2f2f2;
		border-radius: 6px;
		padding: 10px 20px;
		font-size: 14px;
		font-weight: 500;
		color: #aaa;
		background: #fcfcfc;
		cursor: pointer;

		&:before {
			content: "\f065";
			font-family: 'Font Awesome 5 Pro';
			margin-right: 20px;
			color: #efe894;
			font-size: 1.4em;
		}
	}

	.replies {
		display: none;
	}
}

.user-list {
	.user-overview {
		padding: 10px 20px;
		border-radius: 10px;
		min-height: 60px;
		margin-bottom: 10px;
		padding-left: 100px;
		background: #fafafa;

		h3 {
			font-size: 18px;
			color: #777;
			font-weight: 500;
			margin: 10px 0 5px 0;
		}

		.user-avatar {
			float: left;
			margin-left: -80px;

			img {
				width: 60px;
				height: 60px;
			}
		}
	}
}

.user-avatar {
	width: 60px;
	height: 60px;
	border-radius: 120px;
	overflow: hidden;

	&.inline {
		display: inline-block;
	}

	i.fa {
		font-size: 120px;
	}

	&.large {
		width: 120px;
		height: 120px;

		i.fa {
			font-size: 240px;
		}
	}

	&.small {
		width: 20px;
		height: 20px;

		i.fa {
			font-size: 40px;
		}
	}

	&.medium {
		width: 40px;
		height: 40px;

		i.fa {
			font-size: 80px;
		}
	}
}

.progress-indicator {
	font-size: 52px;

	* {
		box-sizing: border-box;
	}

	.pie-wrapper {
		position: relative;
		width: 1em;
		height: 1em;
		margin: 0px auto;

		&.over-50 {
			.right-side {
				transform: rotate(180deg);
			}

			.pie {
				clip: rect(auto, auto, auto, auto);
			}
		}

		.pie {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			clip: rect(0, 1em, 1em, 0.5em);
		}

		.half-circle {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			border: 0.3em solid $blue-etc;
			border-color: $green;
			border-radius: 90%;
			clip: rect(0em, 0.5em, 1em, 0em);
		}

		.right-side {
			border-width: 0.2em;
			transform: rotate(0deg);
		}

		.left-side {
			border-width: 0.2em;
		}

		.shadow {
			width: 100%;
			height: 100%;
			border: 0.3em solid #ddd;
			border-radius: 50%;
			border-width: 0.2em;
		}
	}
}

.project-heading {
	padding: 10px 20px;
	/*border-bottom: 1px solid #f2f2f2;*/
	text-align: left;
	padding-left: 80px;
	background: linear-gradient(to bottom, rgba(232, 244, 255, 0.3), white);

	.edit {
		margin-right: -10px;
	}

	.due {
		color: #aaa;
		display: inline-block;
		margin-left: 20px;
		font-size: 14px;
		font-weight: 500;

		i {
			color: #ccc;
			margin-right: 4px;
		}
	}

	h1 {
		font-size: 22px;
		line-height: 32px;
		font-weight: 500;
	}
}

input[type="text"].small-input {
	font-size: 13px;
}

.notifications-enter {
  background: #f9f4bb;
  color: goldenrod;
}

.notifications-enter.notifications-enter-active {
  background: none;
  color: #666;
  transition: 2000ms;
}

.notifications-exit {
  opacity: 1;
}

.notifications-exit.notifications-exit-active {
  opacity: 0.01;
  transition: all 1500ms ease-in-out;
}

.main-panel.notification-panel {
	padding: 20px;
	h2.section {
		margin: -20px -20px 10px -20px;
		padding: 10px 20px;
		background: linear-gradient(to bottom, #f9f9f9, white);

		i {
			font-size: 20px;
		}
	}

	h3 {
		margin-top: 0;
		font-weight: 400;

		.new-count {
			/*background: #fffcd7;*/
			color: #8cbf7e;
			padding: 0 10px;
			margin-left: 10px;
			border-radius: 50px;
			font-weight: 600;
			line-height: 29px;
			display: inline-block;
		}

		.text-button {
			float: right;
			font-size: 13px;
			font-weight: 400;
			padding: 0px 8px;
		}
	}
}

.notification {
	display: flex;
	padding: 5px;
	margin-bottom: 0px;
	position: relative;
	align-items: center;

	&.unread {
		h4 {
			color: #444;
		}

		.icon {
			color: $green;

			i:before {
				font-weight: 500;
			}
		}
	}

	&:hover, &:active {
		background: #fafafa;
		border-radius: 6px;

		.controls {
			display: block;
		}
	}

	h4 {
		color: #777;
	}

	.icon {
		line-height: 20px;
		width: 20px;
		color: #ccc;
		font-size: 20px;
		text-align: center;
	}

	.controls {
		z-index: 100;
		right: 10px;
		display: none;
		position: absolute;
		background: linear-gradient(to left, #fafafa 60%, transparent);
		text-align: right;
		padding: 0;
		top: 0;
		width: 120px;
		right: 0;

		i {
			text-align: center;
			font-size: 1.2em;
			padding: 5px;
			border: 1px solid #efefef;
			border-color: transparent;
			border-radius: 50px;
			line-height: 20px;
			width: 20px;
			color: #ccc;
			white-space: nowrap;
			cursor: pointer;
			border-color: #efefef;
			background: #f2f2f2;

			&:hover, &:active {
				&:before {
					font-weight: 500;
				}

				&.dismiss {
					color: #ce6f6f;
				}

				&.add {
					color: $green;
				}
			}
		}

		&:hover i, &:active i {
			border-color: #efefef;
			background: #f2f2f2;
		}

		i {
			margin-right: 4px;
			font-size: 1.3em;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.body {
		flex: 1;
		background: none;
		padding-left: 10px;
		position: relative;

		h4 {
			font-weight: 400;
			font-size: 13px;
			margin-bottom: 0;

			strong {
				font-weight: 500;
			}
		}

		.comment-text {
			font-size: 13px;
			padding-left: 10px;
			color: #999;
			margin-top: 4px;

			&:hover, &:active {
				.expand-truncated {
					display: inline-block;
				}
			}

			&:before {
				font-family: 'Font Awesome 5 Pro';
				content: '\f10d';
				float: left;
				margin-left: -10px;
				font-size: 7px;
				width: 10px;
				font-weight: 900;
				color: #ddd;
			}
		}

		.notification-info {
			color: #aaa;
			font-size: 14px;
			padding-left: 10px;

			i {
				color: #ccc;
				font-size: 1.3em;
				margin-right: 8px;
			}
		}
	}
}

.explanation-label {
	font-weight: 500;
	margin-bottom: 4px;
	font-size: 14px;
	color: #777;
}

.canonical {
	background: #f2f2f2;
	background: rgba(197, 234, 184, 0.32);
	padding: 5px 18px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	margin-bottom: 10px;
	display: inline-block;
	font-size: 15px;
	font-weight: 600;
	color: $green;

	i {
		color: rgba(140, 191, 126, 0.64);
		font-size: 1.3em;
		margin-right: 12px;
		margin-left: -5px;
	}
}

.users-with-contact {
	background: rgba(197, 234, 184, .2);
	background: #f6f6f6;
	background: #fff;
	margin-top: -10px;
	margin-bottom: 10px;
	border: 0px solid rgba(197, 234, 184, 0.32);
	/*border-color: #f2f2f2;*/
	color: #999;
	font-size: 13px;
	border-radius: 6px;
	padding: 6px;

	strong {
		font-weight: 500;
	}

	.send-note {
		display: inline-block;
		margin-top: 5px;
		padding: 3px 8px;
		cursor: pointer;
		background: #fffcd7;
		border-radius: 6px;
		color: #e0cd22;
	}
}

.card {
	font-size: 14px;
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'helvetica neue', 'helvetica', 'sans-serif';
	font-weight: 500;

	.StripeElement {
		border: 1px solid #efefef;
		background: #f2f2f2;
		padding: 10px;
		border-radius: 6px;
	}

	.InputElement {
		background: white;
	}

	.CardField-child input {
		border: 40px solid black;
		border-radius: 6px;
	}
}

.plan-reminder, .welcome-new-user {
	margin: 0 10px 20px 10px;
	font-weight: 600;
	line-height: 26px;
	font-size: 15px;

	.etc {
		font-weight: 500;
		line-height: 22px;
		margin-top: 5px;
	}

	strong {
		background: #fffbce;
		padding: 3px 5px;
		border-radius: 3px;
	}
}

.welcome-new-user {
	background: white;
	font-weight: 500;
	margin: -20px -20px 20px -20px;
	padding: 20px;
	line-height: 18px;
	font-size: 14px;
	border-bottom: 2px solid #f2f2f2;

	strong {
		font-size: 16px;
	}
}

.headingless .welcome-new-user {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

.import-status {
	background: linear-gradient(to bottom, #fafafa, white);
	padding: 10px;
	border-bottom: 1px solid #f2f2f2;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	margin-bottom: 0;

	table {
		margin-top: 10px;
		padding: 10px 20px 0 20px;
		table-layout: fixed;

		td {
			text-align: center;

			i {
				font-size: 3em;
				color: #ddd;
				display: block;
				margin-bottom: 10px;
			}

			div {
				width: 150px;
				display: inline-block;
				background: #f2f2f2;
				border-radius: 6px;
				color: #999;
				padding: 5px 12px;

				strong {
					margin-bottom: 5px;
					font-size: 20px;
					display: block;
					color: #8cbf7e;
					font-weight: 600;
					line-height: 26px;
				}
			}
		}
	}

	p {
		font-weight: 500;
	}

	.status-heading {
		display: flex;
		align-items: center;

		.icon {
			flex-shrink: 0;
			padding: 10px;
		}

		i.fa-spin {
			font-size: 60px;
			color: #e0cd22;
			margin-right: 10px;
		}
	}

	h3 {
		margin-top: 0;
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 5px;
		/*color: #666;
		background: #efefef;*/
		color: $green;
		display: inline-block;
	}
}


.credit-card-form {
	background: #f6f6f6;
	margin: 10px -20px -20px -20px;
	padding: 20px;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	border-top: 1px solid #efefef;

	label {
		margin-top: 10px;

		&:first-of-type {
			margin-top: 0;
		}
	}

	h4 {
		font-weight: 600;
		color: $blue-etc;
		text-align: left;
		margin: -10px -10px 0px -10px;
		padding: 10px;
		color: #99b1d3;
	}

	.card {
		box-shadow: none;
		webkit-box-shadow: none;

		.invalid-record {
			margin: 0 0 10px 0;
		}

		label {
			margin-bottom: -2px;
		}
	}
}

.attachment-count {
	background: #fafafa;
	color: #bbb;
	border-radius: 5px;
	padding: 3px 6px;
	font-weight: 600;
}


.grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	.grid-block {
		flex-grow: 1;
	}

	&.file {
		.grid-block {
			margin: 5px;
			text-align: center;
			background: #fafafa;
			border: 1px solid #f2f2f2;
			padding: 10px;
			box-sizing: border-box;
			border-radius: 6px;
			min-width: 100px;
			max-width: 300px;

			.file-icon {
				font-size: 3em;
				background: white;
				margin: -10px;
				padding: 10px;
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
			}

			.attachment-count {
				background: #f2f2f2;
			}

			.file-name {
				font-weight: 600;
				font-size: 14px;
				color: #777;
				background: #f2f2f2;
				padding: 5px;
				margin: 10px -10px;
			}
		}
	}
}

h2.subtitle {
	margin: -5px 0 -10px 28px;
	line-height: 16px;
	color: #aaa;
	font-size: 16px;
}

.sign-in {
	.password-row {
		display: flex;
		margin-top: 10px;
		align-items: center;

		input {
			flex: 1;
		}

		a {
			margin-left: 10px;
			flex-shrink: 0;
			font-weight: 500;
		}
	}
}

.inline-notice {
	h3 {
		margin-top: 0;
		color: $green;
		font-size: 18px;
		font-weight: 600;
	}

	p {
		font-size: 15px;
	}
}

.new-password-intro {
	font-size: 15px;
	color: $blue-etc;
	text-align: center;
	font-weight: 500;

	.user-info {
		display: block;
		font-weight: 600;
		margin-top: 5px;
	}
}

#inbox .section h3 .sort, #contact h2 .sections {
	list-style: none;
	margin: 0;
	padding: 0;
	float: right;

	li {
		margin: 0;
		padding: 0;

		&:first-of-type span {
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
		}

		&:last-of-type span {
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
			border-right: 1px solid #efefef;
		}

		&.active span {
			background: white;
			cursor: auto;
			color: $green;
			font-weight: 600;

			i {
				color: $green;
			}
		}

		span {
			background: #fafafa;
			padding: 5px 8px;
			border: 1px solid #efefef;
			border-right: 0;
			cursor: pointer;
			color: #999;
			font-size: 13px;
			font-weight: 500;
			line-height: initial;

			i {
				margin: 0 8px 0 2px;
				box-shadow: none;
				border: none;
				padding: 0;
				width: auto;
				color: #ccc;
				background: none;
				font-size: 1em;
				float: none;
				line-height: initial;
			}
		}
	}
}

.toggle-icon {
	&.on {
		i.fa {
			font-weight: 900;
		}
	}

	i.fa {
		font-weight: 300;
	}
}

.recipient-list {
	display: inline-block;
	vertical-align: middle;

	.remove {
		display: table-cell;
		vertical-align: middle;
		padding-left: 10px;
		cursor: pointer;

		i {
			color: #99b1d3;
		}

		&:hover, &:active {
			i {
				color: firebrick;
			}
		}
	}

	.recipient {
		padding: 0 12px 0 8px;
		display: inline-table;
		color: #444;
		background: #f5f9ff;border-radius: 30px;
		border: 3px solid white;
		padding-right: 12px;
		margin-right: 5px;
		font-size: 14px;
		font-weight: 500;

		.avatar-container {
			display: table-cell;
			padding-right: 10px;
		}

		.avatar {
			margin-top: 3px;
			height: 25px;
			width: 25px;

			&.inline.generic {
				.initials-overlay {
					display: none;
				}

				i {
					display: block;
					font-size: 25px;
				}
			}
		}

		span {
			display: table-cell;
			vertical-align: middle;
		}
	}
}

.recipient-options {
	.content-block {
		max-height: 450px;
		overflow: scroll;

		ul.table {
			margin: 0;
		}

		.block {
			padding-left: 50px;
		}
	}

	.avatar {
		float: left;
		margin-left: -40px;
		height: 30px;
		width: 30px;

		&.generic {
			border: none;

			i {
				font-size: 30px;
			}
		}

		.initials-overlay {
			display: none;
		}
	}
}

.new-plan {
	&.delinquent {
		h4, .plan-description {
			background: #f8e9e9;
		}
	}

	&.ending {
		h4, .plan-description {
			background: #fffcd7;
		}
	}

	h4 {
		background: #edf8e9;
		padding: 10px 20px 5px 20px;
		font-weight: 600;
		margin: 0 -20px;
		color: $green;

		strong {
			font-weight: 700;
		}

		i {
			font-size: 44px;
			float: left;
			background: #f5efa5;
			background: none;
			border-radius: 50%;
			width: 50px;
			color: #e0cd22;
			color: #a4d497;
			text-align: center;
			margin-top: -15px;
			margin-top: 5px;
			line-height: 50px;
			margin-righT: 20px;
			vertical-align: middle;
		}
	}

	.plan-description {
		padding: 0px 10px 10px 90px;
		margin: 0 -20px 10px -20px;
		background: #edf8e9;
		color: #666;
		font-size: 14px;
		font-weight: 500;

		i {
			color: #e0cd22;
		}
	}
}

.record-row {
	border-bottom: 1px solid #f2f2f2;
	padding: 10px;
	display: flex;
	position: relative;

	.row-body {
		flex-grow: 1;
	}

	/*&:last-of-type {
		border-bottom: 0;
	}*/

	&:hover, &:active, &.active {
		cursor: pointer;
		background: #f5f9ff;

		.row-controls {
			display: flex;
		}
	}
}

.row-controls {
	display: none;
	right: 0;
	position: absolute;
	align-items: center;
	flex-direction: row-reverse;
	bottom: 0;
	top: 0;
	width: 300px;
	padding-left: 100px;
	background: linear-gradient(to right, transparent, #f5f9ff 100px);

	&.file-controls {
		width: 150px;
	}

	&.contact-controls {
		width: 150px;
		background: linear-gradient(to right, transparent, #fcfdff 100px);
	}

	&.persistent {
		display: flex;
	}

	.control-container {
		margin-right: 10px;

		.hover-action {
			display: inline-block;

			i, span {
				font-size: 28px;
				line-height: 50px;
				width: 50px;
				text-align: center;
				border-radius: 50%;
				margin-right: 10px;
				background: #e2edff;
				color: $blue-etc;

				&.active {
					&:before {
						font-weight: 700;
					}
				}

				&.snooze-button {
					display: inline-block;

					&:before {
						content: "\f34e";
						font-family: 'Font Awesome 5 Pro';
						font-weight: 300;
					}

					&:hover, &:active, &.active {
						color: #e0cd22;
					}
				}

				&:hover, &:active {
					background: #d5e3f9;

					&.fa-flag, &.fa-flag-o {
						color: firebrick;
					}

					&.fa-check {
						color: $green;
					}

					&.fa-envelope, &.fa-envelope-open {
						color: $blue-etc;
					}
				}
			}
		}
	}
}

.scope-tab-container {
	margin-left: 20px;
	margin-top: 5px;
	margin-bottom: -1px;
	position: relative;

	.pop-under-form {
		margin-top: 8px;
	}

	i {
		color: #99b1d4;
		margin-right: 10px;
	}

	.scope-tabs {
		box-shadow: 0 0 10px #d0d5db;
		display: inline-block;
		border-top-left-radius: 5px;
		position: relative;
		border-top-right-radius: 5px;

		> span {
			position: relative;
			font-size: 13px;
			display: inline-block;
			background: #fafafa;
			border: 1px solid #d0d5db;
			font-weight: 500;
			border-bottom: 0;
			padding: 6px 12px;
			border-right: 0;
			color: #aaa;
			cursor: pointer;

			&.single-mailbox {
				position: relative;
			}

			&.loading {
				&:after {
					content: '\f110';
					font-family: 'Font Awesome 5 Pro';
					margin: 0 -5px 0 6px;
					color: $gold;
					display: inline-block;
					font-weight: 900;
					-webkit-animation: fa-spin 2s infinite linear;
					animation: fa-spin 2s infinite linear;
				}
			}

			&.active:not(.loading) {
				background: linear-gradient(to bottom, #f3f8f6, white);
				border-bottom-color: white;
				z-index: 100;
				color: $green;
				font-weight: 600;
			}

			&:first-of-type {
				border-top-left-radius: 5px;
			}

			&:last-of-type {
				border-top-right-radius: 5px;
				border-right: 1px solid #d0d5db;
			}
		}
	}

	> span {
		color: #bbd1ea;
		margin-left: 10px;
		font-size: 13px;
		font-weight: 500;
		cursor: pointer;

		&:hover, &:active {
			color: $blue-etc;
		}
	}
}

.layer .mailbox-picker {
	width: 100%;
	margin-top: 0;
}

.mailbox-picker {
	padding: 0;
	width: 300px;
	cursor: auto;

	.content {
		overflow: scroll;
		max-height: 600px;
	}

	.divider, .mailbox {
		text-align: left;
		font-size: 13px;
		font-weight: 500;
		padding: 5px;
		padding-left: 35px;

		&:first-child {
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}

		&:last-child {
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			border-bottom: 0;
		}

		i {
			color: #ccc;
			text-align: center;
			width: 30px;
			margin: 0;
			float: left;
			margin-left: -32px;
		}
	}

	.divider {
		font-size: 14px;
		color: #86a8d6;
		background: #fafafa;
		border-bottom: 1px solid #efefef;
		padding: 8px 5px 8px 35px;
		font-weight: 600;

		i {
			font-size: 16px;
			color: $green;
		}
	}

	.mailbox {
		border-bottom: 1px solid #f2f2f2;
		cursor: pointer;

		i {
			margin-top: 1px;
			font-size: 16px;
		}

		strong {
			color: #777;
		}

		&:hover, &:active {
			background: #f5f9ff;
		}

		&:last-of-type {
			border-bottom: 0;
		}
	}

	&:last-child {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
}

.connected-importing {
	text-align: center;

	i {
		color: $gold;
		font-size: 72px;
	}

	h2 {
		color: $green;
		font-size: 20px;
		font-weight: 500;
	}
}

.mailbox-indicator-dot {
	margin-right: 5px;
	color: #ccc;
	font-size: 14px;
}

.mailbox-indicator {
	border-left: 2px solid transparent;

	&.khaki { border-left-color: khaki; }
	&.orange { border-left-color: orange; }
	&.peru { border-left-color: peru; }
	&.lightcoral { border-left-color: lightcoral; }
	&.crimson { border-left-color: crimson; }
	&.mediumpurple { border-left-color: mediumpurple; }
	&.steelblue { border-left-color: steelblue; }
	&.teal { border-left-color: teal; }
}

/*
.new-comment {
	text-align: center;
	margin-top: 10px;
	background: #f2f2f2;
	padding: 10px 5px;
	border: 1px solid #efefef;
	border-left: 0;
	border-right: 0;

	textarea {
		box-sizing: border-box;
		width: 100%;
		font-size: 12px;
		height: 150px;
	}
}

.comment {
	padding: 10px;
	font-size: 12px;

	.poster {
		font-size: 13px;

		.date {
			float: right;
			font-size: 12px;
			margin-top: 0;
		}
	}

	.text {
		margin: 4px -5px 0 -5px;
		background: #fdfce9;
		position: relative;
		padding: 8px;
		border-radius: 5px;
		border: 1px solid #f9f7c9;

		&:before {
			position:absolute;
			background: #fdfce9;
			content:"";
			color: white;
			text-shadow: 0 0 1px #f9f7c9;
			font-size:12px;
			line-height:12px;
			text-indent:6px;
			top:-5px;
			left:20px;
			width: 8px;
			height: 8px;
			transform: rotate(135deg);
			border-left:1px solid #f2f2f2;
			border-bottom:1px solid #f2f2f2;
		}
	}
}
*/

h1 .sharee {
	font-size: 13px;
	color: $green;
	letter-spacing: -0.1px;
	font-weight: 600;
	text-transform: none;

	i {
		color: $green;
		margin-right: 5px;
	}
}

.share-bar {
	border-radius: 5px;
	width: 100%;
	margin-bottom: 20px;
	border: 1px solid #f2f2f2;
	background: #fafafa;
	box-shadow: 0 0 5px #f6f6f6;

	&.sharee {
		background: $blue-etc;
		margin: 0 15% 20px 15%;
		width: auto;
		border: none;

		.heading {
			color: white;
		}
	}

	.sharees {
		background: white;
		padding: 8px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border-top: 1px solid #f2f2f2;
	}

	.heading {
		padding: 10px 10px 10px 40px;
		margin: 0;
		font-weight: 600;
		color: $green;
		font-size: 16px;

		i {
			margin-left: -40px;
			width: 40px;
			text-align: center;
			color: $gold;

			&:before {
				font-weight: 600;
			}
		}

		.options {
			width: 200px;
			float: right;
			margin-top: -11px;

			span {
				font-size: 13px;
				padding: 5px;
				margin: 5px;
				box-sizing: border-box;
				font-weight: 500;
				border-radius: 5px;
				border: 1px solid #efefef;
				cursor: pointer;
				color: #888;
				display: inline-block;
				width: calc(50% - 10px);
				text-align: center;
				background: white;

				&:hover, &:active {
					background: #f9f9f9;
				}

				&.following {
					background: $green;
					color: white;
					font-weight: 600;

					&:hover, &:active {
						background: #7cb36e;
					}
				}
			}
		}
	}
}

.user-form-container {
	background: #fafafa;
	margin: 0 -20px;
	padding: 10px 30px 10px 20px;
	border-top: 2px solid #f2f2f2;

	.upload-avatar {
		margin-top: 10px;
	}
}

#commentary {
	margin: 0 -20px -20px -20px;
	padding: 10px 40px 10px 40px;
	background: #fafafa;
	border-bottom-left-radius: 5px;
	border-top: 2px solid #f2f2f2;
	border-bottom-right-radius: 5px;
}

.new-comment {
	margin: 20px 0px 0 80px;
	text-align: center;

	.avatar {
		float: left;
		display: block;
		margin-left: -80px;
	}

	textarea {
		height: 150px;
	}
}

.comment-wrapper, .new-comment {
	.avatar {
		display: inline-block;
		float: left;

		i {
			font-size: 60px;
		}

		.initials-overlay {
			top: 35px;
			font-size: 11px;
		}
	}

	.comment {
		background: #fafafa;
		background: white;
		border-radius: 6px;
		padding: 10px;
		margin-bottom: 10px;
		border: 1px solid #f2f2f2;
		margin-left: 80px;
		position: relative;

		.poster {
			color: $blue-etc;
		}

		&:before {
			position:absolute;
			background: #fafafa;
			background: white;
			content:"";
			color: white;
			text-shadow: 0 0 1px #f2f2f2;
			font-size:12px;
			line-height:12px;
			text-indent:6px;
			top:18px;
			left:-8px;
			width: 14px;
			height: 14px;
			-ms-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			border-left:1px solid #f2f2f2;
			border-bottom:1px solid #f2f2f2;
		}
	}

	.text {
		white-space: pre-line;
		margin-top: 10px;
	}
}

h1 .comments {
	display: inline-block;
	margin-left: 10px;
	font-size: 14px;
	letter-spacing: 0;
	font-weight: 500;
	line-height: 23px;
	color: #aaa;

	&:before {
		font-weight: 300;
		content: "\f086";
		font-family: 'Font Awesome 5 Pro';
		margin-right: 5px;
		font-size: 16px;
		color: $blue-etc;
	}
}

.inline-relative {
	display: inline;
	position: relative;
}

.chosen-users-inline {
	display: inline-block;
	margin-right: 5px;

	> div {
		display: inline-block;
		background: #f2f2f2;

		.avatar.container- {
			display: inline-block;
		}
	}
}

.share-sheet .content-block ul.table.user-filter-list, .share-sheet .chosen-users.sharees, .existing, .existing-shares .sharees {
	margin: 0;

	&.user-filter-list {
		width: 290px;
	}

	.block.pointer {
		display: inline-table;
		width: 100%;
		box-sizing: border-box;
		padding: 3px 10px 3px 5px;
	}

	.avatar-container {
		vertical-align: middle;
		display: table-cell;
		width: 30px;
		padding: 2px;
		text-align: center;

		.inline.avatar {
			background: none !important;
			border: 0 !important;
			width: 24px !important;
			height: 24px !important;
			font-size: 18px;
			margin: 0 10px 0 5px !important;
		}
	}

	.name {
		vertical-align: middle;
		font-size: 14px;
		display: table-cell;
		font-weight: 500;
		padding: 0 10px 0 0;
		color: #555;

		strong {
			font-weight: 600;
		}
	}

	.inline.avatar {
		height: 24px;
		width: 24px;
		display: block;

		.initials-overlay { display: none; }

		i {
			font-size: 24px;
		}
	}
}

.sharees .sharee, .chosen-users-inline .user, .badged-users .user {
	display: inline-table;
	border: 1px solid #f2f2f2;
	background: #fafafa;
	margin-right: 5px;
	width: 100%;
	border-radius: 5px;
	box-sizing: border-box;

	&:last-of-type {
		margin-right: 0;
	}

	.unshare {
		display: table-cell;
		text-align: center;
		width: 30px;
		vertical-align: middle;

		i {
			display: none;
			cursor: pointer;
			font-size: 18px;
			color: #ccc;

			&:hover, &:active {
				color: #ce6f6f;
			}
		}
	}

	&:hover, &:active {
		.unshare i {
			display: block;
		}
	}

	.avatar-container {
		vertical-align: middle;
		display: table-cell;
		width: 30px;
		padding: 2px;
		text-align: center;
	}

	.name {
		padding: 6px 2px;
		vertical-align: middle;
		/*display: table-cell;*/
		font-weight: 500;

		strong {
			font-weight: 600;
		}
	}

	.inline.avatar {
		height: 24px;
		width: 24px;
		display: block;

		.initials-overlay { display: none; }

		i {
			font-size: 24px;
		}
	}
}

.badged-users.borderless .user {
	border: 0;
}

.badged-users.backgroundless .user {
	background: none;
}

.inline-block {
	display: inline-block;
}

.chosen-users-inline .user {
	background: #efefef;
	margin-right: 5px;
}

.notification-filters {
	> div {
		padding: 6px 10px;
		cursor: pointer;
		margin: 5px;
		border-radius: 5px;
		border: 1px solid transparent;
		font-weight: 500;
		color: #999;

		&:hover, &:active, &.active {
			background: white;
		}

		&.empty {
			color: #ccc;

			i {
				color: #ddd;
			}
		}

		&.active {
			border-color: #f2f2f2;
			color: $blue-etc;
			box-shadow: 0 0 5px #efefef;

			&.filtering {
				&:after {
					float: right;
					font-family: 'Font Awesome 5 Pro';
					content: '\f1ce';
					-webkit-animation: fa-spin 2s infinite linear;
					animation: fa-spin 2s infinite linear;
					color: $gold;
					font-weight: 900;
				}
			}

			i {
				color: $green;

				&:before {
					font-weight: 900;
				}
			}
		}

		i {
			color: #ccc;
			width: 20px;
			font-size: 16px;
			text-align: center;
			margin-right: 5px;
		}
	}
}

i.expand-truncated {
	cursor: pointer;
	display: none;
	color: #e0cd22;
	margin-left: 5px;
	font-size: 13px;
	background: #fffcd7;
	border: 1px solid #f9f4bb;
	line-height: 15px;
	width: 17px;
	box-sizing: border-box;
	padding-left: 2px;
	text-align: center;
	border-radius: 50%;
}

.notification-tray {
	width: 400px;
	left: 70px;
	bottom: 131px;
	box-shadow: 0 3px 15px rgba(43, 70, 110, 0.25);

	.body {
		margin: 0;
		padding: 0 0 0 10px;
	}

	.notifications {
		padding: 10px;
	}

	.notification-heading {
		padding: 10px;

		.text-button {
			color: #666;
			float: right;
			background: none;
			margin-top: -2px;
			margin-left: 5px;
			border-radius: 20px;
			padding: 3px 8px;
			font-size: 14px;

			&:hover, &:active {
				background: #fbe9e9;
				color: $rust;
			}
		}

		strong {
			color: $green;
			font-size: 16px;

			&:before {
				color: #a6d698;
				font-family: 'Font Awesome 5 Pro';
				content: "\f0f3";
				margin-right: 10px;
			}
		}
	}
}

.event-count {
	float: right;
	line-height: 19px;
	min-width: 19px;
	background: #fafafa;
	text-align: center;
	margin-right: -5px;
	font-size: 13px;
	color: #ccc;
	border-radius: 20px;
	padding: 0 5px;
}

.active .event-count {
	background: #f5f9ff;
	color: $blue-etc;
	background: #e9f7e2;
	color: #8cbf7e;
}

.filtering.active .event-count {
	display: none;
}

.inline-highlight {
	display: inline;

	> span {
		-webkit-box-decoration-break: clone;
		box-decoration-break: clone;
		padding: 5px;
		border-radius: 5px;
	}

	&.warning {
		line-height: 21px;
		color: firebrick;

		span {
			background: #f5efa5;
		}
	}
}

a.highlight-on-hover {
	> span {
		-webkit-box-decoration-break: clone;
		box-decoration-break: clone;
		padding: 5px;
		border-radius: 5px;
	}

	&:hover, &:active {
		> span {
			background: aliceblue;
		}
	}
}

.initial-mailbox-subscriptions {
	margin: -20px;
	padding: 20px;
	background: #fafafa;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;

	> div {
		display: flex;
		padding: 10px;
		align-items: center;
		border-bottom: 1px solid #f2f2f2;
		width: calc(100% - 20px);

		&:last-of-type {
			border-bottom: 0;
		}

		.highlight-colors {
			background: white;
			border: 1px solid #f2f2f2;
			padding: 5px 8px 3px 8px;
			margin-top: 3px;
			border-radius: 40px;

			i {
				font-size: 20px;
				margin: 0 3px;
				cursor: pointer;
			}
		}

		&.active .mailbox-picker {
			&:before {
				content: "\f058";
				font-weight: 900;
				color: $green;
			}
		}

		.mailbox-picker {
			width: 40px;
			text-align: center;
			color: #ccc;
			flex-shrink: 0;
			cursor: pointer;
			margin-right: 10px;

			&:before {
				font-weight: 300;
				content: "\f111";
				font-family: 'Font Awesome 5 Pro';
				font-size: 32px;
			}

			&:hover, &:active {
				&:before {
					content: "\f058";
				}
			}
		}

		.mailbox-info {
			strong {
				display: block;
			}
		}
	}
}

i.green {
	color: $green !important;
}

.qr-code-parent {
	text-align: center;

	img {
		border: 3px solid #efefef;
		border-radius: 10px;
	}

	label {
		padding: 10px;
		background: #efefef;
		margin: 20px -20px -20px -20px;
		color: #999;
		font-weight: 500;
	}

	input[type="text"] {
		display: block;
		margin: 20px -20px;
		padding: 10px 0;
		font-size: 48px;
		text-align: center;
		color: $blue-etc;
		letter-spacing: 10px;
		width: calc(100% + 40px);
		font-weight: 700;
		border: 0;
		border-top: 1px solid #efefef;
		border-bottom: 1px solid #efefef;
		border-radius: 0;
	}
}