.close-icon {
	background-color: transparent;
	border: 0;
	cursor: pointer;
	display: inline-block;
	height: 0;
	outline: 0;
	padding: 0;
	vertical-align: middle;
}

.close-icon:after {
	background-color: #216ba5;
	border-radius: 50%;
	bottom: 0;
	box-sizing: border-box;
	color: white;
	content: "\00d7";
	cursor: pointer;
	display: block;
	font-size: 12px;
	font-weight: normal;
	height: 16px;
	line-height: .8;
	margin: -8px auto 0;
	padding: 2px;
	position: absolute;
	right: 7px;
	text-align: center;
	top: 50%;
	width: 16px;
	z-index: 1;
}

.layer {
	position: fixed;
	z-index: 9999999;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;
	text-align: center;
	padding: 0;

	.main {
		margin: 50px auto;
		position: relative;
		box-shadow: 0 0 80px #111;
		margin-bottom: 100px;

		&.full {
			max-width: 800px;
			margin-left: auto;
		}

		&.small {
			max-width: 600px;
			margin-left: auto;
		}

		&.with-overflow {
			overflow: visible;
		}
	}
}

.layer-shade {
	position: fixed;
	height: 100%;
	width: 100%;
	background: black;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0.7;

	&.secondary {
		opacity: 0.3;
	}
}

.layer .structure .info-panel {
	box-shadow: none;
}