.file-attachments {
	margin-top: -10px;
	margin-bottom: 20px;

	h2 {
		margin: 0;
		color: #666;
		font-size: 15px;

		strong {
			font-weight: 600;
			background: #fafafa;
			border-radius: 4px;
			padding: 4px 8px;
		}
	}

	.attachment-message-row {
		font-size: 13px;
		padding: 8px 10px;
		border-bottom: 1px solid #f2f2f2;
		display: flex;

		&:last-of-type {
			border-bottom: 0;
		}

		.subject {
			flex: 1;
			font-weight: 500;

			a {
				padding: 5px 8px;
				border-radius: 6px;

				&:hover, &:active {
					background: #fafafa;
				}
			}
		}

		.sent-at {
			color: #999;
		}
	}
}

.file.description {
	background: #e0ecff;
	color: #86a8d6;
	font-weight: 500;
	padding: 5px 20px;
}

.attachment-message, .timeline-attachment {
	display: flex;
	margin-bottom: 10px;
	&:last-of-type {
		margin-bottom: 0;
	}

	.sender-icon, .download-icon {
		width: 50px;
		text-align: center;
		color: #ccc;
		padding-top: 7px;
		font-size: 2em;

		i {
			font-weight: 300;
		}

		&.download-icon {
			i {
				font-weight: 900;
				cursor: pointer;
				&:hover, &:active {
					color: $green;
				}
			}
		}
	}

	.message-info {
		flex: 1;
		background: #fafafa;
		padding: 10px 20px;
		border-radius: 10px;
	}

	.subject {
		font-size: 15px;
		font-weight: 500;
	}

	.sent-at {
		float: right;
		font-size: 13px;
		color: #999;
		/*background: #f2f2f2;
		padding: 4px 8px;
		border-radius: 6px;
		margin-top: -4px;*/
	}

	.sender {
		margin-bottom: 5px;
	}

	.snippet {
		color: #999;
	}
}

.timeline-attachment {
	.file-icon {
		width: 50px;
		text-align: center;
		color: #bdd0ef;
		padding-top: 5px;

		i {
			font-size: 2em;
		}
	}

	.message-info {
		background: none;
		padding: 0;

		i {
			margin-right: 10px;
			text-align: center;
			color: #ccc;
		}

		.file-name {
			font-weight: 600;
		}
	}
}

.all-attachments {
	.attachment-message {
		display: flex;
	}

	.action {
		display: none;
	}
}

.last-3-attachments {
	.attachment-message:nth-child(n+4) {
		display: none;
	}
}

a.folder label {
	cursor: pointer;
}

.folder {
	position: relative;
	display: inline-block;
	text-align: center;

	&:before{
		content: '';
		display: block;
		position: absolute;
	}

	&:after{
		content: '';
		position: absolute;
		display: block;
		width: 0;
		height: 0;
	}

	&.small i{ font-size: 37px;}
	&.medium i{ font-size: 53px;}
	&.big i{ font-size: 53px;}

	span, i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	span, label {
		font-size: 12px;
		padding: 2px 8px;
		border-radius: 15px;
		white-space: nowrap;
	}

	label {
		position: absolute;
		bottom: 5px;
		margin-bottom: 0;
		left: 50%;
		transform: translate(-50%);
	}

	&.small label {
		background: none;
		font-size: 12px;
		font-weight: 400;
		bottom: 0;
	}

	&.small.labeled i {
		font-size: 27px;
	}

	&.labeled i {
		top: 35%;
	}

	&.small{
		margin-top: 8px;
		width: 88px;
		height: 61px;
		border-radius: 0px 6px 6px 6px;
	}

	&.medium{
		margin-top: 12px;
		width: 132px;
		height: 92px;
		border-radius: 8px;
	}

	&.big{
		margin-top: 24px;
		width: 264px;
		height: 183px;
		border-radius: 0px 14px 14px 14px;
	}

	&.small:before{
		top:-8px;
		left: 0;
		width: 32px;
		height: 8px;
		border-radius: 6px 0px 0px 0px;
	}

	&.small:after{
		top:-16px;
		left: 24px;
		border: 8px solid transparent;
	}

	&.medium:before{
		top:-12px;
		left: 4px;
		width: 44px;
		height: 12px;
		border-radius: 4px 0px 0px 0px;
	}

	&.medium:after{
		top:-24px;
		left: 36px;
		border: 12px solid transparent;
	}

	&.big:before{
		top:-24px;
		left: 0;
		width: 96px;
		height: 24px;
		border-radius: 14px 0px 0px 0px;
	}

	&.big:after{
		top:-48px;
		left: 72px;
		border: 24px solid transparent;
	}

	$beige: #f5efa5;
	&.beige {
		background-color: $beige;
		border: 1px solid #f1ea98;
		box-shadow: 0 0 10px #f2f2f2;
		span, label { background-color: darken($beige, 20%); color: #a79600; }
		&:before { background-color: darken($beige, 10%) }
		&:after { border-bottom-color: darken($beige, 10%); }
		i { color: darken($beige, 20%); }
	}

	$blue: #f5f9ff;
	&.blue {
		background-color: $blue;
		border: 1px solid darken($blue, 5%);
		box-shadow: 0 0 10px #f2f2f2;
		span, label { color: $blue-etc; }
		&:before { background-color: darken($blue, 10%) }
		&:after { border-bottom-color: darken($blue, 10%); }
		i { color: darken($blue, 20%); }
		&:hover, &:active {
			background: darken($blue, 1%);
			&:before { background-color: darken($blue, 15%) }
			&:after { border-bottom-color: darken($blue, 15%); }
		}
	}

		 /*Available colors*/
	&.cyan{ background-color: #23CBF4;}
	&.cyan:before, &.cyan span{ background-color: #06BAEE;}
	&.cyan:after{ border-bottom-color: #06BAEE;}
	&.cyan span{color: #FFFFFF;}
	&.cyan i{color: #06BAEE;}

	&.yellow{ background-color: #FFBD00;}
	&.yellow:before, &.yellow span{ background-color: #F1A402;}
	&.yellow:after{ border-bottom-color: #F1A402;}
	&.yellow span{color: #FFFFFF;}
	&.yellow i{color: #F1A402;}

	&.pink{ background-color: #FF7F92;}
	&.pink:before, &.pink span{ background-color: #FF5E6E;}
	&.pink:after{ border-bottom-color: #FF5E6E;}
	&.pink span{color: #FFFFFF;}
	&.pink i{color: #FF5E6E;}

	&.green{ background-color: #62DE82;}
	&.green:before, &.green span{ background-color: #66CA7F;}
	&.green:after{ border-bottom-color: #66CA7F;}
	&.green span{background-color: transparent;}
	&.green i{color: #54C170;}
}

.folder-grid {
	display: table;
	table-layout: fixed;
	width: 100%;

	&.smart-folders {
		margin: -20px -20px 20px -20px;
		background: #fafafa;
		background: #fdfcea;
		width: calc(100% + 40px);
		padding: 15px;
		box-sizing: border-box;
	}

	> div {
		text-align: center;
		display: table-cell;
	}
}

/*.file-table:last-child, .email-table:last-child {
 .row:last-of-type, .email-row:last-of-type {
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		border-bottom: 0;

		.file-controls {
			border-bottom-right-radius: 6px;
		}
	}
}*/

.file-table {
	.file-info {
		font-size: 13px;
		padding-top: 2px;
	}

	.date {
		float: right;
	}

	.file-icon {
		font-weight: 300;
		font-size: 40px;
		width: 60px;
		text-align: center;
		flex-shrink: 0;
		margin-right: 10px;
	}

	a.file-thread, a.file-sender {
		&:hover, &:active {
			background: #edf4ff;
			color: $blue-etc;
		}
	}

	.file-thread, .file-sender {
		padding: 3px 5px;
		border-radius: 5px;
		color: #aaa;
		font-weight: 500;

		&:before {
			color: $green;
			margin-right: 5px;
			font-family: 'Font Awesome 5 Pro';
			color: $green;
			font-weight: 300;
			margin-right: 3px;
		}

		&.file-thread:before { content: '\f2b6'; }
		&.file-sender:before { content: '\f2bd';; }
	}
}

.structure.files, .structure.contacts {
	width: calc(100% + 20px);
	margin: -10px;

	.info-panel {
		border-left: 0;
		border: 1px solid #efefef;
		border-radius: 6px;
	}
}

.file-timeline-date {
	font-size: 13px;
	margin: 5px 0 5px -10px;
	background: #f2f2f2;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	display: inline-block;
	padding: 5px 10px 5px 10px;
	color: #777;
	font-weight: 600;
}

.structure .info-panel .recent-files h3, .structure .info-panel .recent-contacts h3 {
	margin-bottom: 5px;
	background: #fcfcfc;
	margin-top: -10px;
	padding: 8px 10px;
	border-bottom: 1px solid #f2f2f2;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;

	i {
		margin-right: 5px;
		width: auto;
	}
}

.see-all {
	background: #f2f2f2;
	border-radius: 30px;
	color: #999;
	font-size: 13px;
	font-weight: 500;
	float: right;
	margin-right: 10px;
	line-height: 18px;
	padding: 4px 12px;
	margin-top: 7px;

	&:hover, &:active {
		background: #efefef;
		color: $green;
	}

	&:before {
		content: "\f356";
		float: right;
		font-family: 'Font Awesome 5 Pro';
		font-weight: 900;
		margin-left: 10px;
		color: $green;
	}
}

.snapshot-heading {
	font-size: 16px;
	line-height: 20px;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	display: flex;
	background: #f2f2f2;
	margin-left: -20px;
	padding: 5px 20px;
	color: #666;
	align-items: center;

	i {
		color: $green;
		font-size: 20px;
		background: white;
		border-radius: 50px;
		width: 30px;
		line-height: 30px;
		text-align: center;
		margin-right: 20px;
	}
}

/*.snapshot-heading {

	line-height: 40px;
	font-size: 18px;
	letter-spacing: -0.2px;
	font-weight: 500;
	border-bottom-right-radius: 20px;
	border-top-right-radius: 20px;

	&.warning {
		i {
			background: #e0cd22;
		}
	}

	i {
		float: left;
		background: $green;
		width: 40px;
		margin-right: 10px;
		height: 40px;
		font-size: 20px;
		color: white;
		margin-left: -20px;
		line-height: 40px;
		padding-right: 10px;
		text-align: center;
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
	}
}*/

.file-name .fa-thumbtack {
	color: $green;
	margin-right: 10px;
}

.pinned-files {
	.file-table {
		border-top: 1px solid #f2f2f2;
		border-bottom: 1px solid #f2f2f2;
		margin: 10px -10px 20px -20px;
	}
}

.tasked-files, .tasked-contacts {
	.file-tasks, .contact-tasks {
		margin: 10px 0 10px 50px;
		padding: 0px;
		background: #fafafa;
		border-radius: 6px;
		border: 0;
		width: auto;

		.task-row {
			border-bottom: 1px solid #f2f2f2;

			&:last-of-type {
				border-bottom: 0;
			}
		}
	}

	.tasked-file, .tasked-contact {
		display: flex;
		align-items: center;

		.avatar-container {
			width: 60px;
			text-align: center;
			padding: 5px;
			box-sizing: border-box;
		}

		.file-icon {
			font-size: 30px;
			width: 50px;
			text-align: center;
			font-weight: 600;
		}

		.name {
			font-weight: 500;
		}

		&.toggling {
			.checkbox.with-circle {
				-webkit-animation: fa-spin 2s infinite linear;
				animation: fa-spin 2s infinite linear;

				&:before {
					content: '\f3f4' !important;
					font-family: 'Font Awesome 5 Pro';
				}
			}
		}

		&.complete:not(.toggling) {
			.checkbox.with-circle:before {
				content: '\f058';
				font-family: 'Font Awesome 5 Pro';
				color: $green;
				font-weight: 900;
			}

			.task-assignment {
				color: #999;
				text-decoration: line-through;
			}
		}

		.checkbox {
			float: left;
			margin-left: -60px;
			width: 50px;

			&:before {
				font-size: 20px;
				font-weight: 300;
			}
		}

		.task-assignment {
			font-weight: 500;
		}

		.file-info {
			font-size: 14px;
			color: #888;
			margin-top: 5px;
			font-weight: 500;
			margin-left: 10px;

			i {
				color: $green;
				margin-right: 5px;
			}
		}
	}
}

.no-pinned-files {
	padding-left: 110px;
	background: #fafafa;
	padding: 10px 10px 10px 110px;
	margin: 10px 10px 0 0;
	border-radius: 10px;

	h3 {
		margin-top: 0;
	}

	i {
		font-size: 70px;
		float: left;
		color: #e0cd22;
		margin-left: -90px;
	}
}

.filters  {
	width: 100%;

	.table {
		width: 100%;
	}

	.extra-options {
		min-width: 90px;
		max-width: 120px;
		text-align: center;
	}
}

.file-filters .toggle-icon {
	color: $green;
	width: 21px;
	text-align: center;

	i {
		line-height: 20px;
	}

	&.off {
		color: #ccc;

		i.fa {
			font-size: 19px; /* for some reason the fal version is bigger */
		}
	}
}

.structure.files, .structure.contacts {
	h3.with-icon-before {
		margin-left: -20px;

		&:before {
			background: #fafafa;
			border-color: #f2f2f2;
		}

		&.pinned:before {
			content: "\f08d";
		}

		&.tasks:before {
			content: "\f058";
		}
	}
}


/*
<div className="paginator">
	<i className="fa fa-circle-left previous" />
	Page {pageNumber} of {numPages}
	<i className="fa fa-circle-right next" />
</div>

*/

.preview {
	.image, .pdf {
		margin: 20px auto;
		text-align: center;
	}

	.pdf-wrapper {
		overflow-x: scroll;

		.textLayer {
			display: none; /* TODO: get the text to overlap the canvas appropriately so people can copy text */
		}

		.paginator {
			background: #fafafa;
			padding: 4px 16px;
			font-size: 14px;
			border-radius: 50px;
			margin: 0px auto 20px auto;
			display: inline-block;
			font-weight: 500;
			line-height: 24px;
			border: 1px solid #efefef;
			box-shadow: 0px 0px 8px #efefef;

			i { /*def1d8*/
				color: $green;
				font-weight: 700;
				cursor: pointer;
				display: inline-block;
				background: white;
				line-height: 30px;
				width: 30px;
				height: 30px;
				border-radius: 50px;
				border: 2px solid #efefef;

				&.inactive {
					color: #aaa;
					background: none;
					border: 2px solid #fafafa;

					&:hover, &:active {
						background: none;
						cursor: auto;
						color: #aaa;
					}
				}

				&:hover, &:active {
					background: #def1d8;
					border-color: #fafafa;
				}
			}

			span {
				display: inline-block;
				margin: 0 20px;
			}
		}

		canvas {
			margin: 0 auto;
			border: 2px solid #efefef;
		}
	}

	.image {
		img {
			display: block;;
			max-width: 80%;
			margin-left: auto;
			margin-right: auto;
		}
	}
}