ul.sections, ul.sort, .infinite-paginator, .center-console, .multipart-console {
	margin: 0;
	padding: 0;

	&.icon-only {
		li .console-button i {
			margin-right: 0;
		}
	}

	&.small {
		li .console-button {
			font-size: 13px;
			font-weight: 500;
			padding: 3px 8px;
		}
	}

	&.inline {
		display: inline-block;
		margin-left: 10px;
	}

	li {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: inline;
		line-height: 20px;

		a, .blank, .console-button, .console-label {
			i {
				width: 20px;
				margin-right: 6px;
				text-align: center;
				color: #ccc;
				display: inline-block;
				font-size: 1.2em;
			}

			display: inline-block;
			background: #fafafa;
			border: 1px solid #efefef;
			border-right: 0;
			padding: 6px 12px;
			color: #aaa;
			font-weight: 500;
			cursor: pointer;
		}

		&:first-child a, &:first-child .console-button {
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
		}

		&:last-child a, &:last-child .console-button {
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
			border-right: 1px solid #efefef;
		}

		a, .console-button {
			&.active {
				background: #f3f3f3;
				font-weight: 600;
				color: $green;

				i {
					color: $green;
				}
			}

			&:hover, &:active, &.active {
				background: #f3f3f3;
			}
		}

		.blank, .console-label {
			background: white;
		}
	}

	.hidden-on-inactive {
		display: none;
	}

	.active .hidden-on-inactive {
		display: inline;
	}
}

ul.center-console {
	text-align: center;
	float: none;

	li .console-button.active {
		background: #efefef;
	}
}

ul.last-viewed-scope {
	margin-bottom: 10px;

	li .console-button i {
		margin-right: 0;
	}

	span.hidden-on-inactive {
		margin-left: 5px;
	}
}

.icon-tabs {
	margin: 0 4% 20px 4%;
	padding: 0;
	list-style-type: none;
	width: 92;
	display: block;

	li {
		margin: 0;
		padding: 10px;
		text-align: center;
		display: inline-block;
		width: 25%;
		box-sizing: border-box;
		position: relative;
		cursor: pointer;

		&:hover, &:active, &.active {
			span, i {
				color: #7ea6d5;
			}

			.count-badge {
				color: white;
			}
		}

		i {
			color: #afceed;
			font-size: 36px;
		}

		&.zero {
			i {
				color: #ddd;
			}

			span {
				color: #aaa;
			}

			.count-badge {
				display: none;
			}

			&:hover, &:active {
				span, i {
					color: #aaa;
				}
			}
		}

		&.active, &.zero.active {
			border-radius: 10px;
			background: aliceblue;

			span {
				font-weight: 600;
				color: #7ea6d5;
			}

			i {
				color: #7ea6d5;
			}
		}

		span {
			font-weight: 500;
			color: #7ea6d5;
			font-size: 14px;
			margin-top: 10px;
			display: block;
		}

		.count-badge {
			font-size: 12px;
			font-weight: 500;
			padding: 2px 8px;
			border-radius: 20px;
			display: inline-block;
			position: absolute;
			top: -8px;
			background: #549442;
			color: white;

			&.read {
				background: $blue-etc;
			}
		}
	}
}