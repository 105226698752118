.tags {
	list-style-type: none;
	margin: 0;
	padding: 0;

	li {
		margin: 0;
		padding: 2px 0;
		border-bottom: 0;
	}
}

.tag {
	font-weight: 500;
	white-space: nowrap;
	position: relative;
	margin: 1px 8px;
	display: inline-block;
	height: 23px;
	font-size: 13px;
	border-radius: 0 5px 5px 0;
	padding: 0 15px;
	background: #fef544;
	border: 1px solid #f0e630;
	color: #d1b800;
	line-height:23px;

	&.large {
		font-size: 15px;
		padding: 3px 15px;
		margin-left: 20px;
	}

	.count {
		background: goldenrod;
		line-height: 18px;
		margin-top: 3px;
	}

	.untag {
		visibility: hidden;
		margin-left: 4px;
		margin-right: -10px;
		float: right;
		cursor: pointer;

		i {
			float: none;
			margin: auto;
			color: #f69f9f;
		}
	}

	&:hover, &:active {
		.untag {
			visibility: visible;
		}
	}
}

.tag:before {
	position:absolute;
	background: #fef544;
	content:"\25CF";
	color: white;
	font-size:12px;
	line-height:12px;
	text-indent:6px;
	top:3px;
	left:-10px;
	width: 17px;
	height: 17px;
	-ms-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	border-left:1px solid #f0e630;
	border-bottom:1px solid #f0e630;
}

.tag.large:before {
	height: 22px;
	width: 22px;
	line-height: 20px;
	left: -12px;
}

.new-tag-input {
	display: none;
}

.social-links, .activity-types {
	border-top: 1px solid #efefef;
	padding: 5px 0;

	ul {
		padding: 0;
		margin: 0;

		li {
			list-style-type: none;
			display: inline;
			padding: 0;
			margin: 0;
		}
	}

	span.section {
		color: #ccc;
		display: inline-block;
		padding: 4px 8px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		margin: 0;
		border: 1px solid transparent;

		&.active {
			border: 1px solid #efefef;
			border-bottom: 1px solid #fafafa;
			background: #fafafa;
			color: $blue-etc;
		}

		&:hover, &:active {
			color: $blue-etc;
		}
	}
}

.new-tag {
	position: relative;
	border: 1px solid $medium-blue;
	display: inline-block;
	padding: 4px 8px;
	font-weight: 500;
	color: $blue-etc;
	font-size: 14px;
	text-decoration: none;
	line-height: 18px;
	background: #e6f2ff;
	border-radius: 3px;
	margin: 2px 10px;
	cursor: pointer;
	z-index: 1000000;
	color: #888;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	background: white;
	border-color: #eaeaea;

	&:before {
		content: "";
		height: 19px;
		width: 19px;
		position: absolute;
		left: -9px;
		background: #e6f2ff;
		border-bottom: 1px solid $medium-blue;
		border-left: 1px solid $medium-blue;
		display: block;
		top: 3px;
		-ms-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		z-index: -1;
		border-radius: 0;
		color: #aaa;
		background: white;
		border-color: #eaeaea;
	}

	&:hover, &:active {
		background: #fafafa;

		&:before {
			background: #fafafa;
		}
	}

	.count {
		margin-right: 0;
	}

	.dot {
		width: 8px;
		height: 8px;
		margin-right: 8px;
		display: inline-block;
		margin-left: -8px;
		border-radius: 8px;
		margin-top: 5px;
		float: left;
		background: #ddd;
	}

	&.purple      { border-color: #413075; color: #413075; .dot { background: #413075; } &:before { border-color: #413075; } }
	&.red-purple  { border-color: #592A72; color: #592A72; .dot { background: #592A72; } &:before { border-color: #592A72; } }
	&.fuschia     { border-color: #882E61; color: #882E61; .dot { background: #882E61; } &:before { border-color: #882E61; } }
	&.rust        { border-color: #AA3939; color: #AA3939; .dot { background: #AA3939; } &:before { border-color: #AA3939; } }
	&.sienna      { border-color: #AA6C39; color: #AA6C39; .dot { background: #AA6C39; } &:before { border-color: #AA6C39; } }
	&.tan         { border-color: #AA8639; color: #AA8639; .dot { background: #AA8639; } &:before { border-color: #AA8639; } }
	&.olive       { border-color: #AAAA39; color: #AAAA39; .dot { background: #AAAA39; } &:before { border-color: #AAAA39; } }
	&.lime        { border-color: #7C9F35; color: #7C9F35; .dot { background: #7C9F35; } &:before { border-color: #7C9F35; } }
	&.green       { border-color: #2E882E; color: #2E882E; .dot { background: #2E882E; } &:before { border-color: #2E882E; } }
	&.teal        { border-color: #226666; color: #226666; .dot { background: #226666; } &:before { border-color: #226666; } }
	&.blue        { border-color: #2E4372; color: #2E4372; .dot { background: #2E4372; } &:before { border-color: #2E4372; } }

	&.purple, &.red-purple, &.fuschia, &.rust, &.sienna, &.tan, &.olive, &.lime, &.green, &.teal, &.blue {
		border-color: #eaeaea;

		&:before {
			border-color: #eaeaea;
		}
	}

	.untag {
		visibility: hidden;
		float: right;
		margin: -4px -8px -4px 5px;
		height: 100%;
		padding: 4px 2px 4px 2px;
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px;
		background: #eaeaea;
		line-height: auto;
		font-size: 1.1em;
		border-left: 1px solid #e2e2e2;

		&:before {
			content: "\f00d";
			font-family: "FontAwesome";
			color: #ccc;
		}
	}

	.number {
		display: inline-block;
		margin-left: 10px;
	}
}

.new-tag:hover, .new-tag:active {
	.untag {
		visibility: visible;

		&:hover, &:active {
			background: #e2e2e2;

			&:before {
				color: #a71e29;
			}
		}
	}
}

.tag-board-options {
	background: #e0ecff;
	padding: 7px 10px;
	display: none;

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		text-align: center;

		li {
			background: #e0ecff;
			display: inline-block;
			font-weight: 500;
			color: $blue-etc;
			border-radius: 30px;
			padding: 5px 15px;
			margin: 0 5px;
			cursor: pointer;

			.number {
				float: none;
				color: $blue-etc;
				background: #e0ecff;
				font-weight: 600;
				margin-left: 6px;
				margin-right: -10px;
			}

			i {
				color: #c0d4f8;
				margin-right: 8px;
				float: left;
				margin-top: 3px;
				font-size: 1.2em;
			}

			&:hover, &:active {
				background: #f5f9ff;
			}

			&.on {
				background: white;
			}
		}
	}
}

.tag-stats {
	list-style-type: none;
	margin: 0;
	padding: 0;
	float: right;
	margin-right: 15px;
	font-size: 14px;
	margin-top: 8px;
	line-height: 100%;

	li {
		display: inline-block;
		margin-left: 20px;
		line-height: 25px;
		color: #6ba35b;
		vertical-align: middle;

		strong {
			background: #f2f2f2;
			min-width: 30px;
			display: inline-block;
			text-align: center;
			border-radius: 3px;
			padding: 3px;
			vertical-align: middle;
			font-weight: 500;
			font-size: 25px;
			margin-right: 10px;
		}

		&.empty {
			color: #ccc;
		}
	}
}

.main-content.tag-board {
	padding: 0 20px;

	.section {
		padding-left: 30px;

		h3.section-heading {
			margin-left: -30px;
		}
	}
}

.header-tags {
	margin-top: 10px;

	.fa-tags {
		color: #ccc;
		margin-left: 5px;
		font-size: 18px;
	}

	.add {
		color: #aaa;
		font-size: 12px;
		padding: 3px 6px;
		border-radius: 3px;
		cursor: pointer;
		margin-left: 5px;
		display: inline-block;
		margin-top: -2px;

		&:hover, &:active {
			background: #efefef;
		}
	}
}