.subheader {
	background: #003878;
	line-height: 60px;
	color: white;
	border-bottom: 2px solid #003878;

	.container {
		width: 910px;
		padding-left: 30px;
		padding-right: 30px;
	}

	a {
		color: white;
		text-decoration: underline;
	}
}

h1.inline {
	background: #f5f9ff;
	font-size: 20px;
	font-weight: normal;
	letter-spacing: -1px;
	margin: 0;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	color: $blue-etc;

	span {
		padding: 5px;
	}

	.icon {
		background: #f5f9ff;
		display: inline-block;
		padding: $small-padding;
		border-top-left-radius: 6px;
		border-bottom-right-radius: 6px;
		margin-right: 0;

		img {
			max-width: 40px;
			max-height: 40px;
		}
	}
}

#header {
	padding: 10px;
	height: auto;

	&.gray {
		background: #fafafa;
		border-bottom: 1px solid #f2f2f2;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	> div {
		display: flex;
		box-sizing: border-box;
		width: 100%;
		flex-wrap: wrap;
		align-items: center;
	}

	.stats {
		margin: 0;
	}

/*
	.avatar {
		width: 60px;
		height: 60px;
		box-shadow: 0 0 20px #ddd;
		background: #f2f2f2;
		border: 1px solid #ccc;
		margin: -5px 10px -5px 0;
	}
*/

	.text-button {
		line-height: 24px;
	}

	.filter-container {
		margin: 10px -10px -10px -10px;
		width: calc(100% + 20px);
		flex-grow: 1;
	}

	.header-page-icon, .header-page-image {
		color: #ddd;
		text-align: center;
		display: inline-block;
		width: 35px;
		margin-right: 10px;
		flex-shrink: 0;
		font-size: 30px;
		/*text-shadow: 1px 0 3px #fff, 1px 0 0 #999;*/

		&.with-breadcrumb {
			width: 50px;
			font-size: 44px;
		}

		&.logo img {
			max-width: 200px;
/*			border-radius: 5px;
			box-shadow: 0 0 10px #ddd;*/
		}

		i {
			font-weight: 300;
		}

		&.urgent {
			color: #e07878;
		}

		&.upcoming {
			color: #d6c102;
		}

		&.header-page-image {
			width: auto;

			img {
				max-height: 48px;
				max-width: 100px;;
			}
		}
	}

	h1 {
		overflow: hidden;
		text-overflow: ellipsis;
		letter-spacing: -0.5px;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		margin: 0;
		flex: 1;

		&.with-breadcrumb {
			padding-left: 10px;

			.page-icon {
				margin-left: -60px;
				font-size: 2em;
				width: 50px;
				margin-top: -20px;
			}
		}
	}

	.breadcrumb {
		font-size: 13px;
		color: #99b1d3;
		font-weight: 600;
		text-transform: uppercase;
	}
}

.header-actions {
	flex-shrink: 0;
	align-self: start;

	.fa-stack.red {
		i {
			color: #ce6f6f;
		}

		&:hover, &:active, &.active {
			i {
				color: #b15353;
			}
		}
	}

	&.blue {
		i {
			color: #afceed;
		}

		a, span {
			&:hover, &:active, &.active {
				i {
					color: $blue-etc;
				}
			}
		}
	}

	&.inline-left {
		float: none;
		margin-left: 10px;
		margin-right: 10px;
		display: inline-block;
		margin-top: -30px;
	}

	.action-name {
		display: none;
	}

	.header-action-button {
		cursor: pointer;
		display: inline-block;
		/*margin-right: 5px;*/

		&:last-child {
			margin-right: 0;
		}

		&:hover, &:active, &.active {
			i {
				color: #aaa;
			}
		}

		i {
			color: #ccc;
		}

		.fa-stack {
			font-size: 1.2em;
		}

		&.edit, &.snoozed {
			i {
				color: #ebe309;
			}

			&:hover {
				i {
					color: #ded709;
				}
			}
		}

		&.on, &.plus {
			i {
				color: $green;
			}

			&:hover, &:active {
				i {
					color: #70a361;
				}
			}
		}

		&.attention {
			i {
				color: $blue-etc;
			}

			&:hover, &:active {
				i {
					color: #6f95c7;
				}
			}
		}

		&.pinned, &.shared {
			i {
				color: $green;
			}

			&:hover, &:active {
				i {
					color: #7cb36e;
				}
			}
		}

		/*color: #fffcd7;
		-webkit-text-stroke: 1px #e0cd22;*/
		&.delete, &.red {
			i {
				color: $rust;
			}

			&:hover, &:active {
				i {
					color: #b96161;
				}
			}
		}
	}
}

#header .search, .subheader-search .search {
	flex: 0;
	display: flex;

	input {
		width: 300px;
	}

	i {
		color: #ccc;
		font-size: 1.8em;
		margin-left: 5px;
		width: 30px;
		text-align: center;
		margin-top: 3px;

		&.fa-arrow-circle-right {
			color: $green;
			font-size: 2em;
		}
	}
}


.subheading {
	margin: 0;
	font-size: 14px;
	font-weight: 500;
	padding: 15px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border-bottom: 2px solid #efefef;
	border-bottom: 0;
	display: flex;
	align-items: center;

	> i {
		width: 30px;
		margin-right: 10px;
		text-align: center;
		flex-shrink: 0;
	}

	i.times {
		color: $rust;
	}

	&.gradient {
		background: linear-gradient(to bottom, rgba(232, 244, 255, 0.3), white);
	}

	h2 {
		padding: 0;
		flex: 1;
		margin: 0;
	}

	.side-button a {
		margin: 0;
	}
}

.subheading ul.tabs, .sub-bar ul.tabs {
	list-style-type: none;
	margin: 0 0 -11px 0;
	padding: 0;
	text-align: center;

	li {
		min-width: 20%;
		display: inline-block;
		padding: 8px 12px;
		cursor: pointer;
		font-weight: 500;
		margin: auto 5px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		background: #f7fcff;
		border: 1px solid #e0ecff;
		color: #99b1d3;

		.count {
			background: #e1ecff;
			color: $blue-etc;
		}

		span {
			font-size: 14px;
		}

		i {
			margin-right: 10px;
			font-size: 1.3em;
			color: #99b1d3;
		}

		&:hover, &:active, &.active {
			background: white;
			color: #86a8d6;

			i {
				color: #e0cd22;
			}
		}

		&.active {
			cursor: auto;
			border-bottom-color: white;
			font-weight: 600;
		}
	}
}

.new-header {
	margin: 0;
	background: #fafafa;
	border-top-left-radius: 6px;
	position: relative;
	border-top-right-radius: 6px;
	padding: 20px;
	border-bottom: 1px solid #efefef;

	&.clear {
		background: none;
		border-bottom: 0;
		display: block;
		/*border-bottom: 2px solid #efefef;*/
	}

	&.narrow {
		padding: 15px;
	}

	&.blue {
		background: #f5f9ff;
		border-bottom: 1px solid #e0ecff;
		color: $blue-etc;
		padding: 10px 20px;

		.page-icon i {
			color: $blue-etc;
		}
	}

	&.with-avatar {
		padding-left: 110px;

		.profile-photo-img {
			position: absolute;
			left: 5px;
			top: 3px;
		}
	}
}

.tag-options {
	background: white;
	border: 3px solid #efefef;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	margin-top: -3px;
	border-top: 0;
}

.tag-input:focus {
	background: #fffcd7;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.new-header .new-tag {
	font-size: 20px;
	line-height: 27px;
	font-weight: 500;
	background: #e0ecff;
	color: $blue-etc;
	border-color: #c0d4f8;
	cursor: auto;

	.dot {
		margin-top: 9px;
		background: #f5f9ff;
		border: 1px solid #c0d4f8;
	}

	&:before {
		background: #e0ecff;
		border-color: #c0d4f8;
		content: "";
		height: 25px;
		width: 25px;
		top: 4px;
		left: -13px;
	}
}

.new-header {
	margin: 0;
	background: #fafafa;
	padding: 16px;
	border-bottom: 1px solid #efefef;
	z-index: 50;
	height: auto;
	width: 100%;
	box-sizing: border-box;
	padding-left: 60px;

	.page-icon {
		color: #ddd;
		text-align: center;
		display: inline-block;
		width: 30px;
		margin-top: -2px;
		float: left;
		margin-left: -40px;
		font-size: 1.3em;
		/*text-shadow: 1px 0 3px #fff, 1px 0 0 #999;*/

		&.urgent {
			color: #e07878;
		}

		&.upcoming {
			color: #d6c102;
		}
	}

	h1 {
		letter-spacing: -0.5px;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		margin: 0;

		&.with-breadcrumb {
			padding-left: 10px;

			.page-icon {
				margin-left: -60px;
				font-size: 2em;
				width: 50px;
				margin-top: -20px;
			}
		}
	}

	.breadcrumb {
		font-size: 13px;
		color: #99b1d3;
		font-weight: 600;
		text-transform: uppercase;
	}

	.button-icons {
		float: right;
		padding: 2px 10px;
		border: 1px solid #f3f3f3;
		background: #f3f3f3;
		border-radius: 30px;
		margin-right: -5px;
		margin-top: -9px;
		margin-left: 10px;
	}
}

.new-header {
	h1 {
		font-size: 20px;
	}

	.email-address {
		color: #bbb;
		margin-top: 5px;
		font-weight: 400;
		font-size: 20px;
		float: right;
		display: inline-block;
	}
}

.standalone .subheading .combined-heading {
	h3 {
		margin: 0;
		color: #999;
		font-weight: 500;
		font-size: 16px;
		letter-spacing: -0.3px;
	}
}