.react-datepicker__tether-element-attached-top .react-datepicker__triangle, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow {
	margin-left: -8px;
	position: absolute;
}
.react-datepicker__tether-element-attached-top .react-datepicker__triangle, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__tether-element-attached-top .react-datepicker__triangle::before, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before {
	box-sizing: content-box;
	position: absolute;
	border: 8px solid transparent;
	height: 0;
	width: 1px;
}
.react-datepicker__tether-element-attached-top .react-datepicker__triangle::before, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before {
	content: "";
	z-index: -1;
	border-width: 8px;
	left: -8px;
	border-bottom-color: #aeaeae;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle {
	top: 0;
	margin-top: -8px;
}
.react-datepicker__tether-element-attached-top .react-datepicker__triangle, .react-datepicker__tether-element-attached-top .react-datepicker__triangle::before {
	border-top: none;
	border-bottom-color: #f0f0f0;
}
.react-datepicker__tether-element-attached-top .react-datepicker__triangle::before {
	top: -1px;
	border-bottom-color: #aeaeae;
}

.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow {
	bottom: 0;
	margin-bottom: -8px;
}
.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before {
	border-bottom: none;
	border-top-color: #fff;
}
.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before {
	bottom: -1px;
	border-top-color: #aeaeae;
}

.react-datepicker {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 11px;
	background-color: #fff;
	color: #000;
	border: 1px solid #aeaeae;
	border-radius: 4px;
	display: inline-block;
	position: relative;
}

.react-datepicker__triangle {
	position: absolute;
	left: 50px;
}

.react-datepicker__tether-element-attached-bottom.react-datepicker__tether-element {
	margin-top: -20px;
}

.react-datepicker__header {
	text-align: center;
	background-color: #f0f0f0;
	border-bottom: 1px solid #aeaeae;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding-top: 8px;
	position: relative;
}

.react-datepicker__current-month {
	margin-top: 0;
	color: #000;
	font-weight: bold;
	font-size: 13px;
}
.react-datepicker__current-month--hasYearDropdown {
	margin-bottom: 16px;
}

.react-datepicker__navigation {
	line-height: 24px;
	text-align: center;
	cursor: pointer;
	position: absolute;
	z-index: 1000;
	top: 10px;
	width: 0;
	border: 6px solid transparent;
}
.react-datepicker__navigation--previous {
	left: 10px;
	border-right-color: #ccc;
}
.react-datepicker__navigation--previous:hover {
	border-right-color: #b3b3b3;
}
.react-datepicker__navigation--next {
	right: 10px;
	border-left-color: #ccc;
}
.react-datepicker__navigation--next:hover {
	border-left-color: #b3b3b3;
}
.react-datepicker__navigation--years {
	position: relative;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.react-datepicker__navigation--years-previous {
	top: 4px;
	border-top-color: #ccc;
}
.react-datepicker__navigation--years-previous:hover {
	border-top-color: #b3b3b3;
}
.react-datepicker__navigation--years-upcoming {
	top: -4px;
	border-bottom-color: #ccc;
}
.react-datepicker__navigation--years-upcoming:hover {
	border-bottom-color: #b3b3b3;
}

.react-datepicker__month {
	margin: 5px;
	text-align: center;
}

.react-datepicker__day-name,
.react-datepicker__day {
	color: #000;
	display: inline-block;
	width: 24px;
	line-height: 24px;
	text-align: center;
	margin: 2px;
}

.react-datepicker__day {
	cursor: pointer;
}
.react-datepicker__day:hover {
	border-radius: 4px;
	background-color: #f0f0f0;
}
.react-datepicker__day--today {
	font-weight: bold;
}
.react-datepicker__day--selected, .react-datepicker__day--in-range {
	border-radius: 4px;
	background-color: #216ba5;
	color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-range:hover {
	background-color: #1d5d90;
}
.react-datepicker__day--disabled {
	cursor: default;
	color: #ccc;
}
.react-datepicker__day--disabled:hover {
	background-color: transparent;
}

.react-datepicker__input-container {
	position: relative;
	/*display: inline-block;*/
}

.react-datepicker__year-read-view {
	width: 50%;
	left: 25%;
	position: absolute;
	bottom: 25px;
	border: 1px solid transparent;
	border-radius: 4px;
}
.react-datepicker__year-read-view:hover {
	cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow {
	border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow {
	border-top-color: #ccc;
	margin-bottom: 3px;
	left: 5px;
	top: 9px;
	position: relative;
	border-width: 6px;
}
.react-datepicker__year-read-view--selected-year {
	right: 6px;
	position: relative;
}

.react-datepicker__year-dropdown {
	background-color: #f0f0f0;
	position: absolute;
	width: 50%;
	left: 25%;
	top: 30px;
	text-align: center;
	border-radius: 4px;
	border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover {
	cursor: pointer;
}

.react-datepicker__year-option {
	line-height: 20px;
	width: 100%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.react-datepicker__year-option:first-of-type {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
.react-datepicker__year-option:last-of-type {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.react-datepicker__year-option:hover {
	background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming {
	border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous {
	border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected {
	position: absolute;
	left: 30px;
}
/** Done below **/


.react-datepicker__close-icon {
	background-color: transparent;
	border: 0;
	cursor: pointer;
	display: inline-block;
	height: 0;
	outline: 0;
	padding: 0;
	vertical-align: middle;
}

.react-datepicker__close-icon::after {
	background-color: #216ba5;
	border-radius: 50%;
	bottom: 0;
	box-sizing: border-box;
	color: #fff;
	content: "\00d7";
	cursor: pointer;
	font-size: 12px;
	height: 16px;
	width: 16px;
	line-height: 1;
	margin: -8px auto 0;
	padding: 2px;
	position: absolute;
	right: 7px;
	text-align: center;
	top: 50%;
}

.react-datepicker__today-button {
	background: #f0f0f0;
	border-top: 1px solid #aeaeae;
	cursor: pointer;
	text-align: center;
	font-weight: bold;
	padding: 5px 0;
}

.react-datepicker__tether-element {
	z-index: 2147483647;
}
/* end datepicker */


.tether-element-attached-top .datepicker__triangle, .tether-element-attached-bottom .datepicker__triangle {
	margin-left: -8px;
	position: absolute;
}

.tether-element-attached-top .datepicker__triangle, .tether-element-attached-bottom .datepicker__triangle, .tether-element-attached-top .datepicker__triangle:before, .tether-element-attached-bottom .datepicker__triangle:before {
	box-sizing: content-box;
	position: absolute;
	border: 8px solid transparent;
	height: 0;
	width: 1px;
}

.tether-element-attached-top .datepicker__triangle:before, .tether-element-attached-bottom .datepicker__triangle:before {
	content: "";
	z-index: -1;
	border-width: 8px;
	left: -8px;
	border-bottom-color: #ddd;
}

.tether-element-attached-top .datepicker__triangle {
	top: 0;
	margin-top: -8px;
}

.tether-element-attached-top .datepicker__triangle, .tether-element-attached-top .datepicker__triangle:before {
	border-top: none;
	border-bottom-color: #fafafa;
}

.tether-element-attached-top .datepicker__triangle:before {
	top: -1px;
	border-bottom-color: #ddd;
}

.tether-element-attached-bottom .datepicker__triangle {
	bottom: 0;
	margin-bottom: -8px;
}

.tether-element-attached-bottom .datepicker__triangle, .tether-element-attached-bottom .datepicker__triangle:before {
	border-bottom: none;
	border-top-color: #fff;
}

.tether-element-attached-bottom .datepicker__triangle:before {
	bottom: -1px;
	border-top-color: #aeaeae;
}

.datepicker {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 11px;
	background-color: #fff;
	color: #000;
	border: 1px solid #ddd;
	border-radius: 4px;
	display: inline-block;
	position: relative;
	box-shadow: 0 0 10px #ddd;
}

.datepicker__container {
	position: absolute;
	display: inline-block;
	z-index: 2147483647;
}

.datepicker__triangle {
	position: absolute;
	left: 50px;
}

.datepicker__header {
	text-align: center;
	background-color: #fafafa;
	border-bottom: 1px solid #efefef;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding-top: 8px;
	position: relative;
}

.datepicker__current-month {
	color: black;
	font-weight: bold;
	font-size: 13px;
}

.datepicker__navigation {
	line-height: 24px;
	text-align: center;
	cursor: pointer;
	position: absolute;
	top: 10px;
	width: 0;
	border: 6px solid transparent;
}

.datepicker__navigation--previous {
	left: 10px;
	border-right-color: #ccc;
}

.datepicker__navigation--previous:hover {
	border-right-color: #b3b3b3;
}

.datepicker__navigation--next {
	right: 10px;
	border-left-color: #ccc;
}

.datepicker__navigation--next:hover {
	border-left-color: #b3b3b3;
}

.datepicker__week-day {
	color: #ccc;
	display: inline-block;
	width: 28px;
	line-height: 24px;
}

.datepicker__month {
	margin: 5px;
	text-align: center;
}

.datepicker__day {
	color: #000;
	display: inline-block;
	width: 24px;
	line-height: 24px;
	text-align: center;
	margin: 2px;
	cursor: pointer;
}

.datepicker__day:hover {
	border-radius: 4px;
	background-color: #f0f0f0;
}

.datepicker__header .datepicker__day:hover {
	background-color: #fafafa;
	cursor: auto;
}

.datepicker__day--today {
	font-weight: bold;
}

.datepicker__day--selected {
	border-radius: 4px;
	background-color: #216ba5;
	color: #fff;
}

.datepicker__day--selected:hover {
	background-color: #1d5d90;
}

.datepicker__day--disabled {
	cursor: default;
	color: #ccc;
}

.datepicker__day--disabled:hover {
	background-color: transparent;
}

.datepicker__input-container {
	position: relative;
}

.datepicker__input {
	width: 100px;
	text-align: center;
}

.datepicker__input:not(:valid) ~ .close-icon {
	display: none;
}

/** MY OVERRIDES **/
.react-datepicker {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'helvetica neue', 'helvetica', 'sans-serif';
	border: 1px solid #ddd;
	box-shadow: 0 0 10px #ddd;
	color: #444;
}

.react-datepicker__header {
	background: #fafafa;
	border-bottom: 1px solid #efefef;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle {
	border-bottom-color: #fafafa;

	&:before {
		border-bottom-color: #ddd;
	}
}

.react-datepicker__current-month {
	color: $blue-etc;
}

.react-datepicker__day-name {
	color: #aaa;
	font-size: 13px;
	font-weight: 600;
}

.react-datepicker__day--outside-month {
	color: #aaa;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-range:hover {
	background-color: #8cbf7e;
}

.react-datepicker-popper {
	z-index: 100000000;
}

.react-datepicker__navigation-icon--next, .react-datepicker__navigation-icon--previous {
	display: none;
}

.react-datepicker__navigation--next {
	right: -5px;
	background: none;
}

.react-datepicker__navigation--previous {
	left: -5px;
	background: none;
}