.pop-under {
	cursor: default;
	background-color: white;
	border:1px solid #d4ebff;
	line-height:1.3em;
	padding: 5px 10px;
	position: absolute;
	display: none;
	text-align:center;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	z-index: 143223483400;

	.contents {
		max-height: 300px;
		overflow: auto;
		margin: -5px -10px;
		padding: 5px 10px;
	}

	&.left {
		text-align: left;
	}
}

.pop-under-arrow-border {
	border-color:  transparent transparent #d4ebff transparent;
	border-style: solid;
	border-width: 10px;
	height:0;
	width:0;
	position:absolute;
	top:-21px;
	left:20px;
 }

.pop-under-arrow {
	border-color:  transparent transparent white transparent;
	border-style: solid;
	border-width: 10px;
	height:0;
	width:0;
	position:absolute;
	top:-19px;
	left:20px;

	&.blue {
		border-color:  transparent transparent #f5f9ff transparent;
	}
}

.pop-under-form, .content-block, .drawer {
	color: #999;
	z-index: 99999999999;
	position: absolute;
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 6px;
	box-shadow: 0 3px 15px #ddd;
	text-align: left;

	.contents {
		max-height: 300px;
		overflow: auto;
		padding: 10px;
	}

	ul.table {
		/*margin: -10px;*/

		li {
			a.block {
				padding: 8px;
			}
		}

		li:last-of-type .block {
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
		}

		li:first-of-type .block {
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}
	}

	.body {
		background: white;
		margin: -10px;
		padding: 10px;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}
}

.pop-under-form, .layer .main {
	&.headingless {
		&:after {
			border-bottom-color: white;
		}
	}

	.heading {
		font-weight: 600;
		box-sizing: border-box;
		width: 100%;
		padding: 15px;
		font-size: 15px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border-bottom: 1px solid #efefef;
		background: #fafafa;
		color: $blue-etc;

		i {
			color: $green;
			margin-right: 5px;

		}

		&.narrow {
			padding: 5px;
		}

		.button {
			line-height: 14px;
		}

		.etc {
			font-weight: 500;
		}

		.exit {
			float: right;
			color: #ccc;
			cursor: pointer;

			&:hover, &:active {
				color: firebrick;
			}
		}
	}

	.heading .icon, .subheading .icon {
		width: 40px;
		margin-left: -10px;
		display: inline-block;
		text-align: center;
	}

}

.content-block.user-list {
	width: 350px;

	li .block {
		padding-left: 35px;

		.user-avatar {
			float: left;
			margin-left: -27px;
		}
	}
}

.pop-under-form, .drawer, .sidebar-search {
	&.snooze {
		border-color: #efefef;

		.table {
			/*width: auto;*/
		}

		&:after {
			border-bottom-color: #ffffff;
		}
	}

	&.centered {
		&:after, &:before {
			left: 50%;
		}
	}

	&.side {
		&:after, &:before {
			left: -11px;
			top: 10px;
			margin-left: -20px;
		}

		&.bottom {
			&:before, &:after {
				top: auto;
				bottom: 10px;
			}
		}

		&:after {
			border-color: rgba(239, 239, 239, 0);
			border-right-color: #fafafa;
			border-width: 11px;
			margin-left: -10px;
		}

		&:before {
			border-color: rgba(239, 239, 239, 0);
			border-right-color: #dddddd;
			border-width: 11px;
			margin-left: -11px;
		}

		&.right {
			&:after, &:before {
				right: -10px;
				left: auto;
				top: 10px;
				margin-right: 0;
			}

			&:after {
				border-color: rgba(239, 239, 239, 0);
				border-left-color: #fafafa;
				border-width: 11px;
				margin-right: -12px;
			}

			&:before {
				border-color: rgba(239, 239, 239, 0);
				border-left-color: #dddddd;
				border-width: 11px;
				margin-right: -13px;
			}
		}

		&.white.right {
			&:after, &:before {
				border-left-color: #fff;
			}
		}
	}

	&:after, &:before {
		bottom: 100%;
		left: 15%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(239, 239, 239, 0);
		border-bottom-color: #fafafa;
		border-width: 10px;
		margin-left: -10px;
	}

	&:before {
		border-color: rgba(239, 239, 239, 0);
		border-bottom-color: #ddd;
		border-width: 11px;
		margin-left: -11px;
	}

	&.right {
		text-align: left;

		&:after, &:before {
			left: auto;
			right: 30px;
		}
	}

	&.center {
		&:after, &:before {
			left: 50%;
		}
	}

	&.white:after {
		border-bottom-color: white;
	}
}

.drawer {
	top: 50px;
	left: 15px;
	width: 300px;
	background: #86a8d6;
	border: 1px solid #6687b5;
	border: transparent;
	color: white;
	/*border-top-left-radius: 4px;*/

	a {
		color: white;
	}

	&:before {
		border-bottom-color: #86a8d6;
		left: 11px;
	}

	&:after {
		border-bottom-color: #86a8d6;
		left: 11px;
	}

	&.with-container {
		&:before, &:after {
			border-bottom-color: #6087bb;
		}
	}

	.intro-heading {
		font-size: 14px;
		margin-top: 15px;
		margin-left: 10px;
		text-transform: uppercase;
		color: #45699a;

		i {
			margin-right: 10px;
			width: 30px;
			font-size: 1.2em;
			text-align: center;
		}
	}

	.search-container {
		background: #6087bb;
		margin: -10px -10px 10px -10px;
		padding: 10px;
		/*border-top-left-radius: 4px;*/
		border-top-right-radius: 5px;
	}

	.lists {
		margin: 0 -10px;

		a {
			display: block;
			border-bottom: 1px solid #769aca;
			padding: 8px 12px;
			font-weight: 500;

			i {
				width: 30px;
				text-align: center;
				font-size: 1.2em;
				margin-right: 10px;
			}

			&:last-of-type {
				border-bottom: 0;
			}
		}
	}
}

#header .share-sheet, .share-sheet {
	width: 350px;
	box-sizing: border-box;
	cursor: auto;

	.heading {
		margin-bottom: 0;
	}

	.global-info {
		padding: 20px;

		h3 {
			color: $blue-etc;
			margin: 0 0 10px 0;
			letter-spacing: -0.3px;
		}

		p {
			font-size: 13px;
			color: #777;
		}

		.submit {
			margin: 0;
		}

		.submit i {
			color: $gold;
			margin: 0;
		}
	}

	.share-associated {
		background: #f2f2f2;
		padding: 5px 10px;
		display: flex;
		width: calc(100% - 20px);
		align-items: center;

		label {
			flex-grow: 1;
			font-size: 14px;
			margin: 0;
		}

		.toggler {
			text-align: right;;
			width: 92px;
			font-size: 12px;

			.on {
				background: white !important;
			}
		}
	}

	.share-type {
		background: #f6f6f6;
		display: table;
		table-layout: fixed;
		width: 100%;
		box-sizing: border-box;
		padding: 5px 10px;

		> div {
			display: table-cell;
			text-align: center;

			&:first-of-type span {
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				border-right: 0;
			}

			&:last-of-type span {
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
			}

			span {
				font-size: 14px;
				padding: 5px;
				display: block;
				background: #efefef;
				border: 1px solid #f2f2f2;
				cursor: pointer;

				&.active {
					background: white;
					color: $green;
					font-weight: 600;
				}
			}
		}
	}

	.add-sharee-section {
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		overflow: hidden;
	}

	.add-sharees {
		line-height: 16px;
		float: right;
		margin-top: -2px;
		color: #666;
		font-size: 13px;

		&:hover, &:active {
			cursor: pointer;
			background: white;
			color: $blue-etc;
		}
	}

	.input {
		background: #f2f2f2;
		padding: 5px ;

		input[type="text"] {
			width: 100%;
			box-sizing: border-box;
		}
	}

	.submit-with-options {
		padding: 2px 10px;
		margin: 0 5px 5px 5px;
		text-align: right;
		background: #fcfcfc;
		border-radius: 5px;
		border: 1px solid #f2f2f2;

		.add-note {
			float: left;
			color: $blue-etc;
			font-size: 13px;
			font-weight: 500;
			margin-top: 5px;
		}

		input { margin: 3px; }

		textarea {
			box-sizing: border-box;
			width: 100%;
			margin-top: 5px;
		}
	}

	&:last-child {
		border-bottom: 0;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.no-chosen-users {
		padding: 10px;
		color: #c7b618;
		background: #fffdea;
		font-weight: 500;
/*		border-bottom: 1px solid #f5efa5;*/

		i {
			float: left;
			transform: rotate(270deg);
			font-size: 20px;
			color: $gold;
			margin: 0 10px;
		}
	}

	.existing-shares {
		box-sizing: border-box;
		width: 100%;

		h5 {
			background: #f2f2f2;
			background: #fafafa;
			border-bottom: 1px solid #f2f2f2;
			color: $green;
			margin: 0;
			padding: 5px 10px;
			font-size: 12px;
			font-weight: 600;
		}
	}

	.sharees {
		margin: 0;
		width: 100%;

		.sharee {
			width: 100%;
			padding: 5px 10px;
			border: 0;
			border-bottom: 1px solid #fafafa;
			color: #555;
			background: 0;
		}
	}

	.chosen-users {
		&.sharees {
			margin: 0;
		}

		> div {
			border-bottom: 1px solid #f2f2f2;
			padding: 8px 10px;

			&:last-of-type {
				border-bottom: 0;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
			}
		}
	}
}

.record-row .share-sheet {
	right: 193px;
	top: 10px;
}

.pop-under-form {
	#main, .main {
		box-shadow: none;
		border: 0;
	}

	.view-task {
		width: 450px;
	}
}