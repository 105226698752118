.styled-select {
	position: relative;

	.placeholder {
		color: #aaa;
	}

	&.small {
		select {
			font-size: 13px;
			padding: 5px;
		}

		&:before {
			top: 6px;
		}
	}

	select {
		padding: 6px;
		width: 100%;
		box-sizing: border-box;
		border-width: 3px;
		padding-right: 25px;
		border-color: #efefef;
		background: white;
		font-size: 14px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		&.small {
			padding-right: 25px;
		}
	}

	&:before {
		content: '\f0d7';
		font-family: 'Font Awesome 5 Pro';
		color: #e0cd22;
		font-size: 1.3em;
		display: inline-block;
		position: absolute;
		right: 10px;
		top: 8px;
		font-weight: 700;
		pointer-events: none;
	}

	&:focus:before {
		color: #e0cd22;
	}
}

input[type="text"]:focus, input[type="password"]:focus, textarea:focus, select:focus, .public-DraftEditor-content:focus {
		outline: none;
		border-color: #ccc;
}

input, textarea, select, button, input[type="search"], .public-DraftEditor-content {
	font-family: 'Whitney SSm A', 'Whitney SSm B', 'helvetica neue', 'helvetica', 'sans-serif';
	color: #222;
}

input[type="text"], textarea, input[type="password"], input[type="submit"], input[type="email"], .public-DraftEditor-content, select {
	-webkit-appearance: none;
	padding: 6px;
	border-radius: 6px;
	font-size: 14px;
	border: 3px solid #efefef;

	.bold {
		font-weight: 500;
	}

	&.half-width {
		box-sizing: border-box;
		width: calc(50% - 5px);

		&:first-of-type {
			margin-right: 10px;
		}
	}

	&.full {
		width: 100%;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	&.large {
		font-size: 18px;
	}

	&.small {
		font-size: 13px;
		padding: 5px;
	}

	&.error {
		border-color: #ffeded !important;
		background: #fbf4f4 !important;
		color: maroon !important;
	}

	&:disabled {
		background: #efefef !important;
		border-color: #efefef !important;
		color: #999 !important;
		cursor: not-allowed !important;

		&:hover, &:active {
			background: #efefef !important;
			border-color: #efefef !important;
		}
	}
}

h1 input[type="text"] {
	font-size: 18px;
	width: 90%;
}

h2 input[type="text"] {
	font-size: 14px;
}

label {
	font-weight: 500;
	display: block;
	margin-bottom: 5px;

	&.standalone {
		margin: 0;
	}

	&.error {
		color: #f69f9f;
	}
}

textarea.average-height {
	height: 200px;
}

textarea.tall {
	height: 400px;
}

textarea.short {
	height: 100px;
}

textarea.one-line {
	height: 33px;
}

textarea.two-lines {
	height: 66px;
}

textarea.four-lines {
	height: 88px;
}

.invalid-record {
	color: #d29196;
	font-weight: 500;
	font-size: 15px;
	margin: 10px 10px 0 10px;
	background: #ffeded;
	padding: 5px 10px 5px 40px;
	border-radius: 6px;
	display: inline-block;

	&:before {
		float: left;
		margin-left: -25px;
		content: '\f071';
		font-family: 'Font Awesome 5 Pro';
	}
}

.submit-payment {
	i {
		font-size: 3em;
		color: #ddd;
		margin: 7px;
	}
}