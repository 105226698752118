$medium-blue: #c5eab8;
$link-blue: #005493;
$blue-etc: #86a8d6;
$info-background: #fffedb;
$info-border: #fef544;
$small-padding: 10px;
$medium-padding: 20px;
$double-medium-padding: 40px;
$green: #8cbf7e;
$gold: #e0cd22;
$ruby: #d6292c;
$rust: #ce6f6f;
$light-pink: #ffd5d5;
$straw: #f5efa5;
$pale-yellow: #fffcd7;