table {
	width: 100%;
	border-collapse: collapse;

	&.inline {
		border: 0; border-spacing: 0; width: 960px; padding:0; border-collapse: collapse; cell-spacing: 0; margin-left: -20px; margin-right: -20px; vertical-align: top;
	}

	&.centered {
		td {
				text-align: center;
		}
	}

	&.padded {
		td, th {
				padding: $small-padding;
		}
	}

	&.narrow-padded {
		td {
			padding: 5px;
		}
	}

	&.equal-width {
		table-layout: fixed;
	}

	&.top-aligned {
		td {
			vertical-align: top;
		}
	}

	&.clean {
		border: none;

		th {
			background: #f5f9ff;
			color: $blue-etc;
			padding: $small-padding;
			font-weight: 500;
			font-size: 16px;
			font-size: 14px;
		}

		tr:last-child td {
			border-bottom: none;
		}

		td {
			border-bottom: 1px solid #f5f9ff;
			background: transparent;
			padding: $small-padding;
		}

		&.gray, &.summary {
			td {
				border-bottom: 1px solid #f2f2f2;
				font-size: 14px;
				line-height: 18px;

				strong {
					font-weight: 600;
				}
			}

			th {
				background-color: #fafafa;
				border-top: 1px solid #f3f3f3;
				border-bottom: 1px solid #f3f3f3;
				font-size: 14px;
				font-weight: 500;
				color: #999;
			}

			border-collapse: separate;
			border-spacing: 0;

			tr.active {
				td {
					background: #f5f9ff;
				}
			}
		}

		&.borderless {
			td {
				border: 0;
			}
		}

		&.narrow td {
			padding: 5px;
		}
	}

	&.summary {
		color: #999;

		td {
			padding: 10px;
			border-bottom: 1px solid #efefef;
		}

		tr:last-child td {
			border-bottom: 0;
		}

		i {
			color: #ccc;
		}
	}

	&.full-width {
		margin: auto (-$medium-padding);
		width: calc(100% + 40px);
	}

	&.underlined {
		tr {
			border-bottom: 1px solid #f5f9ff;

			&:last-child {
				border-bottom: 0;
			}
		}
	}
}

td.top-aligned {
	vertical-align: top;
}

.table {
	display: table;

	&.narrow {
		.cell {
			padding: 3px;
		}
	}

	&.wide {
		.cell {
			padding: 6px;
		}
	}

	.row {
		display: table-row;
	}

	&.underlined {
		.row {
			.cell {
				border-bottom: 1px solid #efefef;
			}

			&:last-child {
				.cell {
					border-bottom: 0;
				}
			}
		}
	}

	&.top-aligned {
		.cell {
			vertical-align: top;
		}
	}

	&.equal-width {
		table-layout: fixed;
	}

	&.full-width {
		margin: auto (-$medium-padding);
		width: calc(100% + #{$double-medium-padding});
	}

	&.padded {
		border-spacing: 10px;
	}

	&.unpadded {
		border-spacing: 0;
	}

	&.wide-padded {
		border-spacing: 20px;
	}

	&.navigable {
		tr.active {
			td {
				background: #f5f9ff;
			}
		}
	}
}

.cell {
	display: table-cell;
	height: 100%;
}

table.padded td {
	padding: 10px;
}

dl.table {
	dt, dd {
		border-bottom: 1px solid #efefef;
		line-height: 20px;
		margin: 0;
		padding: 6px;

		&:last-of-type {
			border-bottom: 0;
		}
	}

	dd {
		font-weight: 500;
	}

	dt {
		width: 30%;
		font-size: 13px;
		color: #999;
		border-bottom-left-radius: 0;
		background: none;
		clear: both;
		text-align: left;
	}
}