.button.red {
	/*
	background: #B33939;
	border: 2px solid #b33939;
	cursor: pointer;
	color: white;
*/
	&:hover, &:active {
		background: #b33939;
		border-color: #b33939;
	}

	&.disabled, &.disabled:hover {
		background: white;
		border-color: #b33939;
		color: #b33939;
	}
}

.button, input[type="submit"], .submit .submit, .modal .links a {
	-webkit-appearance: none;
	cursor: pointer;
	border-style: solid;
	display: inline-block;
	padding: 6px 30px;
	margin: 10px;
	font-size: 14px;
	border-radius: 5px;
	font-weight: 600;
	text-align: center;
	color: white;

	&.blue {
		background: $link-blue;
		border: 1px solid $link-blue;
		color: white;

		i {
			color: white;
			margin-top: 0;
		}
	}

	i {
		margin-right: 10px;
	}

	&.bold {
		font-weight: 600;
	}

	&.blue:hover, &.blue:active {
		background: #0074c8;
		border-color: #0074c8;
	}

	&.transparent {
		background: transparent;
	}

	&.rounded {
		border-radius: 30px;
	}

	&.secondary {
		background: #efefef;
		border-color: #999;
		color: #666;
		width: 140px;
		padding: $small-padding;

		&:hover {
			background: white;
		}
	}

	&.red {
		background: #b33939;
		border-color: #b33939;
		color: white;

		&:hover, &:active {
			background: #ce4142;
			border-color: #ce4142;
		}
	}

	&.tiny {
		font-size: 13px;
		padding: 3px 15px;
		font-weight: 500;
		width: auto;
		margin: auto;
	}

	&.medium {
		i {
			font-size: 18px;
			float: left;
		}
	}

	&.green {
		background: $green;
		border-color: $green;
		color: white;

		&:hover, &:active, &.active {
			background: #7eb170;
			border-color: #7eb170;
		}
	}

	&.yellow {
		background: goldenrod;
		border-color: goldenrod;

		&:hover, &:active {
			background: #e6af22;
			border-color: #e6af22;
		}
	}

	&.tertiary {
		background: $medium-blue;
		border-color: $medium-blue;
		color: $link-blue;
		font-weight: normal;
		font-size: 13px;
		padding: $small-padding;
	}

	&.large {
		width: 300px;
		font-size: 15px;
		padding: 8px;
		font-weight: 500;
	}

	&.small {
		font-size: 14px;
		margin: 5px auto;
		width: 60%;
		font-weight: normal;
	}
}

.small-form {
	width: 60%;
	margin: auto 20%;
	padding: $small-padding;
	background: #fcfcfc;
	border: 1px solid #efefef;
	border-top: 0;
	border-radius: 6px;
	box-shadow: 0 0 2px 1px #fcfcfc;

	h4 {
		margin: -10px;
		margin-bottom: 10px;
		padding: $small-padding;
		text-align: center;
		font-weight: 600;
		background: #efefef;
	}
}

input[type="submit"].button.tiny {
	padding: 6px 15px;
}

.modal .submit, .small-form .submit {
	background: #efefef;
	margin: -10px;
	margin-top: 10px;
	clear: both;

	input[type="submit"] {
		font-size: 14px;
		text-align: center;
		width: 70%;
		margin: 10px 15%;
	}
}

.search-wrapper {
	position: relative;
	padding-right: 3%;

	input {
		border: 2px solid #0074c8;
		width: 280px;
		font-size: 14px;
		border-radius: 5px;
		line-height: 20px;
		padding: 4px;
		margin-left: 0;
		position: relative;
		padding-left: 30px;
	}

	&:before {
		font-family: 'Font Awesome 5 Pro';
		font-size: 16px;
		color: $link-blue;
		content: "\f002";
		z-index: 1000;
		position: absolute;
		top: 20px;
		margin-right: 30px;
		left: 8px;
	}
}

.search-header {
	text-align: center;
	background: #fafafa;
	padding: 12px;
	border-bottom: 1px solid #efefef;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;

	form {
		margin: 0 auto;
		display: flex;
		align-items: center;
		width: 70%;

		i {
			color: #ddd;
			width: 40px;
			font-size: 2em;
			text-align: center;
			flex-shrink: 0;
		}

		i.back {
			font-size: 2.5em;
			font-weight: 300;
			visibility: hidden;
		}

		i.submit {
			&.fa-arrow-circle-right {
				color: $green;
				cursor: pointer;
			}

			&.fa-spinner {
				color: #e0cd22;
			}
		}

		.input {
			position: relative;
			flex: 1;
			padding: 0 10px;

			&:before {
				font-family: 'Font Awesome 5 Pro';
				font-size: 20px;
				color: #aad49f;
				content: "\f002";
				z-index: 1000;
				position: absolute;
				font-weight: 700;
				top: 13px;
				left: 22px;
			}
		}

		input {
			font-size: 20px;
			width: 100%;
			padding: 8px;
			padding-left: 40px;
			box-sizing: border-box;
		}
	}
}

.search-form.small {
	input {
		font-size: 15px;
		padding: 6px;
		padding-left: 34px;
	}

	&:before {
		top: 11px;
	}
}

.search-form.medium {
	input {
		font-size: 16px;
		padding: 8px;
		padding-left: 40px;
	}

	&:before {
		top: 10px;
		left: 14px;
		font-size: 1.6em;
	}
}

.field_with_errors input, input.field_with_errors, .field_with_errors select, .field_with_errors textarea {
	border-color: #ffd5d5;
}

div.field_with_errors {
	display: inline;
}

.main-form {
	&.padded {
		padding: 10px 30px;
	}

	&.gray .submit {
		background: none;
		margin: 0;
	}

	fieldset {
		border: none;
		margin: 0;
		padding: 0;
	}
}

.main-form h4 {
	color: #888;
	font-size: 14px;
	font-weight: 500;

	i {
		color: #ccc;
		font-size: 1.2em;
		width: 30px;
		text-align: center;
	}

	.more {
		float: right;
		color: #aaa;
	}
}

.main-form legend, .main-form h3, .modal h4.block {
	margin: 20px -20px;
	background: #efefef;
	display: block;
	width: auto;
	font-size: 16px;
	line-height: 20px;
	padding: 10px 20px;

	&.half, &.three-quarters {
		width: 50%;
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;

		&.centered {
			margin-left: auto;
			margin-right: auto;
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
		}

		&.data-table, &.secondary {
			margin-bottom: 0; background: #f5f9ff;
			border: none;
		}

		i {
			color: $link-blue;
			/*font-size: 1em;*/
			margin-right: 10px;
		}

		span.fa-stack {
			margin-top: -6px;
			margin-right: -13px;
		}
	}

	&.three-quarters {
		width: 75%;
	}

	span.etc {
		font-weight: normal;
		float: right;
		font-size: 13px;
	}

	div.etc {
		font-size: 13px;
		font-weight: normal;
		line-height: 18px;
	}
}

span.label, div.label, a.label, label {
	font-size: 15px;
	text-shadow: none;
	padding: 5px;
	border-radius: 4px;

	&.large {
		font-size: 16px;
		padding: 5px 8px;
	}

	&.small {
		font-size: 14px;
	}

	&.huge {
		font-size: 18px;
		padding: 5px 8px;
	}
}

.main-form.gray.full-width {
	background: #fafafa;
	border-top: 2px solid #f2f2f2;
	margin: -20px;
	border-radius: 0;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

button.invisible-container {
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;

	i.fa-spinner {
		color: #e0cd22;
	}
}

.submit .fa-spinner {
	color: #e0cd22;
	font-size: 30px;
	margin: 10px;
}

button.with-icon {
	padding: 0;
	border: 0;
	background: transparent;
}